import 'selectric';

export default {
  	init() {

		// when the alert 'X' is clicked it will hide
		$('.alert-close').on('click', function() {
			$(this).parent('.row').addClass('hide');
		});



		//Toggling the hidden advanced search options
		$('.advanced-filter-toggle > .col-xs-3 > img, .advanced-filter-toggle > .col-xs-3 > a').on('click', function(e) {
			e.preventDefault();
			$('.hidden-advanced-search-filters').toggleClass('hide');
			if ( $('.hidden-advanced-search-filters').hasClass('hide') ) {
				$('.advanced-filter-toggle > .col-xs-3 > a').text('Open Advanced Filters')
			} else {
				$('.advanced-filter-toggle > .col-xs-3 > a').text('Close Advanced Filters')
			}
		});

		//the date picker dropdown toggles its hidden option menu
		$('button.show-options, .advanced-search-date-container-header').on('click', function(){
			$('.advanced-search-date-content').toggleClass('show');
			return false;
		});

		// Whenever a date is picked it will change the text of the date picker to the date
		$('.control-group > label > input:not(#custom)').change(function(){
			let newText = $(this).parent('label').text();
			$('.current-date').html(newText);
		});

		// Whenever a date is picked it will change the text of the date picker to the date
		var fromMonth;
		$('#from-date-month').on('change', function() {
			fromMonth = $('#from-date-month option:selected').text();
			var total = fromMonth + ', ' + fromYear + ' - ' + untilMonth + ', ' + untilYear;
			$('.current-date').html(total);
		});

		// Whenever a date is picked it will change the text of the date picker to the date
		var fromYear;
		$('#from-date-year').on('change', function() {
			fromYear = $('#from-date-year option:selected').text();
			var total = fromMonth + ', ' + fromYear + ' - ' + untilMonth + ', ' + untilYear;
			$('.current-date').html(total);
		});

		// Whenever a date is picked it will change the text of the date picker to the date
		var untilMonth;
		$('#until-date-month').on('change', function() {
			untilMonth = $('#until-date-month option:selected').text();
			var total = fromMonth + ', ' + fromYear + ' - ' + untilMonth + ', ' + untilYear;
			$('.current-date').html(total);
		});

		// Whenever a date is picked it will change the text of the date picker to the date
		var untilYear;
		$('#until-date-year').on('change', function() {
			untilYear = $('#until-date-year option:selected').text();
			var total = fromMonth + ', ' + fromYear + ' - ' + untilMonth + ', ' + untilYear;
			$('.current-date').html(total);
		});

		// Whenever the submit button is pressed it will submit the form
		$('#submit-btn').on('click', function (e) {
			e.preventDefault();
			$('#advanced-search-form').submit();
		});

		//Whenever the reset button is pressed it will reset the form
		$('#reset-btn').on('click', function (e) {
			e.preventDefault();
			$("#advanced-search-form").trigger("reset");
			$dropdown.refresh();
		});

		//When screen is tablet size move the visible search filters into the hidden search filter div for responsive styling
		if (window.innerWidth < 1025 ) {
			let visibleSearchFilters = $('.visible-advanced-search-filters');
			$('.hidden-advanced-search-filters').prepend(visibleSearchFilters);
		}

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
        // turning select menus into selectric styled menus
        const selectElms = $('select').selectric();
	},
};
