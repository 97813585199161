import Swiper from "swiper/dist/js/swiper.js";
import Common from "./common";

export default {
	init() {
	
		const favLink = document.getElementById('favorite_posts');
		const copyUrl = document.getElementById('source-link');
		
		try {
		    if (favLink) {
                Common.toggleChecked(favLink);
                favLink.addEventListener('change', e => {
                    e.preventDefault();
                    const postID = favLink.value;
                
                    /**
                     * Prevent the user from constantly clicking the checkbox
                     * @type {boolean}
                     */
                    favLink.disabled = true;
                
                    /**
                     * Pass the element and the post ID to our AJAX call
                     */
                    Common.setUserMeta(favLink, postID);
                
                });
            }
			
		    if (copyUrl) {
                copyUrl.addEventListener('click', e => {
                    e.preventDefault();
                    Common.copyURL(copyUrl);
                });
            }

            const newsSwiper = new Swiper("#news-swiper", {
                spaceBetween: 0,
                slidesPerView: 3,
                loop: false,
                pagination: {
                    el: "#news-swiper-pagination",
                },
                navigation: {
                    nextEl: "#news-button-next",
                    prevEl: "#news-button-prev",
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 1
                    },
                }
            });
			
		}
		catch (e) {console.log(e);}
		
	}
};
