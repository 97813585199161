export default {
  	init() {

		// $('.gradient-border > .circle').on('click', function () {
		// 	if ( !$('#tablet-sticky-cart-form, .tablet-sticky-cart-body, .circle > img, .exit, .renew-btn').hasClass('active')  ) {
		// 		$('#tablet-sticky-cart-form, .tablet-sticky-cart-body, .circle > img, .exit, .renew-btn').addClass('active');
		// 		$('#tablet-cart').attr('aria-expanded', 'true');
		// 	} else {
		// 		$('#tablet-sticky-cart-form, .tablet-sticky-cart-body, .circle > img, .exit, .renew-btn').removeClass('active');
		// 		$('#tablet-cart').attr('aria-expanded', 'false');
		// 	}
		// });

		// if (window.innerWidth < 768) {
		// 	let cta = $('.cta-container.blue > .cta-container-content');
		// 	$('.cta-container.white').append(cta);
		// 	$('section.double-cta .cta-container.blue').hide();
		// }
		
		// $(window).on('resize', function(){
		// 	if (window.innerWidth < 768) {
		// 		let cta = $('.cta-container.blue > .cta-container-content');
		// 		$('.cta-container.white').append(cta);
		// 		$('section.double-cta .cta-container.blue').hide();
		// 	} else {
		// 		if ($('section.double-cta .cta-container.white .cta-container-content').length > 1){
		// 			let cta = $('section.double-cta .cta-container.white .cta-container-content:last-child');
		// 			$('section.double-cta .cta-container.white .cta-container-content:last-child').remove();
		// 			$('.cta-container.blue').append(cta);
		// 			$('section.double-cta .cta-container.blue').show();
		// 		}
		// 	}
		// });

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
		
	},
};
