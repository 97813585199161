import JqueryUIDatePicker from "jquery-ui/ui/widgets/datepicker.js";
import moment from 'moment/src/moment.js';
import { Calendar } from '@fullcalendar/core';
import momentPlugin from '@fullcalendar/moment';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction';
import 'selectric';

export default {
	init() {

		// date picker for the date filter
		// $( "#datepicker" ).datepicker();


		// prev / next buttons clicked
		// if (window.innerWidth > 1024){
		// 	$(document).on('click', '.fc-button', function(){
        //
		// 		if ($('.fc-dayGridMonth-view').length){
		// 			let selectedCategories = [];
		// 			$('#desktop-calendar-header ul li').not('.category-everything').each(function(){
		// 				if ($(this).hasClass('active')){
		// 					let filterItem = $(this).attr('id');
		// 					if (selectedCategories.indexOf(filterItem) === -1) {
		// 						selectedCategories.push(filterItem);
		// 						var setCategories = selectedCategories.join(', .');
		// 						$('.fc-event').css('display', 'none');
		// 						$('.'+setCategories).css('display', 'block');
		// 					}
		// 				}
		// 			});
        //
		// 			// registererd event icon
		// 			let registeredIcon = '<div class="registered-icon">Registered</div>';
		// 			$('.registered').append(registeredIcon);
        //
		// 			if (window.innerWidth > 1024){
		// 				$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
		// 					$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-more-cell').each(function(i){
		// 						$(this).children().children().attr('href', 'javascript:;');
		// 					});
		// 					$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
		// 						if (!$(this).hasClass('fc-limited')){
		// 							$(this).children('.fc-event').attr('href', 'javascript:;');
		// 						}
		// 					});
		// 				});
		// 			}
        //
		// 			if (window.innerWidth < 1025 && window.innerWidth > 767){
		// 				$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
		// 					$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
		// 						if (!$(this).hasClass('fc-limited')){
		// 							$(this).addClass('fc-more-cell');
		// 							let singleEvent = '<a href="javascript:;" class="fc-more single-more">1 event</a>';
		// 							$(this).append(singleEvent);
		// 						}
		// 					});
		// 				});
		// 			}
        //
		// 			if (window.innerWidth < 768){
		// 				$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
		// 					$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
		// 						if (!$(this).hasClass('fc-limited')){
		// 							$(this).addClass('fc-more-cell');
		// 							let singleEvent = '<a href="javascript:;" class="fc-more single-more">+1</a>';
		// 							$(this).append(singleEvent);
		// 						}
		// 					});
		// 				});
		// 			}
        //
		// 			$('.single-more').on('click', function(e){
		// 				e.preventDefault();
        //
		// 				let singleEventContent = $(this).prev().html();
		// 				let catName1 = $(this).prev().data('cat1');
		// 				let catName2 = $(this).prev().data('cat2');
		// 				let eventDate = $(this).prev().data('date');
        //
		// 				let modal_contents = '<div class="modal-content">' +
		// 					'<a href="#" class="close">Close</a>' +
		// 					'<div class="modal-event-date">'+eventDate+'</div>' +
		// 					'<div class="row center-xs">';
        //
		// 				modal_contents += '<div class="col-xs-12">' +
		// 					'<div class="modal-event modal-event-1">' +
		// 					'<div class="modal-event-category-container">' +
		// 						'<div class="modal-event-category first '+catName1+'">'+catName1+'</div>' +
		// 						'<div class="modal-event-category second '+catName2+'">'+catName2+'</div>' +
		// 					'</div>' +
		// 					'<a href="#" class="modal-event-title">'+singleEventContent+'</a>' +
		// 					'</div>' +
		// 				'</div>';
		//
		// 				modal_contents += '</div></div>';
        //
		// 				$(this).addClass('re-focus');
		// 				$('body').addClass('no-scroll');
		// 				$('#modal').addClass('open');
		// 				$('.calendar-modal-overlay').addClass('open');
		// 				$('#modal .modal-events').html(modal_contents);
		// 				$('body a, body input, body button').attr('tabindex', '-1');
		// 				$('#modal a').attr('tabindex', '0');
		// 				$('#modal.modal .close').focus();
        //
		// 			});
        //
		// 			$(document).on('click', '.fc-more', function(e){
		// 				e.preventDefault();
		// 			});
        //
		// 			$(document).on('click', '#modal .close', function(e){
		// 				e.preventDefault();
		// 				$('body').removeClass('no-scroll');
		// 				$('body a, body input, body button').attr('tabindex', '0');
		// 				$('#modal .events-contents .modal-content').remove();
		// 				$('#modal').removeClass('open');
		// 				$('.calendar-modal-overlay').removeClass('open');
		// 				$('.re-focus').focus().removeClass('re-focus');
		// 			});
        //
		// 		}
        //
		// 	});
		// }

		// reset the form
		// var $dropdown = $('select').selectric();
		// $('#reset-btn').on('click', function (e) {
		// 	e.preventDefault();
		// 	$("#calendar-filters-form").trigger("reset");
		// 	$dropdown.refresh();
		// 	if (!$('.category-everything').hasClass('active')) {
		// 		$('.category-everything').addClass('active');
		// 		$('#desktop-calendar-header ul li').not('.category-everything').removeClass('active');
		// 		$('.fc-event').css('display', 'block');
		// 		$('.event').css('display', 'block');
		// 		selectedCategories = [];
		// 	}
		// });

		// prev / next buttons clicked
		// if (window.innerWidth < 1024){
		// 	$(document).on('click', '.fc-button', function(){
        //
		// 		if ($('.fc-dayGridMonth-view').length){
		// 			let selectedCategories = [];
		// 			$('#desktop-calendar-header ul li').not('.category-everything').each(function(){
		// 				if ($(this).hasClass('active')){
		// 					let filterItem = $(this).attr('id');
		// 					if (selectedCategories.indexOf(filterItem) === -1) {
		// 						selectedCategories.push(filterItem);
		// 						var setCategories = selectedCategories.join(', .');
		// 						$('.fc-event').css('display', 'none');
		// 						$('.'+setCategories).css('display', 'block');
		// 					}
		// 				}
		// 			});
        //
		// 			// registererd event icon
		// 			let registeredIcon = '<div class="registered-icon">Registered</div>';
		// 			$('.registered').append(registeredIcon);
        //
		// 			if ($(window).width() < 1025 && $(window).width() > 767){
		// 				$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
		// 					$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
		// 						if (!$(this).hasClass('fc-limited')){
		// 							$(this).addClass('fc-more-cell');
		// 							let singleEvent = '<a href="javascript:;" class="fc-more single-more">1 event</a>';
		// 							$(this).append(singleEvent);
		// 						}
		// 					});
		// 				});
		// 			}
        //
		// 			if ($(window).width() < 768){
		// 				$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
		// 					$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
		// 						if (!$(this).hasClass('fc-limited')){
		// 							$(this).addClass('fc-more-cell');
		// 							let singleEvent = '<a href="javascript:;" class="fc-more single-more">+1</a>';
		// 							$(this).append(singleEvent);
		// 						}
		// 					});
		// 				});
		// 			}
        //
		// 			$('.single-more').on('click', function(e){
		// 				e.preventDefault();
        //
		// 				let singleEventContent = $(this).prev().html();
		// 				let catName1 = $(this).prev().data('cat1');
		// 				let catName2 = $(this).prev().data('cat2');
		// 				let eventDate = $(this).prev().data('date');
        //
		// 				let modal_contents = '<div class="modal-content">' +
		// 					'<a href="#" class="close">Close</a>' +
		// 					'<div class="modal-event-date">'+eventDate+'</div>' +
		// 					'<div class="row center-xs">';
        //
		// 				modal_contents += '<div class="col-xs-12">' +
		// 					'<div class="modal-event modal-event-1">' +
		// 					'<div class="modal-event-category-container">' +
		// 						'<div class="modal-event-category first '+catName1+'">'+catName1+'</div>' +
		// 						'<div class="modal-event-category second '+catName2+'">'+catName2+'</div>' +
		// 					'</div>' +
		// 					'<a href="#" class="modal-event-title">'+singleEventContent+'</a>' +
		// 					'</div>' +
		// 				'</div>';
		//
		// 				modal_contents += '</div></div>';
        //
		// 				$(this).addClass('re-focus');
		// 				$('body').addClass('no-scroll');
		// 				$('#modal').addClass('open');
		// 				$('.calendar-modal-overlay').addClass('open');
		// 				$('#modal .modal-events').html(modal_contents);
		// 				$('body a, body input, body button').attr('tabindex', '-1');
		// 				$('#modal a').attr('tabindex', '0');
		// 				$('#modal .close').focus();
		// 			});
        //
		// 		}
        //
		// 	});
		// }

		// tablet submit filters
		// $('#advanced-submit-btn').on('click', function (e) {
		// 	e.preventDefault();
        //
		// 	// check datepicker for date
		// 	if ($('#datepicker2').val()){
		// 		let filteredDate = moment($('#datepicker2').val());
		// 		let selected_date = moment(filteredDate).format('YYYY-MM-DD');
		// 		calendar.gotoDate(selected_date);
		// 		calendar.select(selected_date);
		// 		if (window.innerWidth > 1024){
		// 			$('html, body').animate({
		// 				scrollTop: $('.fc-day-top[data-date="'+selected_date+'"]').offset().top
		// 			}, 1000);
		// 		}
		// 	} else {
		// 		let selected_date = new Date().getTime();
		// 		selected_date = moment(selected_date).format('YYYY-MM-DD');
		// 		calendar.gotoDate(selected_date);
		// 		calendar.select(selected_date);
		// 		if (window.innerWidth > 1024){
		// 			$('html, body').animate({
		// 				scrollTop: $('.fc-day-top[data-date="'+selected_date+'"]').offset().top
		// 			}, 1000);
		// 		}
		// 	}
        //
		// 	calendar.destroy();
		// 	calendar.render();
		//
		// 	var calendarKeys = '<div class="calendar-keys"><ul><li class="no-events">Not available</li><li class="searched-event">Searched event</li><li class="today-event">Today</li></ul><p>Tap each date to see full list of programs</p></div>';
		// 	$('.fc-toolbar').append(calendarKeys);
		// 	let registeredIcon = '<div class="registered-icon">Registered</div>';
		// 	$('.registered').append(registeredIcon);
        //
		// 	if (window.innerWidth < 1025 && window.innerWidth > 767){
		// 		$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
		// 			$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
		// 				if (!$(this).hasClass('fc-limited')){
		// 					$(this).addClass('fc-more-cell');
		// 					let singleEvent = '<a href="javascript:;" class="fc-more single-more">1 event</a>';
		// 					$(this).append(singleEvent);
		// 				}
		// 			});
		// 		});
		// 	}
        //
		// 	if (window.innerWidth < 768){
		// 		$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
		// 			$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
		// 				if (!$(this).hasClass('fc-limited')){
		// 					$(this).addClass('fc-more-cell');
		// 					let singleEvent = '<a href="javascript:;" class="fc-more single-more">+1</a>';
		// 					$(this).append(singleEvent);
		// 				}
		// 			});
		// 		});
		// 	}
        //
		// 	$('.single-more').on('click', function(e){
		// 		e.preventDefault();
        //
		// 		let singleEventContent = $(this).prev().html();
		// 		let catName1 = $(this).prev().data('cat1');
		// 		let catName2 = $(this).prev().data('cat2');
		// 		let eventDate = $(this).prev().data('date');
        //
		// 		let modal_contents = '<div class="modal-content">' +
		// 			'<a href="#" class="close">Close</a>' +
		// 			'<div class="modal-event-date">'+eventDate+'</div>' +
		// 			'<div class="row center-xs">';
        //
		// 		modal_contents += '<div class="col-xs-12">' +
		// 			'<div class="modal-event modal-event-1">' +
		// 			'<div class="modal-event-category-container">' +
		// 				'<div class="modal-event-category first '+catName1+'">'+catName1+'</div>' +
		// 				'<div class="modal-event-category second '+catName2+'">'+catName2+'</div>' +
		// 			'</div>' +
		// 			'<a href="#" class="modal-event-title">'+singleEventContent+'</a>' +
		// 			'</div>' +
		// 		'</div>';
		//
		// 		modal_contents += '</div></div>';
        //
		// 		$(this).addClass('re-focus');
		// 		$('body').addClass('no-scroll');
		// 		$('#modal').addClass('open');
		// 		$('.calendar-modal-overlay').addClass('open');
		// 		$('#modal .modal-events').html(modal_contents);
		// 		$('body a, body input, body button').attr('tabindex', '-1');
		// 		$('#modal a').attr('tabindex', '0');
		// 		$('#modal .close').focus();
		// 	});
		//
		// 	$('#tablet-calendar-settings').removeClass('active');
		// 	$('.tablet-settings-overlay').removeClass('active');
		// });

		// reset button
		// var $dropdown = $('#calendar-region').selectric();
		// $('#advanced-reset-btn').on('click', function (e) {
		// 	e.preventDefault();
		// 	$("#advanced-filter-form").trigger("reset");
		// 	$dropdown.refresh();
		// 	$('#tablet-calendar-settings .control').removeClass('active');
		// 	$('#tablet-calendar-settings .control input').prop('checked', false);
		// 	$('.fc-event').css('display', 'block');
		// 	$('.event').css('display', 'block');
		// });




		//////////////////////// EVENTS ////////////////////////
		let eventList = [
		{
			title  : '1 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 9, 28, 9),
			end    : new Date(2019, 9, 28, 13),
			className : ['category-cle-program', 'registered'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
			categoryName : ['CLE Program'] // the category names are what is used for the tooltips
		},
		{
			title  : '2 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 9, 28, 9),
			end    : new Date(2019, 9, 28, 10),
			className : ['category-other', 'registered'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		{
			title  : '3 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 9, 29, 9),
			end    : new Date(2019, 9, 29, 10),
			className : ['category-cle-program', 'registered'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
			categoryName : ['CLE Program'] // the category names are what is used for the tooltips
		},
		{
			title  : '4 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 9, 29, 9),
			end    : new Date(2019, 9, 29, 10),
			className : ['category-other', 'registered'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		{
			title  : '5 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 9, 30, 9),
			end    : new Date(2019, 9, 30, 10),
			className : ['category-cle-program'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
			categoryName : ['CLE Program'] // the category names are what is used for the tooltips
		},
		{
			title  : '6 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 9, 30, 9),
			end    : new Date(2019, 9, 30, 10),
			className : ['category-section-seasonal'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
			categoryName : ['Section Seasonal'] // the category names are what is used for the tooltips
		},
		{
			title  : '7 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 9, 30, 9),
			end    : new Date(2019, 9, 30, 10),
			className : ['category-informational-networking'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
			categoryName : ['Informational Networking'] // the category names are what is used for the tooltips
		},
		{
			title  : '8 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 9, 31, 9),
			end    : new Date(2019, 9, 31, 10),
			className : ['category-administrative', 'category-informational-networking'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
			categoryName : ['Administrative', 'Informational Networking'] // the category names are what is used for the tooltips
		},
		{
			title  : '9 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 10, 16, 9),
			end    : new Date(2019, 10, 16, 10),
			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		{
			title  : '10 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 10, 16, 9),
			end    : new Date(2019, 10, 16, 10),
			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		{
			title  : '11 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 10, 16, 9),
			end    : new Date(2019, 10, 16, 10),
			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		{
			title  : '12 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 10, 16, 9),
			end    : new Date(2019, 10, 16, 10),
			className : ['category-cle-program'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
			categoryName : ['CLE Program'] // the category names are what is used for the tooltips
		},
		{
			title  : '13 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 10, 20, 9),
			end    : new Date(2019, 10, 20, 10),
			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		{
			title  : '14 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 10, 21, 9),
			end    : new Date(2019, 10, 21, 10),
			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		{
			title  : '15 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 10, 22, 9),
			end    : new Date(2019, 10, 22, 10),
			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		{
			title  : '16 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 10, 23, 9),
			end    : new Date(2019, 10, 23, 10),
			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		{
			title  : '17 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 10, 23, 9),
			end    : new Date(2019, 10, 23, 12),
			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		{
			title  : '18 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 10, 23, 9),
			end    : new Date(2019, 10, 23, 14),
			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		{
			title  : '19 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 10, 23, 9),
			end    : new Date(2019, 10, 23, 15),
			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		{
			title  : '20 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 10, 23, 9),
			end    : new Date(2019, 10, 23, 17),
			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		{
			title  : '21 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 10, 24, 9),
			end    : new Date(2019, 10, 24, 10),
			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		{
			title  : '22 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
			thumb : 'event-thumb.jpg',
			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
			start  : new Date(2019, 10, 25, 9),
			end    : new Date(2019, 10, 25, 10),
			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
			categoryName : ['Other'] // the category names are what is used for the tooltips
		},
		];

		// initilizing calendar - https://fullcalendar.io/docs/v4
		// var calendarEl = document.getElementById('calendar');

		// if (window.innerWidth > 1024){
		// 	var calendar = new Calendar(calendarEl, {
		// 		header: {
		// 			left: 'prev',
		// 			center: 'title',
		// 			right: 'next'
		// 		},
		// 		plugins: [ momentPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin ],
		// 		views: {
		// 			timeGridWeek: {
		// 				columnHeaderFormat: { day: 'numeric', weekday: 'long' },
		// 			},
		// 			dayGridMonth: {
		// 				eventLimit: 3,
		// 				columnHeaderFormat: { weekday: 'long' },
		// 			}
		// 		},
		// 		nextDayThreshold: '11:59:59',
		// 		editable: false,
		// 		eventOrder: 'start',
		// 		events: eventList,
		// 		eventRender: function(info) {
		// 			//console.log(info);
		// 			let eventDate = info.event.start;
		// 			eventDate = moment(eventDate).format('YYYY-MM-DD');
		// 			$('.fc td.fc-day').each(function(i){
		// 				if ($(this).data('date') == eventDate){
		// 					$(this).addClass('has-events');
		// 				}
		// 			});
		// 			if (!$('.filter-all').hasClass('active')){
		// 				return filter(info.event);
		// 			}
		// 		},
		// 		eventLimitClick: function(info) {
		// 			let eventArray = info.segs;
		// 			let eventDate = eventArray[0].eventRange.range.start;
		// 			let eventDateEnd = eventArray[0].eventRange.range.end;
		// 			eventDate = moment(eventDate).format('MMMM DD, YYYY');
        //
		// 			let modal_contents = '<div class="modal-content">' +
		// 				'<a href="#" class="close">Close</a>' +
		// 				'<div class="modal-event-date">'+eventDate+'</div>' +
		// 				'<div class="row center-xs">';
        //
		// 			for (var i = 0; i < eventArray.length; i++){
		// 				let cat1 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][0];
		// 				let cat2 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][1];
		// 				let title = eventArray[i]['eventRange']['def']['title'];
		// 				modal_contents += '<div class="col-xs-6">' +
		// 					'<div class="modal-event modal-event-'+i+'">' +
		// 					'<div class="modal-event-category-container">' +
		// 						'<div class="modal-event-category first '+cat1+'">'+cat1+'</div>' +
		// 						'<div class="modal-event-category second '+cat2+'">'+cat2+'</div>' +
		// 					'</div>' +
		// 					'<a href="#" class="modal-event-title">'+title+'</a>' +
		// 					'</div>' +
		// 				'</div>';
		// 			}
        //
		// 			modal_contents += '</div></div>';
        //
		// 			info.moreEl.classList.add('re-focus');
		// 			$('body').addClass('no-scroll');
		// 			$('#modal').addClass('open');
		// 			$('.calendar-modal-overlay').addClass('open');
		// 			$('#modal .modal-events').html(modal_contents);
		// 			$('body a, body input, body button').attr('tabindex', '-1');
		// 			$('#modal a').attr('tabindex', '0');
		// 			$('#modal .close').focus();
		// 		},
		// 		eventMouseEnter: function (el) {
		// 			var tis = el.el;
		// 			let tooltipClass1 = 'class="' + el.event.classNames[0] + '"';
		// 			let tooltipClass2 = 'class="' + el.event.classNames[1] + '"';
		// 			let tooltipCategory1 = el.event.extendedProps.categoryName[0];
		// 			let tooltipCategory2 = el.event.extendedProps.categoryName[1];
		//
		// 			let tooltip = '<div class="tooltip">'+
		// 			'<div ' + tooltipClass1 + '>' + tooltipCategory1 + '</div>' +
		// 			'<div ' + tooltipClass2 + '>' + tooltipCategory2 + '</div>' +
		// 			el.event.title +
		// 			'</div>';
        //
		// 			let $tooltip = $(tooltip).appendTo('body');
		// 			$('.tooltip').find('.undefined').remove();
		// 			$('.tooltip').find('.registered').remove();
		// 			let offsetTop = $(tis).offset().top - ($('.tooltip').height() + 80);
		// 			let offsetLeft = $(tis).offset().left - ($('.tooltip').width() / 2);
		// 			$tooltip.css('top', offsetTop+'px');
		// 			$tooltip.css('left', offsetLeft+'px');
        //
		// 			$(tis).mouseover(function(e) {
		// 				$(tis).css('z-index', 10000);
		// 				$tooltip.fadeIn('500');
		// 				$tooltip.fadeTo('10', 1.9);
		// 			}).mousemove(function(e) {
		// 				let offsetTop = e.pageY - ($('.tooltip').height() + 80);
		// 				let offsetLeft = e.pageX - ($('.tooltip').width() / 2);
		// 				$tooltip.css('top', offsetTop+'px');
		// 				$tooltip.css('left', offsetLeft+'px');
		// 			});
		// 		},
		// 		eventMouseLeave: function (el, view) {
		// 			$(el.el).css('z-index', 8);
		// 			$('.tooltip').remove();
		// 		},
		// 		dateClick: function () {
		// 			$('.tooltip').hide();
		// 		},
		// 		eventResizeStart: function () {
		// 			$('.tooltip').hide();
		// 		},
		// 		eventDragStart: function () {
		// 			$('.tooltip').hide();
		// 		},
		// 		viewDisplay: function () {
		// 			$('.tooltip').hide();
		// 		},
		// 	});
        //
		// 	// Return Filters
		// 	function filter(event) {
		// 		var vals = [];
		// 		$('#desktop-calendar-header ul li.filter-cat').each(function() {
		// 			if ($(this).hasClass('active')){
		// 				vals.push($(this).data('cats'));
		// 			}
		// 		});
		// 		for (var i = 0; i < vals.length; i++){
		// 			if (vals.indexOf(event.classNames[i]) === -1) {
		// 				return false;
		// 			} else {
		// 				return true;
		// 			}
		// 		}
		// 	}
		// }

		// if (window.innerWidth < 1025 && window.innerWidth > 767){
		// 	var calendar = new Calendar(calendarEl, {
		// 		header: {
		// 			left: 'prev',
		// 			center: 'title',
		// 			right: 'next'
		// 		},
		// 		plugins: [ momentPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin ],
		// 		views: {
		// 			timeGridWeek: {
		// 				columnHeaderFormat: { day: 'numeric', weekday: 'short' },
		// 			},
		// 			dayGridMonth: {
		// 				eventLimit: 1,
		// 				eventLimitText: function (numEvents) {
		// 					return numEvents+ ' events';
		// 				},
		// 				columnHeaderFormat: { weekday: 'short' },
		// 			}
		// 		},
		// 		nextDayThreshold: '11:59:59',
		// 		editable: false,
		// 		events: eventList,
		// 		eventRender: function(info) {
		// 			let eventDate = info.event.start;
		// 			eventDate = moment(eventDate).format('YYYY-MM-DD');
		// 			$('.fc td.fc-day').each(function(i){
		// 				if ($(this).data('date') == eventDate){
		// 					$(this).addClass('has-events');
		// 				}
		// 			});
		// 			let modalDate = moment(info.event.start).format('MMMM DD, YYYY');
		// 			let modalCategory1 = info.event.extendedProps.categoryName[0];
		// 			let modalCategory2 = info.event.extendedProps.categoryName[1];
		// 			$(info.el).attr('data-date', modalDate);
		// 			$(info.el).attr('data-cat1', modalCategory1);
		// 			$(info.el).attr('data-cat2', modalCategory2);
		// 			return filter(info.event);
		// 		},
		// 		eventLimitClick: function(info) {
		// 			let eventArray = info.segs;
		// 			let eventDate = eventArray[0].eventRange.range.start;
		// 			let eventDateEnd = eventArray[0].eventRange.range.end;
		// 			eventDate = moment(eventDate).format('MMMM DD, YYYY');
        //
		// 			let modal_contents = '<div class="modal-content">' +
		// 				'<a href="#" class="close">Close</a>' +
		// 				'<div class="modal-event-date">'+eventDate+'</div>' +
		// 				'<div class="row center-xs">';
        //
		// 			for (var i = 0; i < eventArray.length; i++){
		// 				let cat1 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][0];
		// 				let cat2 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][1];
		// 				let title = eventArray[i]['eventRange']['def']['title'];
		// 				modal_contents += '<div class="col-xs-6">' +
		// 					'<div class="modal-event modal-event-'+i+'">' +
		// 					'<div class="modal-event-category-container">' +
		// 						'<div class="modal-event-category first '+cat1+'">'+cat1+'</div>' +
		// 						'<div class="modal-event-category second '+cat2+'">'+cat2+'</div>' +
		// 					'</div>' +
		// 					'<a href="#" class="modal-event-title">'+title+'</a>' +
		// 					'</div>' +
		// 				'</div>';
		// 			}
        //
		// 			modal_contents += '</div></div>';
        //
		// 			info.moreEl.classList.add('re-focus');
		// 			$('body').addClass('no-scroll');
		// 			$('#modal').addClass('open');
		// 			$('.calendar-modal-overlay').addClass('open');
		// 			$('#modal .modal-events').html(modal_contents);
		// 			$('body a, body input, body button').attr('tabindex', '-1');
		// 			$('#modal a').attr('tabindex', '0');
		// 			$('#modal .close').focus();
		// 		},
		// 		eventMouseEnter: function (el) {
		// 			var tis = el.el;
		// 			let tooltipClass1 = 'class="' + el.event.classNames[0] + '"';
		// 			let tooltipClass2 = 'class="' + el.event.classNames[1] + '"';
		// 			let tooltipCategory1 = el.event.extendedProps.categoryName[0];
		// 			let tooltipCategory2 = el.event.extendedProps.categoryName[1];
		//
		// 			let tooltip = '<div class="tooltip">'+
		// 			'<div ' + tooltipClass1 + '>' + tooltipCategory1 + '</div>' +
		// 			'<div ' + tooltipClass2 + '>' + tooltipCategory2 + '</div>' +
		// 			el.event.title +
		// 			'</div>';
        //
		// 			let $tooltip = $(tooltip).appendTo('body');
		// 			$('.tooltip').find('.undefined').remove();
		// 			$('.tooltip').find('.registered').remove();
		// 			let offsetTop = $(tis).offset().top - ($('.tooltip').height() + 80);
		// 			let offsetLeft = $(tis).offset().left - ($('.tooltip').width() / 2);
		// 			$tooltip.css('top', offsetTop+'px');
		// 			$tooltip.css('left', offsetLeft+'px');
        //
		// 			$(tis).mouseover(function(e) {
		// 				$(tis).css('z-index', 10000);
		// 				$tooltip.fadeIn('500');
		// 				$tooltip.fadeTo('10', 1.9);
		// 			}).mousemove(function(e) {
		// 				let offsetTop = e.pageY - ($('.tooltip').height() + 80);
		// 				let offsetLeft = e.pageX - ($('.tooltip').width() / 2);
		// 				$tooltip.css('top', offsetTop+'px');
		// 				$tooltip.css('left', offsetLeft+'px');
		// 			});
		// 		},
		// 		eventMouseLeave: function (el, view) {
		// 			$(el.el).css('z-index', 8);
		// 			$('.tooltip').remove();
		// 		},
		// 		dateClick: function () {
		// 			$('.tooltip').hide();
		// 		},
		// 		eventResizeStart: function () {
		// 			$('.tooltip').hide();
		// 		},
		// 		eventDragStart: function () {
		// 			$('.tooltip').hide();
		// 		},
		// 		viewDisplay: function () {
		// 			$('.tooltip').hide();
		// 		},
		// 	});
        //
		// 	// Return Filters
		// 	function filter(event) {
		// 		var vals = [];
		// 		$('#tablet-calendar-settings .control').each(function() {
		// 			if ($(this).hasClass('active')){
		// 				vals.push($(this).data('category'));
		// 			}
		// 		});
		// 		for (var i = 0; i < vals.length; i++){
		// 			if (vals.indexOf(event.classNames[i]) === -1) {
		// 				return false;
		// 			} else {
		// 				return true;
		// 			}
		// 		}
		// 	}
		// }
		
		// if (window.innerWidth < 768){
		// 	var calendar = new Calendar(calendarEl, {
		// 		header: {
		// 			left: 'prev',
		// 			center: 'title',
		// 			right: 'next'
		// 		},
		// 		titleFormat: { month: 'short', day: 'numeric' },
		// 		plugins: [ momentPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin ],
		// 		views: {
		// 			timeGridWeek: {
		// 				columnHeaderFormat: { day: 'numeric', weekday: 'short' },
		// 			},
		// 			dayGridMonth: {
		// 				eventLimit: 1,
		// 				eventLimitText: function (numEvents) {
		// 					return '+'+numEvents;
		// 				},
		// 				columnHeaderFormat: { weekday: 'short' },
		// 			}
		// 		},
		// 		nextDayThreshold: '11:59:59',
		// 		editable: false,
		// 		events: eventList,
		// 		eventRender: function(info) {
		// 			let eventDate = info.event.start;
		// 			eventDate = moment(eventDate).format('YYYY-MM-DD');
		// 			$('.fc td.fc-day').each(function(i){
		// 				if ($(this).data('date') == eventDate){
		// 					$(this).addClass('has-events');
		// 				}
		// 			});
		// 			let modalDate = moment(info.event.start).format('MMMM DD, YYYY');
		// 			let modalCategory1 = info.event.extendedProps.categoryName[0];
		// 			let modalCategory2 = info.event.extendedProps.categoryName[1];
		// 			$(info.el).attr('data-date', modalDate);
		// 			$(info.el).attr('data-cat1', modalCategory1);
		// 			$(info.el).attr('data-cat2', modalCategory2);
		// 			return filter(info.event);
		// 		},
		// 		eventLimitClick: function(info) {
		// 			let eventArray = info.segs;
		// 			let eventDate = eventArray[0].eventRange.range.start;
		// 			let eventDateEnd = eventArray[0].eventRange.range.end;
		// 			eventDate = moment(eventDate).format('MMMM DD, YYYY');
        //
		// 			let modal_contents = '<div class="modal-content">' +
		// 				'<a href="#" class="close">Close</a>' +
		// 				'<div class="modal-event-date">'+eventDate+'</div>' +
		// 				'<div class="row center-xs">';
        //
		// 			for (var i = 0; i < eventArray.length; i++){
		// 				let cat1 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][0];
		// 				let cat2 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][1];
		// 				let title = eventArray[i]['eventRange']['def']['title'];
		// 				modal_contents += '<div class="col-xs-6">' +
		// 					'<div class="modal-event modal-event-'+i+'">' +
		// 					'<div class="modal-event-category-container">' +
		// 						'<div class="modal-event-category first '+cat1+'">'+cat1+'</div>' +
		// 						'<div class="modal-event-category second '+cat2+'">'+cat2+'</div>' +
		// 					'</div>' +
		// 					'<a href="#" class="modal-event-title">'+title+'</a>' +
		// 					'</div>' +
		// 				'</div>';
		// 			}
        //
		// 			modal_contents += '</div></div>';
        //
		// 			info.moreEl.classList.add('re-focus');
		// 			$('body').addClass('no-scroll');
		// 			$('#modal').addClass('open');
		// 			$('.calendar-modal-overlay').addClass('open');
		// 			$('#modal .modal-events').html(modal_contents);
		// 			$('body a, body input, body button').attr('tabindex', '-1');
		// 			$('#modal a').attr('tabindex', '0');
		// 			$('#modal .close').focus();
		// 		},
		// 		eventMouseEnter: function (el) {
		// 			var tis = el.el;
		// 			let tooltipClass1 = 'class="' + el.event.classNames[0] + '"';
		// 			let tooltipClass2 = 'class="' + el.event.classNames[1] + '"';
		// 			let tooltipCategory1 = el.event.extendedProps.categoryName[0];
		// 			let tooltipCategory2 = el.event.extendedProps.categoryName[1];
		//
		// 			let tooltip = '<div class="tooltip">'+
		// 			'<div ' + tooltipClass1 + '>' + tooltipCategory1 + '</div>' +
		// 			'<div ' + tooltipClass2 + '>' + tooltipCategory2 + '</div>' +
		// 			el.event.title +
		// 			'</div>';
        //
		// 			let $tooltip = $(tooltip).appendTo('body');
		// 			$('.tooltip').find('.undefined').remove();
		// 			$('.tooltip').find('.registered').remove();
		// 			let offsetTop = $(tis).offset().top - ($('.tooltip').height() + 80);
		// 			let offsetLeft = $(tis).offset().left - ($('.tooltip').width() / 2);
		// 			$tooltip.css('top', offsetTop+'px');
		// 			$tooltip.css('left', offsetLeft+'px');
        //
		// 			$(tis).mouseover(function(e) {
		// 				$(tis).css('z-index', 10000);
		// 				$tooltip.fadeIn('500');
		// 				$tooltip.fadeTo('10', 1.9);
		// 			}).mousemove(function(e) {
		// 				let offsetTop = e.pageY - ($('.tooltip').height() + 80);
		// 				let offsetLeft = e.pageX - ($('.tooltip').width() / 2);
		// 				$tooltip.css('top', offsetTop+'px');
		// 				$tooltip.css('left', offsetLeft+'px');
		// 			});
		// 		},
		// 		eventMouseLeave: function (el, view) {
		// 			$(el.el).css('z-index', 8);
		// 			$('.tooltip').remove();
		// 		},
		// 		dateClick: function () {
		// 			$('.tooltip').hide();
		// 		},
		// 		eventResizeStart: function () {
		// 			$('.tooltip').hide();
		// 		},
		// 		eventDragStart: function () {
		// 			$('.tooltip').hide();
		// 		},
		// 		viewDisplay: function () {
		// 			$('.tooltip').hide();
		// 		},
		// 	});
        //
		// 	// Return Filters
		// 	function filter(event) {
		// 		var vals = [];
		// 		$('#tablet-calendar-settings .control').each(function() {
		// 			if ($(this).hasClass('active')){
		// 				vals.push($(this).data('category'));
		// 			}
		// 		});
		// 		for (var i = 0; i < vals.length; i++){
		// 			if (vals.indexOf(event.classNames[i]) === -1) {
		// 				return false;
		// 			} else {
		// 				return true;
		// 			}
		// 		}
		// 	}
		// }

		// render calendar
		// calendar.render();
		// calendar.changeView('dayGridMonth');

		// $('.calendar-keys').remove();
		// var calendarKeys = '<div class="calendar-keys"><ul><li class="no-events">Not available</li><li class="searched-event">Searched event</li><li class="today-event">Today</li></ul><p>Tap each date to see full list of programs</p></div>';
		// $('.fc-toolbar').append(calendarKeys);

		// registererd event icon
		// let registeredIcon = '<div class="registered-icon">Registered</div>';
		// $('.registered').append(registeredIcon);

		// single events
		// if (window.innerWidth < 1025 && window.innerWidth > 767){
		// 	$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
		// 		$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
		// 			if (!$(this).hasClass('fc-limited')){
		// 				$(this).addClass('fc-more-cell');
		// 				let singleEvent = '<a href="javascript:;" class="fc-more single-more">1 event</a>';
		// 				$(this).append(singleEvent);
		// 			}
		// 		});
		// 	});
		// }

		// if (window.innerWidth < 768){
		// 	$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
		// 		$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
		// 			if (!$(this).hasClass('fc-limited')){
		// 				$(this).addClass('fc-more-cell');
		// 				let singleEvent = '<a href="javascript:;" class="fc-more single-more">+1</a>';
		// 				$(this).append(singleEvent);
		// 			}
		// 		});
		// 	});
		// }

		// single event modal
		// $('.single-more').on('click', function(e){
		// 	e.preventDefault();
        //
		// 	let singleEventContent = $(this).prev().html();
		// 	let catName1 = $(this).prev().data('cat1');
		// 	let catName2 = $(this).prev().data('cat2');
		// 	let eventDate = $(this).prev().data('date');
        //
		// 	let modal_contents = '<div class="modal-content">' +
		// 		'<a href="#" class="close">Close</a>' +
		// 		'<div class="modal-event-date">'+eventDate+'</div>' +
		// 		'<div class="row center-xs">';
        //
		// 	modal_contents += '<div class="col-xs-12">' +
		// 		'<div class="modal-event modal-event-1">' +
		// 		'<div class="modal-event-category-container">' +
		// 			'<div class="modal-event-category first '+catName1+'">'+catName1+'</div>' +
		// 			'<div class="modal-event-category second '+catName2+'">'+catName2+'</div>' +
		// 		'</div>' +
		// 		'<a href="#" class="modal-event-title">'+singleEventContent+'</a>' +
		// 		'</div>' +
		// 	'</div>';
		//
		// 	modal_contents += '</div></div>';
        //
		// 	$(this).addClass('re-focus');
		// 	$('body').addClass('no-scroll');
		// 	$('#modal').addClass('open');
		// 	$('.calendar-modal-overlay').addClass('open');
		// 	$('#modal .modal-events').html(modal_contents);
		// 	$('body a, body input, body button').attr('tabindex', '-1');
		// 	$('#modal a').attr('tabindex', '0');
		// 	$('#modal .close').focus();
		// });

		// resize event
		// var rtime;
		// var timeout = false;
		// var delta = 200;
		// var hasScrolled = false;
		// $(window).on('resize', function(){
		// 	rtime = new Date();
		// 	if (timeout === false) {
		// 		timeout = true;
		// 		setTimeout(resizeend, delta);
		// 	}
		// });

		// function resizeend() {
		// 	if (new Date() - rtime < delta) {
		// 		setTimeout(resizeend, delta);
		// 	} else {
		// 		timeout = false;
		//
		// 		if (window.innerWidth > 1024){
		// 			var calendar = new Calendar(calendarEl, {
		// 				header: {
		// 					left: 'prev',
		// 					center: 'title',
		// 					right: 'next'
		// 				},
		// 				plugins: [ momentPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin ],
		// 				views: {
		// 					timeGridWeek: {
		// 						columnHeaderFormat: { day: 'numeric', weekday: 'long' },
		// 					},
		// 					dayGridMonth: {
		// 						eventLimit: 3,
		// 						columnHeaderFormat: { weekday: 'long' },
		// 					}
		// 				},
		// 				nextDayThreshold: '11:59:59',
		// 				editable: false,
		// 				events: eventList,
		// 				eventRender: function(info) {
		// 					let eventDate = info.event.start;
		// 					eventDate = moment(eventDate).format('YYYY-MM-DD');
		// 					$('.fc td.fc-day').each(function(i){
		// 						if ($(this).data('date') == eventDate){
		// 							$(this).addClass('has-events');
		// 						}
		// 					});
		// 					if (!$('.filter-all').hasClass('active')){
		// 						return filter(info.event);
		// 					}
		// 				},
		// 				eventLimitClick: function(info) {
		// 					let eventArray = info.segs;
		// 					let eventDate = eventArray[0].eventRange.range.start;
		// 					let eventDateEnd = eventArray[0].eventRange.range.end;
		// 					eventDate = moment(eventDate).format('MMMM DD, YYYY');
        //
		// 					let modal_contents = '<div class="modal-content">' +
		// 						'<a href="#" class="close">Close</a>' +
		// 						'<div class="modal-event-date">'+eventDate+'</div>' +
		// 						'<div class="row center-xs">';
        //
		// 					for (var i = 0; i < eventArray.length; i++){
		// 						let cat1 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][0];
		// 						let cat2 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][1];
		// 						let title = eventArray[i]['eventRange']['def']['title'];
		// 						modal_contents += '<div class="col-xs-6">' +
		// 							'<div class="modal-event modal-event-'+i+'">' +
		// 							'<div class="modal-event-category-container">' +
		// 								'<div class="modal-event-category first '+cat1+'">'+cat1+'</div>' +
		// 								'<div class="modal-event-category second '+cat2+'">'+cat2+'</div>' +
		// 							'</div>' +
		// 							'<a href="#" class="modal-event-title">'+title+'</a>' +
		// 							'</div>' +
		// 						'</div>';
		// 					}
        //
		// 					modal_contents += '</div></div>';
        //
		// 					$(this).addClass('re-focus');
		// 					$('body').addClass('no-scroll');
		// 					$('#modal').addClass('open');
		// 					$('.calendar-modal-overlay').addClass('open');
		// 					$('#modal .modal-events').html(modal_contents);
		// 					$('body a, body input, body button').attr('tabindex', '-1');
		// 					$('#modal a').attr('tabindex', '0');
		// 					$('#modal .close').focus();
		// 				},
		// 				eventMouseEnter: function (el) {
		// 					var tis = el.el;
		// 					let tooltipClass1 = 'class="' + el.event.classNames[0] + '"';
		// 					let tooltipClass2 = 'class="' + el.event.classNames[1] + '"';
		// 					let tooltipCategory1 = el.event.extendedProps.categoryName[0];
		// 					let tooltipCategory2 = el.event.extendedProps.categoryName[1];
		//
		// 					let tooltip = '<div class="tooltip">'+
		// 					'<div ' + tooltipClass1 + '>' + tooltipCategory1 + '</div>' +
		// 					'<div ' + tooltipClass2 + '>' + tooltipCategory2 + '</div>' +
		// 					el.event.title +
		// 					'</div>';
        //
		// 					let $tooltip = $(tooltip).appendTo('body');
		// 					$('.tooltip').find('.undefined').remove();
		// 					$('.tooltip').find('.registered').remove();
		// 					let offsetTop = $(tis).offset().top - ($('.tooltip').height() + 80);
		// 					let offsetLeft = $(tis).offset().left - ($('.tooltip').width() / 2);
		// 					$tooltip.css('top', offsetTop+'px');
		// 					$tooltip.css('left', offsetLeft+'px');
        //
		// 					$(tis).mouseover(function(e) {
		// 						$(tis).css('z-index', 10000);
		// 						$tooltip.fadeIn('500');
		// 						$tooltip.fadeTo('10', 1.9);
		// 					}).mousemove(function(e) {
		// 						let offsetTop = e.pageY - ($('.tooltip').height() + 80);
		// 						let offsetLeft = e.pageX - ($('.tooltip').width() / 2);
		// 						$tooltip.css('top', offsetTop+'px');
		// 						$tooltip.css('left', offsetLeft+'px');
		// 					});
		// 				},
		// 				eventMouseLeave: function (el, view) {
		// 					$(el.el).css('z-index', 8);
		// 					$('.tooltip').remove();
		// 				},
		// 				dateClick: function () {
		// 					$('.tooltip').hide();
		// 				},
		// 				eventResizeStart: function () {
		// 					$('.tooltip').hide();
		// 				},
		// 				eventDragStart: function () {
		// 					$('.tooltip').hide();
		// 				},
		// 				viewDisplay: function () {
		// 					$('.tooltip').hide();
		// 				},
		// 			});
        //
		// 			// Return Filters
		// 			function filter(event) {
		// 				var vals = [];
		// 				$('#desktop-calendar-header ul li.filter-cat').each(function() {
		// 					if ($(this).hasClass('active')){
		// 						vals.push($(this).data('cats'));
		// 					}
		// 				});
		// 				for (var i = 0; i < vals.length; i++){
		// 					if (vals.indexOf(event.classNames[i]) === -1) {
		// 						return false;
		// 					} else {
		// 						return true;
		// 					}
		// 				}
		// 			}
		// 		}
		// 		if (window.innerWidth < 1025 && window.innerWidth > 767){
		// 			var calendar = new Calendar(calendarEl, {
		// 				header: {
		// 					left: 'prev',
		// 					center: 'title',
		// 					right: 'next'
		// 				},
		// 				plugins: [ momentPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin ],
		// 				views: {
		// 					timeGridWeek: {
		// 						columnHeaderFormat: { day: 'numeric', weekday: 'short' },
		// 					},
		// 					dayGridMonth: {
		// 						eventLimit: 1,
		// 						eventLimitText: function (numEvents) {
		// 							return numEvents+ ' events';
		// 						},
		// 						columnHeaderFormat: { weekday: 'short' },
		// 					}
		// 				},
		// 				nextDayThreshold: '11:59:59',
		// 				editable: false,
		// 				events: eventList,
		// 				eventRender: function(info) {
		// 					let eventDate = info.event.start;
		// 					eventDate = moment(eventDate).format('YYYY-MM-DD');
		// 					$('.fc td.fc-day').each(function(i){
		// 						if ($(this).data('date') == eventDate){
		// 							$(this).addClass('has-events');
		// 						}
		// 					});
		// 					let modalDate = moment(info.event.start).format('MMMM DD, YYYY');
		// 					let modalCategory1 = info.event.extendedProps.categoryName[0];
		// 					let modalCategory2 = info.event.extendedProps.categoryName[1];
		// 					$(info.el).attr('data-date', modalDate);
		// 					$(info.el).attr('data-cat1', modalCategory1);
		// 					$(info.el).attr('data-cat2', modalCategory2);
		// 					return filter(info.event);
		// 				},
		// 				eventLimitClick: function(info) {
		// 					let eventArray = info.segs;
		// 					let eventDate = eventArray[0].eventRange.range.start;
		// 					let eventDateEnd = eventArray[0].eventRange.range.end;
		// 					eventDate = moment(eventDate).format('MMMM DD, YYYY');
        //
		// 					let modal_contents = '<div class="modal-content">' +
		// 						'<a href="#" class="close">Close</a>' +
		// 						'<div class="modal-event-date">'+eventDate+'</div>' +
		// 						'<div class="row center-xs">';
        //
		// 					for (var i = 0; i < eventArray.length; i++){
		// 						let cat1 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][0];
		// 						let cat2 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][1];
		// 						let title = eventArray[i]['eventRange']['def']['title'];
		// 						modal_contents += '<div class="col-xs-6">' +
		// 							'<div class="modal-event modal-event-'+i+'">' +
		// 							'<div class="modal-event-category-container">' +
		// 								'<div class="modal-event-category first '+cat1+'">'+cat1+'</div>' +
		// 								'<div class="modal-event-category second '+cat2+'">'+cat2+'</div>' +
		// 							'</div>' +
		// 							'<a href="#" class="modal-event-title">'+title+'</a>' +
		// 							'</div>' +
		// 						'</div>';
		// 					}
        //
		// 					modal_contents += '</div></div>';
        //
		// 					$(this).addClass('re-focus');
		// 					$('body').addClass('no-scroll');
		// 					$('#modal').addClass('open');
		// 					$('.calendar-modal-overlay').addClass('open');
		// 					$('#modal .modal-events').html(modal_contents);
		// 					$('body a, body input, body button').attr('tabindex', '-1');
		// 					$('#modal a').attr('tabindex', '0');
		// 					$('#modal .close').focus();
		// 				},
		// 				eventMouseEnter: function (el) {
		// 					var tis = el.el;
		// 					let tooltipClass1 = 'class="' + el.event.classNames[0] + '"';
		// 					let tooltipClass2 = 'class="' + el.event.classNames[1] + '"';
		// 					let tooltipCategory1 = el.event.extendedProps.categoryName[0];
		// 					let tooltipCategory2 = el.event.extendedProps.categoryName[1];
		//
		// 					let tooltip = '<div class="tooltip">'+
		// 					'<div ' + tooltipClass1 + '>' + tooltipCategory1 + '</div>' +
		// 					'<div ' + tooltipClass2 + '>' + tooltipCategory2 + '</div>' +
		// 					el.event.title +
		// 					'</div>';
        //
		// 					let $tooltip = $(tooltip).appendTo('body');
		// 					$('.tooltip').find('.undefined').remove();
		// 					$('.tooltip').find('.registered').remove();
		// 					let offsetTop = $(tis).offset().top - ($('.tooltip').height() + 80);
		// 					let offsetLeft = $(tis).offset().left - ($('.tooltip').width() / 2);
		// 					$tooltip.css('top', offsetTop+'px');
		// 					$tooltip.css('left', offsetLeft+'px');
        //
		// 					$(tis).mouseover(function(e) {
		// 						$(tis).css('z-index', 10000);
		// 						$tooltip.fadeIn('500');
		// 						$tooltip.fadeTo('10', 1.9);
		// 					}).mousemove(function(e) {
		// 						let offsetTop = e.pageY - ($('.tooltip').height() + 80);
		// 						let offsetLeft = e.pageX - ($('.tooltip').width() / 2);
		// 						$tooltip.css('top', offsetTop+'px');
		// 						$tooltip.css('left', offsetLeft+'px');
		// 					});
		// 				},
		// 				eventMouseLeave: function (el, view) {
		// 					$(el.el).css('z-index', 8);
		// 					$('.tooltip').remove();
		// 				},
		// 				dateClick: function () {
		// 					$('.tooltip').hide();
		// 				},
		// 				eventResizeStart: function () {
		// 					$('.tooltip').hide();
		// 				},
		// 				eventDragStart: function () {
		// 					$('.tooltip').hide();
		// 				},
		// 				viewDisplay: function () {
		// 					$('.tooltip').hide();
		// 				},
		// 			});
        //
		// 			// Return Filters
		// 			function filter(event) {
		// 				var vals = [];
		// 				$('#tablet-calendar-settings .control').each(function() {
		// 					if ($(this).hasClass('active')){
		// 						vals.push($(this).data('category'));
		// 					}
		// 				});
		// 				for (var i = 0; i < vals.length; i++){
		// 					if (vals.indexOf(event.classNames[i]) === -1) {
		// 						return false;
		// 					} else {
		// 						return true;
		// 					}
		// 				}
		// 			}
		// 		}
		// 		if (window.innerWidth < 768){
		// 			var calendar = new Calendar(calendarEl, {
		// 				header: {
		// 					left: 'prev',
		// 					center: 'title',
		// 					right: 'next'
		// 				},
		// 				titleFormat: { month: 'short', day: 'numeric' },
		// 				plugins: [ momentPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin ],
		// 				views: {
		// 					timeGridWeek: {
		// 						columnHeaderFormat: { day: 'numeric', weekday: 'short' },
		// 					},
		// 					dayGridMonth: {
		// 						eventLimit: 1,
		// 						eventLimitText: function (numEvents) {
		// 							return '+'+numEvents;
		// 						},
		// 						columnHeaderFormat: { weekday: 'short' },
		// 					}
		// 				},
		// 				nextDayThreshold: '11:59:59',
		// 				editable: false,
		// 				events: eventList,
		// 				eventRender: function(info) {
		// 					let eventDate = info.event.start;
		// 					eventDate = moment(eventDate).format('YYYY-MM-DD');
		// 					$('.fc td.fc-day').each(function(i){
		// 						if ($(this).data('date') == eventDate){
		// 							$(this).addClass('has-events');
		// 						}
		// 					});
		// 					let modalDate = moment(info.event.start).format('MMMM DD, YYYY');
		// 					let modalCategory1 = info.event.extendedProps.categoryName[0];
		// 					let modalCategory2 = info.event.extendedProps.categoryName[1];
		// 					$(info.el).attr('data-date', modalDate);
		// 					$(info.el).attr('data-cat1', modalCategory1);
		// 					$(info.el).attr('data-cat2', modalCategory2);
		// 					return filter(info.event);
		// 				},
		// 				eventLimitClick: function(info) {
		// 					let eventArray = info.segs;
		// 					let eventDate = eventArray[0].eventRange.range.start;
		// 					let eventDateEnd = eventArray[0].eventRange.range.end;
		// 					eventDate = moment(eventDate).format('MMMM DD, YYYY');
        //
		// 					let modal_contents = '<div class="modal-content">' +
		// 						'<a href="#" class="close">Close</a>' +
		// 						'<div class="modal-event-date">'+eventDate+'</div>' +
		// 						'<div class="row center-xs">';
        //
		// 					for (var i = 0; i < eventArray.length; i++){
		// 						let cat1 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][0];
		// 						let cat2 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][1];
		// 						let title = eventArray[i]['eventRange']['def']['title'];
		// 						modal_contents += '<div class="col-xs-6">' +
		// 							'<div class="modal-event modal-event-'+i+'">' +
		// 							'<div class="modal-event-category-container">' +
		// 								'<div class="modal-event-category first '+cat1+'">'+cat1+'</div>' +
		// 								'<div class="modal-event-category second '+cat2+'">'+cat2+'</div>' +
		// 							'</div>' +
		// 							'<a href="#" class="modal-event-title">'+title+'</a>' +
		// 							'</div>' +
		// 						'</div>';
		// 					}
        //
		// 					modal_contents += '</div></div>';
        //
		// 					$(this).addClass('re-focus');
		// 					$('body').addClass('no-scroll');
		// 					$('#modal').addClass('open');
		// 					$('.calendar-modal-overlay').addClass('open');
		// 					$('#modal .modal-events').html(modal_contents);
		// 					$('body a, body input, body button').attr('tabindex', '-1');
		// 					$('#modal a').attr('tabindex', '0');
		// 					$('#modal .close').focus();
		// 				},
		// 				eventMouseEnter: function (el) {
		// 					var tis = el.el;
		// 					let tooltipClass1 = 'class="' + el.event.classNames[0] + '"';
		// 					let tooltipClass2 = 'class="' + el.event.classNames[1] + '"';
		// 					let tooltipCategory1 = el.event.extendedProps.categoryName[0];
		// 					let tooltipCategory2 = el.event.extendedProps.categoryName[1];
		//
		// 					let tooltip = '<div class="tooltip">'+
		// 					'<div ' + tooltipClass1 + '>' + tooltipCategory1 + '</div>' +
		// 					'<div ' + tooltipClass2 + '>' + tooltipCategory2 + '</div>' +
		// 					el.event.title +
		// 					'</div>';
        //
		// 					let $tooltip = $(tooltip).appendTo('body');
		// 					$('.tooltip').find('.undefined').remove();
		// 					$('.tooltip').find('.registered').remove();
		// 					let offsetTop = $(tis).offset().top - ($('.tooltip').height() + 80);
		// 					let offsetLeft = $(tis).offset().left - ($('.tooltip').width() / 2);
		// 					$tooltip.css('top', offsetTop+'px');
		// 					$tooltip.css('left', offsetLeft+'px');
        //
		// 					$(tis).mouseover(function(e) {
		// 						$(tis).css('z-index', 10000);
		// 						$tooltip.fadeIn('500');
		// 						$tooltip.fadeTo('10', 1.9);
		// 					}).mousemove(function(e) {
		// 						let offsetTop = e.pageY - ($('.tooltip').height() + 80);
		// 						let offsetLeft = e.pageX - ($('.tooltip').width() / 2);
		// 						$tooltip.css('top', offsetTop+'px');
		// 						$tooltip.css('left', offsetLeft+'px');
		// 					});
		// 				},
		// 				eventMouseLeave: function (el, view) {
		// 					$(el.el).css('z-index', 8);
		// 					$('.tooltip').remove();
		// 				},
		// 				dateClick: function () {
		// 					$('.tooltip').hide();
		// 				},
		// 				eventResizeStart: function () {
		// 					$('.tooltip').hide();
		// 				},
		// 				eventDragStart: function () {
		// 					$('.tooltip').hide();
		// 				},
		// 				viewDisplay: function () {
		// 					$('.tooltip').hide();
		// 				},
		// 			});
        //
		// 			// Return Filters
		// 			function filter(event) {
		// 				var vals = [];
		// 				$('#tablet-calendar-settings .control').each(function() {
		// 					if ($(this).hasClass('active')){
		// 						vals.push($(this).data('category'));
		// 					}
		// 				});
		// 				for (var i = 0; i < vals.length; i++){
		// 					if (vals.indexOf(event.classNames[i]) === -1) {
		// 						return false;
		// 					} else {
		// 						return true;
		// 					}
		// 				}
		// 			}
        //
		// 			if ($('.fc-timeGridWeek-view').length){
		// 				$('.prev-dates').remove();
		// 				$('.next-dates').remove();
		// 			}
		// 		}
        //
		// 		// month view
		// 		if ($('.fc-dayGridMonth-view').length){
        //
		// 			calendar.destroy();
		// 			$('#calendar').html(' ');
		// 			calendar.render();
		// 			calendar.changeView('dayGridMonth');
        //
		// 			$('#calendar .fc-prev-button .prev-dates').remove();
		// 			$('#calendar .fc-next-button .next-dates').remove();
		// 			$('#prettydropdown-calendar-date-display li').removeClass('selected');
		// 			$('#prettydropdown-calendar-date-display li[data-value="Monthly"]').addClass('selected');
		// 			$('.onoffswitch input').prop('checked', true);
		//
		// 			let registeredIcon = '<div class="registered-icon">Registered</div>';
		// 			$('.registered').append(registeredIcon);
        //
		// 			if (window.innerWidth < 1025 && window.innerWidth > 767){
		// 				$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
		// 					$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
		// 						if (!$(this).hasClass('fc-limited')){
		// 							$(this).addClass('fc-more-cell');
		// 							let singleEvent = '<a href="javascript:;" class="fc-more single-more">1 event</a>';
		// 							$(this).append(singleEvent);
		// 						}
		// 					});
		// 				});
		// 			}
        //
		// 			if (window.innerWidth < 768){
		// 				$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
		// 					$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
		// 						if (!$(this).hasClass('fc-limited')){
		// 							$(this).addClass('fc-more-cell');
		// 							let singleEvent = '<a href="javascript:;" class="fc-more single-more">+1</a>';
		// 							$(this).append(singleEvent);
		// 						}
		// 					});
		// 				});
		// 			}
        //
		// 			$('.single-more').on('click', function(e){
		// 				e.preventDefault();
        //
		// 				let singleEventContent = $(this).prev().html();
		// 				let catName1 = $(this).prev().data('cat1');
		// 				let catName2 = $(this).prev().data('cat2');
		// 				let eventDate = $(this).prev().data('date');
        //
		// 				let modal_contents = '<div class="modal-content">' +
		// 					'<a href="#" class="close">Close</a>' +
		// 					'<div class="modal-event-date">'+eventDate+'</div>' +
		// 					'<div class="row center-xs">';
        //
		// 				modal_contents += '<div class="col-xs-12">' +
		// 					'<div class="modal-event modal-event-1">' +
		// 					'<div class="modal-event-category-container">' +
		// 						'<div class="modal-event-category first '+catName1+'">'+catName1+'</div>' +
		// 						'<div class="modal-event-category second '+catName2+'">'+catName2+'</div>' +
		// 					'</div>' +
		// 					'<a href="#" class="modal-event-title">'+singleEventContent+'</a>' +
		// 					'</div>' +
		// 				'</div>';
		//
		// 				modal_contents += '</div></div>';
        //
		// 				$(this).addClass('re-focus');
		// 				$('body').addClass('no-scroll');
		// 				$('#modal').addClass('open');
		// 				$('.calendar-modal-overlay').addClass('open');
		// 				$('#modal .modal-events').html(modal_contents);
		// 				$('body a, body input, body button').attr('tabindex', '-1');
		// 				$('#modal a').attr('tabindex', '0');
		// 				$('#modal .close').focus();
		// 			});
		// 		}
        //
		// 		$('.calendar-keys').remove();
		// 		var calendarKeys = '<div class="calendar-keys"><ul><li class="no-events">Not available</li><li class="searched-event">Searched event</li><li class="today-event">Today</li></ul><p>Tap each date to see full list of programs</p></div>';
		// 		$('.fc-toolbar').append(calendarKeys);
        //
		// 	}
		// }

	},
	finalize() {
	// JavaScript to be fired on the home page, after the init JS


	},
};
