export default {
	init() {

		var publicationsContainer = $('.publication-master__publication-wrapper');
		var publicationForm = $('.publication-master__search__form');
		var publicationSearchBtn = $('#publication-lading-search-btn');
		var publicationRefineBtn = $('#publication-lading-refine-btn');
		var publicationFiltersShow = $('.publication-master__filter-btn');
		var publicationSearchWrapper = $('.publication-master__search-wrapper');
		var publicationSearchInput = publicationForm.find("input[type='text'], input[type='date'], select");
		var publicationSearchDate = publicationForm.find("input.date");
		var publicationSearchTags = $('.publication-master__search-tags');
		var publicationIssueSearchWrapper = $('.publication-issue-landing__header-filters');

		var publications = publicationForm.data('publication');
		var ignoreIssue = publicationForm.data('ignore_issue');
		
		$('.publication-issue-landing__header-filters__form-element-button').on('click', function () {
			var issue = $('#issue_month').val();
			var publication = publicationIssueSearchWrapper.data('publication');
			window.location.href = '/issue/' + publication + '/' + issue;
		});

		publicationSearchDate.each(function () {
			$(this).datepicker({
				dateFormat: 'yy-mm-dd',
				dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
				beforeShow: function() {
					$('#ui-datepicker-div').addClass('fade');
				},
				onClose: function() {
					$('#ui-datepicker-div').removeClass('fade');
				}
			});
		});

		if (publicationsContainer) {
			this.loadPublicationsAjax(publicationsContainer, publications, ignoreIssue)

			publicationSearchBtn.click((e) => {
				e.preventDefault();
				var formData = publicationForm.serialize();
				this.loadPublicationsAjax(publicationsContainer, publications, null, formData);
			});

			publicationRefineBtn.click((e) => {
				e.preventDefault();
				publicationForm.find("input[type='text'], input[type='date'], select").val('');
				publicationForm.find('.selectric .label').text('Any');
				publicationSearchTags.find('.search-tag').remove();
				publicationSearchTags.find('.search-tag-reset').remove();
				this.loadPublicationsAjax(publicationsContainer, publications, ignoreIssue);
			});

			publicationFiltersShow.click((e) => {
				e.preventDefault();
				publicationFiltersShow.remove();
				publicationSearchWrapper.show();
			});

			publicationSearchInput.on('change', function () {
				var value = $(this).val();
				var $this = $(this);
				publicationSearchTags.find('.search-tag-reset').remove();

				if (value) {
					var attr = $this.attr('name');
					$('.search-tag[data-tag="' + attr + '"]').remove();

					var tagName = $this.is('select') ? $this.find('option:selected').text() : value;
					var newTag = $('<div>').text(tagName).addClass('search-tag');
					publicationSearchTags.append(newTag);

					publicationSearchTags.find('.search-tag').last().attr('data-tag', attr);
					publicationSearchTags.find('.search-tag').last().click(function () {
						$(this).remove();
						$this.val('');
						publicationForm.find("select").selectric('refresh');
						publicationSearchBtn.click();

						if (publicationSearchTags.find('.search-tag').length == 0) {
							publicationSearchTags.find('.search-tag-reset').remove();
							publicationRefineBtn.click();
						}
					});

					publicationSearchTags.append('<div class="search-tag-reset">Clear all</div>');

					publicationSearchTags.find('.search-tag-reset').click(function () {
						publicationRefineBtn.click();
					});
				}
			});
		}
	},
	loadPublicationsAjax(publicationsContainer, publications, ignoreIssue = '', formData = []) {

		$.ajax({
			url: PUBLICATIONS_AJAX.ajax_url,
			data: 'action=filter_publications&template=master&publications=' + publications + '&ignore_issue=' + ignoreIssue + '&' + formData,
			type: 'POST',
			beforeSend: function () {
				$('body').addClass('ajax-sort');
			},
			success: function (data) {
				publicationsContainer.html(data);
			},
		});
	}
};
