// import JqueryUIDatePicker from "jquery-ui/ui/widgets/datepicker.js";
// import moment from 'moment/src/moment.js';
// import { Calendar } from '@fullcalendar/core';
// import momentPlugin from '@fullcalendar/moment';
// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin from '@fullcalendar/interaction';
// import 'selectric';
//
// export default {
// 	init() {
// 		// scroll to
// 		$(document).on('click', '.scrollto', function (e) {
// 			e.preventDefault();
// 			if (window.innerWidth > 1024){
// 				if (!$(this).hasClass('disabled')){
// 					let section = $(this).attr('href');
// 					$('html, body').animate({
// 						scrollTop: $(section).offset().top
// 					}, 1000);
// 				}
// 			}
// 		});
//
// 		// render weekly view events
// 		function renderWeekView(){
//
// 			let todaysDate = new Date().getTime();
// 			todaysDate = moment(todaysDate).format('YYYY-MM-DD');
// 			let isToday = '';
// 			let dateRangeStart = $('#calendar thead > tr > .fc-day-header:first-child').data('date');
// 			let dateRangeEnd = $('#calendar thead > tr > .fc-day-header:last-child').data('date');
// 			let selectedEvent = $('#calendar thead > tr > .fc-day-header.fc-selected').data('date');
// 			let event_contents = '<div class="events-wrapper">';
//
// 			// Events
// 			for (var key in eventList) {
//
// 				var eventStart = moment(eventList[key].start).valueOf();
// 				eventStart = moment(eventStart).format('YYYY-MM-DD');
// 				var eventEnd = moment(eventList[key].end).valueOf();
// 				eventEnd = moment(eventEnd).format('YYYY-MM-DD');
//
// 				// check if it is in the week
// 				if (eventStart >= dateRangeStart && eventEnd <= dateRangeEnd){
//
// 					var eventDataDate = moment(eventList[key].start);
// 					var eventDataDateDisplay = moment(eventList[key].start);
// 					eventDataDate = eventDataDate.format('YYYY-MM-DD');
// 					eventDataDateDisplay = eventDataDateDisplay.format('MMMM DD, YYYY');
// 					var eventTitle = eventList[key].title;
// 					var eventThumb = eventList[key].thumb;
// 					var eventExcerpt = eventList[key].excerpt;
// 					var eventCategory1 = eventList[key].categoryName[0];
// 					var eventCategory2 = eventList[key].categoryName[1];
// 					var eventClasses = eventList[key].className;
// 					var eventUrl = eventList[key].url;
//
// 					// check if selected or not
// 					if (eventDataDate == selectedEvent) {
//
// 						if (eventDataDate == todaysDate){
// 							isToday = '<span class="separator"></span> <span>Today</span>';
// 							var todayClass = 'today';
// 						}
//
// 						event_contents += '<div id="date-'+eventDataDate+'" class="event selected ' + eventClasses[0] + ' ' + eventClasses[1] + ' ' + todayClass + '" data-date="' + eventDataDate + '">' +
// 							'<div class="searched-date">You searched this date</div>' +
// 							'<div class="row">' +
// 								'<div class="col-xs-5">' +
// 									'<div class="thumb">' +
// 										'<div class="event-category ' + eventClasses[0] + '">' + eventCategory1 + '</div>' +
// 										'<div class="event-category ' + eventClasses[1] + '">' + eventCategory2 + '</div>' +
// 										'<img src="../images/' + eventThumb + '" alt="' + eventTitle + '" />' +
// 									'</div>' +
// 								'</div>' +
// 								'<div class="col-xs-7">' +
// 									'<div class="content">' +
// 										'<div class="event-category mobile-cat ' + eventClasses[0] + '">' + eventCategory1 + '</div>' +
// 										'<div class="event-category mobile-cat ' + eventClasses[1] + '">' + eventCategory2 + '</div>' +
// 										'<p class="date"><span>' + eventDataDateDisplay + '</span> '+isToday+'</p>' +
// 										'<p class="title"><a href="' + eventUrl + '">' + eventTitle + '</a></p>' +
// 										'<p class="excerpt">' + eventExcerpt + '</p>' +
// 										'<a href="' + eventUrl + '" class="renew-btn blue"><span>Read More</span></a>' +
// 									'</div>' +
// 								'</div>' +
// 							'</div>' +
// 						'</div>';
//
// 					} else {
//
// 						if (eventDataDate == todaysDate){ isToday = '<span class="separator"></span> <span>Today</span>'; }
//
// 						event_contents += '<div id="date-'+eventDataDate+'" class="event ' + eventClasses[0] + ' ' + eventClasses[1] + ' ' + todayClass + '" data-date="' + eventDataDate + '">' +
// 							'<div class="row">' +
// 								'<div class="col-xs-5">' +
// 									'<div class="thumb">' +
// 										'<div class="event-category ' + eventClasses[0] + '">' + eventCategory1 + '</div>' +
// 										'<div class="event-category ' + eventClasses[1] + '">' + eventCategory2 + '</div>' +
// 										'<img src="../images/' + eventThumb + '" alt="' + eventTitle + '" />' +
// 									'</div>' +
// 								'</div>' +
// 								'<div class="col-xs-7">' +
// 									'<div class="content">' +
// 										'<div class="event-category mobile-cat ' + eventClasses[0] + '">' + eventCategory1 + '</div>' +
// 										'<div class="event-category mobile-cat ' + eventClasses[1] + '">' + eventCategory2 + '</div>' +
// 										'<p class="date"><span>' + eventDataDateDisplay + '</span> '+isToday+'</p>' +
// 										'<p class="title"><a href="' + eventUrl + '">' + eventTitle + '</a></p>' +
// 										'<p class="excerpt">' + eventExcerpt + '</p>' +
// 										'<a href="' + eventUrl + '" class="renew-btn blue"><span>Read More</span></a>' +
// 									'</div>' +
// 								'</div>' +
// 							'</div>' +
// 						'</div>';
//
// 					}
// 				}
// 			}
//
// 			event_contents += '</div>';
// 			$('.fc-scroller .fc-content-skeleton').html('');
// 			$('.fc-scroller .fc-content-skeleton').html(event_contents);
//
// 		}
//
// 		// no events
// 		if ($('.events-wrapper').is(':empty')){
// 			$('.events-wrapper').html('<div class="no-events">There are no events scheduled this week.</div>');
// 		}
//
//
//
//
// 		//////////////////////// DESKTOP FILTERING SETTINGS ////////////////////////
//
// 		// desktop calendar categories
// 		// $('#desktop-calendar-header ul li.filter-cat').children('a').on('click', function(e) {
// 		// 	e.preventDefault();
// 		// 	if ($(this).parent().hasClass('active')) {
// 		// 		$(this).parent().removeClass('active');
// 		// 		if ($('#desktop-calendar-header ul li.filter-cat.active').length < 1){
// 		// 			$('.category-everything').addClass('active');
// 		// 		} else {
// 		// 			$('.category-everything').removeClass('active');
// 		// 		}
// 		// 		if ($('.fc-dayGridMonth-view').length){
// 		// 			// calendar.destroy();
// 		// 			// calendar.render();
// 		// 			let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 		// 			$('.registered').append(registeredIcon);
// 		// 		}
// 		// 		if ($('.fc-timeGridWeek-view').length){
// 		// 			let selectedCategories = [];
// 		// 			$('#desktop-calendar-header ul li').not('.category-everything').each(function(){
// 		// 				if ($(this).hasClass('active')){
// 		// 					let filterItem = $(this).attr('id');
// 		// 					if (selectedCategories.indexOf(filterItem) === -1) {
// 		// 						selectedCategories.push(filterItem);
// 		// 						var setCategories = selectedCategories.join(', .');
// 		// 						$('.event').css('display', 'none');
// 		// 						$('.'+setCategories).css('display', 'block');
// 		// 					}
// 		// 				}
// 		// 			});
// 		// 		}
// 		// 	} else if ( $(this).not('.active') ) {
// 		// 		$(this).parent().addClass('active');
// 		// 		if ($('#desktop-calendar-header ul li.filter-cat.active').length < 1){
// 		// 			$('.category-everything').addClass('active');
// 		// 		} else {
// 		// 			$('.category-everything').removeClass('active');
// 		// 		}
// 		// 		if ($('.fc-dayGridMonth-view').length){
// 		// 			// calendar.destroy();
// 		// 			// calendar.render();
// 		// 			let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 		// 			$('.registered').append(registeredIcon);
// 		// 		}
// 		// 		if ($('.fc-timeGridWeek-view').length){
// 		// 			let selectedCategories = [];
// 		// 			$('#desktop-calendar-header ul li').not('.category-everything').each(function(){
// 		// 				if ($(this).hasClass('active')){
// 		// 					let filterItem = $(this).attr('id');
// 		// 					if (selectedCategories.indexOf(filterItem) === -1) {
// 		// 						selectedCategories.push(filterItem);
// 		// 						var setCategories = selectedCategories.join(', .');
// 		// 						$('.event').css('display', 'none');
// 		// 						$('.'+setCategories).css('display', 'block');
// 		// 					}
// 		// 				}
// 		// 			});
// 		// 		}
// 		// 	}
// 		// });
//         //
// 		// // turn everything back on
// 		// $('li.category-everything').children('a').on('click', function(e) {
// 		// 	e.preventDefault();
// 		// 	if (!$(this).parent().hasClass('active')) {
// 		// 		$(this).parent().addClass('active');
// 		// 		$('#desktop-calendar-header ul li.filter-cat').removeClass('active');
// 		// 		// calendar.destroy();
// 		// 		// calendar.render();
// 		// 	}
// 		// });
//
// 		// date picker for the date filter
// 		$( "#datepicker" ).datepicker();
//
// 		// submit filters
// 		$('#submit-btn').on('click', function (e) {
// 			e.preventDefault();
//
// 			var calendar_view_select = $('#prettydropdown-calendar-date-display li.selected').data('value');
//
// 			// month view
// 			if (calendar_view_select === 'disabled' || calendar_view_select === 'Monthly'){
//
// 				// calendar.destroy();
// 				// $('#calendar').html(' ');
// 				// calendar.render();
// 				// calendar.changeView('dayGridMonth');
//
// 				$('#calendar .fc-prev-button .prev-dates').remove();
// 				$('#calendar .fc-next-button .next-dates').remove();
//
// 				if ($('#datepicker').val()){
// 					let filteredDate = moment($('#datepicker').val());
// 					let selected_date = moment(filteredDate).format('YYYY-MM-DD');
// 					// calendar.gotoDate(selected_date);
// 					// calendar.select(selected_date);
// 					if (window.innerWidth > 1024){
// 						$('html, body').animate({
// 							scrollTop: $('.fc-day-top[data-date="'+selected_date+'"]').offset().top
// 						}, 1000);
// 					}
// 				} else {
// 					let selected_date = new Date().getTime();
// 					selected_date = moment(selected_date).format('YYYY-MM-DD');
// 					// calendar.gotoDate(selected_date);
// 					// calendar.select(selected_date);
// 					if (window.innerWidth > 1024){
// 						$('html, body').animate({
// 							scrollTop: $('.fc-day-top[data-date="'+selected_date+'"]').offset().top
// 						}, 1000);
// 					}
// 				}
// 				let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 				$('.registered').append(registeredIcon);
//
// 				if (window.innerWidth < 1025 && window.innerWidth > 767){
// 					$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 						$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 							if (!$(this).hasClass('fc-limited')){
// 								$(this).addClass('fc-more-cell');
// 								let singleEvent = '<a class="fc-more single-more">1 event</a>';
// 								$(this).append(singleEvent);
// 							}
// 						});
// 					});
// 				}
//
// 				if (window.innerWidth < 768){
// 					$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 						$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 							if (!$(this).hasClass('fc-limited')){
// 								$(this).addClass('fc-more-cell');
// 								let singleEvent = '<a class="fc-more single-more">+1</a>';
// 								$(this).append(singleEvent);
// 							}
// 						});
// 					});
// 				}
//
// 				$('.single-more').on('click', function(e){
// 					e.preventDefault();
//
// 					let singleEventContent = $(this).prev().html();
// 					let catName1 = $(this).prev().data('cat1');
// 					let catName2 = $(this).prev().data('cat2');
// 					let eventDate = $(this).prev().data('date');
//
// 					let modal_contents = '<div class="modal-content">' +
// 						'<div class="modal-event-date">'+eventDate+'</div>' +
// 						'<div class="row center-xs">';
//
// 					modal_contents += '<div class="col-xs-12">' +
// 						'<div class="modal-event modal-event-1">' +
// 						'<div class="modal-event-category-container">' +
// 							'<div class="modal-event-category first '+catName1+'">'+catName1+'</div>' +
// 							'<div class="modal-event-category second '+catName2+'">'+catName2+'</div>' +
// 						'</div>' +
// 						'<div class="modal-event-title">'+singleEventContent+'</div>' +
// 						'</div>' +
// 					'</div>';
//
// 					modal_contents += '</div></div>';
//
// 					//$('#modal').modal();
// 					$('#modal .modal-events').html(modal_contents);
// 				});
// 			}
//
// 			// week view
// 			if (calendar_view_select === 'Weekly') {
//
// 				// calendar.destroy();
// 				// $('#calendar').html(' ');
// 				// calendar.render();
// 				// calendar.changeView('timeGridWeek');
//
// 				// check date picker
// 				if ($('#datepicker').val() != ''){
// 					let selected_date = moment($('#datepicker').val());
// 					selected_date = moment(selected_date).format('YYYY-MM-DD');
// 					// calendar.gotoDate(selected_date);
// 					$('#calendar thead > tr > .fc-day-header').each(function(){
// 						if ($(this).data('date') === selected_date){
// 							$(this).addClass('fc-selected');
// 						}
// 						}
// 					});
// 				} else {
// 					let selected_date = new Date().getTime();
// 					selected_date = moment(selected_date).format('YYYY-MM-DD');
// 					// calendar.gotoDate(selected_date);
// 					$('#calendar thead > tr > .fc-day-header').each(function(){
// 						if ($(this).data('date') === selected_date){
// 							$(this).addClass('fc-selected');
// 						}
// 					});
// 				}
//
// 				// add tab anchors
// 				var setDate = [];
// 				$('#calendar thead > tr > .fc-day-header').each(function(i){
// 					setDate.push($(this).data('date'));
// 					let date = $(this).children('span').text();
// 					let num = date.match(/\d+/g);
// 					let day=  date.match(/[a-zA-Z]+/g);
// 					if ($(this).children('a').length < 1){
// 						$(this).contents().wrap('<a href="#date-'+setDate[i]+'" class="scrollto"><span class="goto">Go to date</span> <span class="num">'+num+'</span><span class="day">'+day+'</span></a>');
// 					}
// 				});
//
// 				// disable anchors with no events
// 				$('.fc-axis').remove();
// 				$('.fc-time-grid-container > .fc-time-grid > .fc-content-skeleton > table > tbody > tr > td').each(function(i){
// 					$(this).attr('id', 'date-'+setDate[i]);
// 					if ($(this).children('.fc-content-col').children('.fc-event-container').children('a').length < 1){
// 						let disableDate = $(this).attr('id');
// 						$('#calendar thead > tr > .fc-day-header').each(function(i){
// 							if ($(this).children('a').attr('href') === '#'+disableDate){
// 								$(this).children('a').addClass('disabled');
// 							}
// 						});
// 					}
// 				});
//
// 				// render events
// 				renderWeekView();
//
// 				// scroll to event
// 				setTimeout(function(){
// 					if (window.innerWidth > 1024){
// 						$('#calendar thead > tr > .fc-day-header').each(function(i){
// 							if ($(this).hasClass('fc-selected')){
// 								let gotoDate = $(this).data('date');
// 								$('html, body').animate({
// 									scrollTop: $('#date-'+gotoDate).offset().top
// 								}, 1000);
// 							}
// 						});
// 					}
// 				}, 400);
//
// 				// add prev - next dates
// 				// var view = calendar.view;
// 				// var current_start = view.activeStart;
// 				// var current_end = view.activeEnd;
// 				// var previous_start = moment(current_start).subtract(8,'d').format('MMMM DD');
// 				// var previous_end = moment(current_end).subtract(8,'d').format('MMMM DD');
// 				// var next_start = moment(current_start).add(7,'d').format('MMMM DD');
// 				// var next_end = moment(current_end).add(7,'d').format('MMMM DD');
// 				// var previous_dates = previous_start+' - '+previous_end;
// 				// var next_dates = next_start+' - '+next_end;
// 				$('.fc-prev-button').append('<span class="prev-dates" style="left:80px;">'+previous_dates+'</span>');
// 				$('.fc-next-button').append('<span class="next-dates" style="right:80px;">'+next_dates+'</span>');
//
// 				// registererd event icon
// 				let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 				$('.registered').append(registeredIcon);
//
// 				// no events
// 				if ($('.events-wrapper').is(':empty')){
// 					$('.events-wrapper').html('<div class="no-events">There are no events scheduled this week.</div>');
// 				}
// 			}
//
// 		});
//
// 		// prev / next buttons clicked
// 		if (window.innerWidth > 1024){
// 			$(document).on('click', '.fc-button', function(){
//
// 				if ($('.fc-timeGridWeek-view').length){
//
// 					// set anchors
// 					var setDate = [];
// 					$('#calendar thead > tr > .fc-day-header').each(function(i){
// 						setDate.push($(this).data('date'));
// 						let date = $(this).children('span').text();
// 						let num = date.match(/\d+/g);
// 						let day=  date.match(/[a-zA-Z]+/g);
// 						if ($(this).children('a').length < 1){
// 							$(this).contents().wrap('<a href="#date-'+setDate[i]+'" class="scrollto"><span class="goto">Go to date</span> <span class="num">'+num+'</span><span class="day">'+day+'</span></a>');
// 						}
// 					});
//
// 					// disable anchors with no events
// 					$('.fc-axis').remove();
// 					$('.fc-time-grid-container > .fc-time-grid > .fc-content-skeleton > table > tbody > tr > td').each(function(i){
// 						$(this).attr('id', 'date-'+setDate[i]);
// 						if ($(this).children('.fc-content-col').children('.fc-event-container').children('a').length < 1){
// 							let disableDate = $(this).attr('id');
// 							$('#calendar thead > tr > .fc-day-header').each(function(i){
// 								if ($(this).children('a').attr('href') === '#'+disableDate){
// 									$(this).children('a').addClass('disabled');
// 								}
// 							});
// 						}
// 					});
//
// 					// if selected date exsists scroll to it
// 					$('#calendar thead > tr > .fc-day-header').each(function(i){
// 						if ($(this).hasClass('fc-selected')){
// 							let gotoDate = $(this).data('date');
// 							if (window.innerWidth > 1024){
// 								$('html, body').animate({
// 									scrollTop: $('#date-'+gotoDate).offset().top
// 								}, 1000);
// 							}
// 						}
// 					});
//
// 					// check date picker for a date
// 					if ($('#datepicker').val() != ''){
// 						let selected_date = moment($('#datepicker').val());
// 						selected_date = moment(selected_date).format('YYYY-MM-DD');
// 						$('#calendar thead > tr > .fc-day-header').each(function(){
// 							if ($(this).data('date') === selected_date){
// 								$(this).addClass('fc-selected');
// 							}
// 						});
// 					}
//
// 					// render events
// 					renderWeekView();
//
// 					// scroll to selected event
// 					setTimeout(function(){
// 						if ($('#datepicker').val() != ''){
// 							let selected_date = moment($('#datepicker').val());
// 							selected_date = moment(selected_date).format('YYYY-MM-DD');
// 							$('#calendar thead > tr > .fc-day-header').each(function(){
// 								if ($(this).data('date') === selected_date){
// 									$(this).addClass('fc-selected');
// 									let gotoDate = $(this).data('date');
// 									if (window.innerWidth > 1024){
// 										$('html, body').animate({
// 											scrollTop: $('#date-'+gotoDate).offset().top
// 										}, 1000);
// 									}
// 								}
// 							});
// 						}
// 					}, 400);
//
// 					// add prev - next dates
// 					// var view = calendar.view;
// 					// var current_start = view.activeStart;
// 					// var current_end = view.activeEnd;
// 					// var previous_start = moment(current_start).subtract(8,'d').format('MMMM DD');
// 					// var previous_end = moment(current_end).subtract(8,'d').format('MMMM DD');
// 					// var next_start = moment(current_start).add(7,'d').format('MMMM DD');
// 					// var next_end = moment(current_end).add(7,'d').format('MMMM DD');
// 					// var previous_dates = previous_start+' - '+previous_end;
// 					// var next_dates = next_start+' - '+next_end;
// 					$('.fc-prev-button .prev-dates').remove();
// 					$('.fc-next-button .next-dates').remove();
// 					$('.fc-prev-button').append('<span class="prev-dates" style="left:80px;">'+previous_dates+'</span>');
// 					$('.fc-next-button').append('<span class="next-dates" style="right:80px;">'+next_dates+'</span>');
//
// 					// check category filters
// 					let selectedCategories = [];
// 					// $('#desktop-calendar-header ul li').not('.category-everything').each(function(){
// 					// 	if ($(this).hasClass('active')){
// 					// 		let filterItem = $(this).attr('id');
// 					// 		if (selectedCategories.indexOf(filterItem) === -1) {
// 					// 			selectedCategories.push(filterItem);
// 					// 			var setCategories = selectedCategories.join(', .');
// 					// 			$('.event').css('display', 'none');
// 					// 			$('.'+setCategories).css('display', 'block');
// 					// 		}
// 					// 	}
// 					// });
//
// 					// registererd event icon
// 					let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 					$('.registered').append(registeredIcon);
//
// 					// no events
// 					if ($('.events-wrapper').is(':empty')){
// 						$('.events-wrapper').html('<div class="no-events">There are no events scheduled this week.</div>');
// 					}
// 				}
//
// 				if ($('.fc-dayGridMonth-view').length){
// 					let selectedCategories = [];
// 					// $('#desktop-calendar-header ul li').not('.category-everything').each(function(){
// 					// 	if ($(this).hasClass('active')){
// 					// 		let filterItem = $(this).attr('id');
// 					// 		if (selectedCategories.indexOf(filterItem) === -1) {
// 					// 			selectedCategories.push(filterItem);
// 					// 			var setCategories = selectedCategories.join(', .');
// 					// 			$('.fc-event').css('display', 'none');
// 					// 			$('.'+setCategories).css('display', 'block');
// 					// 		}
// 					// 	}
// 					// });
//
// 					// registererd event icon
// 					let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 					$('.registered').append(registeredIcon);
//
// 					if (window.innerWidth < 1025 && window.innerWidth > 767){
// 						$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 							$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 								if (!$(this).hasClass('fc-limited')){
// 									$(this).addClass('fc-more-cell');
// 									let singleEvent = '<a class="fc-more single-more">1 event</a>';
// 									$(this).append(singleEvent);
// 								}
// 							});
// 						});
// 					}
//
// 					if (window.innerWidth < 768){
// 						$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 							$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 								if (!$(this).hasClass('fc-limited')){
// 									$(this).addClass('fc-more-cell');
// 									let singleEvent = '<a class="fc-more single-more">+1</a>';
// 									$(this).append(singleEvent);
// 								}
// 							});
// 						});
// 					}
//
// 					$('.single-more').on('click', function(e){
// 						e.preventDefault();
//
// 						let singleEventContent = $(this).prev().html();
// 						let catName1 = $(this).prev().data('cat1');
// 						let catName2 = $(this).prev().data('cat2');
// 						let eventDate = $(this).prev().data('date');
//
// 						let modal_contents = '<div class="modal-content">' +
// 							'<div class="modal-event-date">'+eventDate+'</div>' +
// 							'<div class="row center-xs">';
//
// 						modal_contents += '<div class="col-xs-12">' +
// 							'<div class="modal-event modal-event-1">' +
// 							'<div class="modal-event-category-container">' +
// 								'<div class="modal-event-category first '+catName1+'">'+catName1+'</div>' +
// 								'<div class="modal-event-category second '+catName2+'">'+catName2+'</div>' +
// 							'</div>' +
// 							'<div class="modal-event-title">'+singleEventContent+'</div>' +
// 							'</div>' +
// 						'</div>';
//
// 						modal_contents += '</div></div>';
//
// 						//$('#modal').modal();
// 						$('#modal .modal-events').html(modal_contents);
// 					});
//
// 				}
//
// 			});
// 		}
//
// 		// reset the form
// 		// var $dropdown = $('select').selectric();
// 		// $('#reset-btn').on('click', function (e) {
// 		// 	e.preventDefault();
// 		// 	$("#calendar-filters-form").trigger("reset");
// 		// 	$dropdown.refresh();
// 		// 	if (!$('.category-everything').hasClass('active')) {
// 		// 		$('.category-everything').addClass('active');
// 		// 		$('#desktop-calendar-header ul li').not('.category-everything').removeClass('active');
// 		// 		$('.fc-event').css('display', 'block');
// 		// 		$('.event').css('display', 'block');
// 		// 		selectedCategories = [];
// 		// 	}
// 		// });
//
//
//
//
// 		//////////////////////// TABLET FILTERING SETTINGS ////////////////////////
//
// 		// toggles the tablet settings menu to appear
// 		$('.tablet-advanced-settings-toggle').on('click', function() {
// 			$('#tablet-calendar-settings').addClass('active');
// 			$('.tablet-settings-overlay').addClass('active');
// 			$('.main').addClass('no-scroll');
// 			$('body').addClass('no-scroll');
// 		});
//
// 		$('.tablet-advanced-filters').on('click', function() {
// 			$('#tablet-calendar-settings').removeClass('active');
// 			$('.tablet-settings-overlay').removeClass('active');
// 			$('.main').removeClass('no-scroll');
// 			$('body').removeClass('no-scroll');
// 		});
//
// 		// date picker
// 		$( "#datepicker2" ).datepicker();
//
// 		// tablet toggles
// 		$('.dropdown-filter-toggle').on('click', function() {
// 			if ( $('.advanced-filter-dropdown-options').is(':visible') ) {
// 				$(this).parents('.advanced-filter.dropdown').attr('aria-expanded', 'false');
// 			} else {
// 				$(this).parents('.advanced-filter.dropdown').attr('aria-expanded', 'true');
// 			}
// 			$(this).parents('.advanced-filter.dropdown').find('.advanced-filter-dropdown-options').slideToggle();
// 			$(this).children('img').toggleClass('active');
// 		});
//
// 		// change views
// 		$('.onoffswitch input').on('click', function(){
// 			if ($(this).is(':checked')){
// 				// calendar.changeView('dayGridMonth');
// 				$('#calendar .fc-prev-button .prev-dates').remove();
// 				$('#calendar .fc-next-button .next-dates').remove();
// 				$('.calendar-keys ul li.no-events').show();
// 				$('.calendar-keys p').show();
// 				$('.calendar-keys ul li.searched-event').removeClass('fill');
//
// 				if (window.innerWidth < 1025 && window.innerWidth > 767){
// 					$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 						$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 							if (!$(this).hasClass('fc-limited')){
// 								$(this).addClass('fc-more-cell');
// 								let singleEvent = '<a class="fc-more single-more">1 event</a>';
// 								$(this).append(singleEvent);
// 							}
// 						});
// 					});
// 				}
//
// 				if (window.innerWidth < 768){
// 					$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 						$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 							if (!$(this).hasClass('fc-limited')){
// 								$(this).addClass('fc-more-cell');
// 								let singleEvent = '<a class="fc-more single-more">+1</a>';
// 								$(this).append(singleEvent);
// 							}
// 						});
// 					});
// 				}
//
// 				$('.single-more').on('click', function(e){
// 					e.preventDefault();
//
// 					let singleEventContent = $(this).prev().html();
// 					let catName1 = $(this).prev().data('cat1');
// 					let catName2 = $(this).prev().data('cat2');
// 					let eventDate = $(this).prev().data('date');
//
// 					let modal_contents = '<div class="modal-content">' +
// 						'<div class="modal-event-date">'+eventDate+'</div>' +
// 						'<div class="row center-xs">';
//
// 					modal_contents += '<div class="col-xs-12">' +
// 						'<div class="modal-event modal-event-1">' +
// 						'<div class="modal-event-category-container">' +
// 							'<div class="modal-event-category first '+catName1+'">'+catName1+'</div>' +
// 							'<div class="modal-event-category second '+catName2+'">'+catName2+'</div>' +
// 						'</div>' +
// 						'<div class="modal-event-title">'+singleEventContent+'</div>' +
// 						'</div>' +
// 					'</div>';
//
// 					modal_contents += '</div></div>';
//
// 					//$('#modal').modal();
// 					$('#modal .modal-events').html(modal_contents);
// 				});
// 			} else {
// 				// calendar.changeView('timeGridWeek');
// 				$('.calendar-keys ul li.no-events').hide();
// 				$('.calendar-keys p').hide();
// 				$('.calendar-keys ul li.searched-event').addClass('fill');
//
// 				// check date picker
// 				if ($('#datepicker2').val() != ''){
// 					let selected_date = moment($('#datepicker2').val());
// 					selected_date = moment(selected_date).format('YYYY-MM-DD');
// 					// calendar.gotoDate(selected_date);
// 					$('#calendar thead > tr > .fc-day-header').each(function(){
// 						if ($(this).data('date') === selected_date){
// 							$(this).addClass('fc-selected');
// 						}
// 					});
// 				} else {
// 					let selected_date = new Date().getTime();
// 					selected_date = moment(selected_date).format('YYYY-MM-DD');
// 					// calendar.gotoDate(selected_date);
// 					$('#calendar thead > tr > .fc-day-header').each(function(){
// 						if ($(this).data('date') === selected_date){
// 							$(this).addClass('fc-selected');
// 						}
// 					});
// 				}
//
// 				// add tab anchors
// 				var setDate = [];
// 				$('#calendar thead > tr > .fc-day-header').each(function(i){
// 					setDate.push($(this).data('date'));
// 					let date = $(this).children('span').text();
// 					let num = date.match(/\d+/g);
// 					let day=  date.match(/[a-zA-Z]+/g);
// 					if ($(this).children('a').length < 1){
// 						$(this).contents().wrap('<a href="#date-'+setDate[i]+'" class="scrollto"><span class="goto">Go to date</span> <span class="num">'+num+'</span><span class="day">'+day+'</span></a>');
// 					}
// 				});
//
// 				// disable anchors with no events
// 				$('.fc-axis').remove();
// 				$('.fc-time-grid-container > .fc-time-grid > .fc-content-skeleton > table > tbody > tr > td').each(function(i){
// 					$(this).attr('id', 'date-'+setDate[i]);
// 					if ($(this).children('.fc-content-col').children('.fc-event-container').children('a').length < 1){
// 						let disableDate = $(this).attr('id');
// 						$('#calendar thead > tr > .fc-day-header').each(function(i){
// 							if ($(this).children('a').attr('href') === '#'+disableDate){
// 								$(this).children('a').addClass('disabled');
// 							}
// 						});
// 					}
// 				});
//
// 				// render events
// 				renderWeekView();
//
// 				// scroll to event
// 				// setTimeout(function(){
// 				// 	$('#calendar thead > tr > .fc-day-header').each(function(i){
// 				// 		if ($(this).hasClass('fc-selected')){
// 				// 			let gotoDate = $(this).data('date');
// 				// 			$('html, body').animate({
// 				// 				scrollTop: $('#date-'+gotoDate).offset().top
// 				// 			}, 1000);
// 				// 		}
// 				// 	});
// 				// }, 400);
//
// 				// add prev - next dates
// 				// var view = calendar.view;
// 				// var current_start = view.activeStart;
// 				// var current_end = view.activeEnd;
// 				// var previous_start = moment(current_start).subtract(8,'d').format('MMM DD');
// 				// var previous_end = moment(current_end).subtract(8,'d').format('MMM DD');
// 				// var next_start = moment(current_start).add(7,'d').format('MMM DD');
// 				// var next_end = moment(current_end).add(7,'d').format('MMM DD');
// 				// var previous_dates = previous_start+' - '+previous_end;
// 				// var next_dates = next_start+' - '+next_end;
// 				$('.prev-dates').remove();
// 				$('.next-dates').remove();
// 				$('.fc-prev-button').append('<span class="prev-dates" style="left:0;">'+previous_dates+'</span>');
// 				$('.fc-next-button').append('<span class="next-dates" style="right:0;">'+next_dates+'</span>');
//
// 				// registererd event icon
// 				let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 				$('.registered').append(registeredIcon);
//
// 				// no events
// 				if ($('.events-wrapper').is(':empty')){
// 					$('.events-wrapper').html('<div class="no-events">There are no events scheduled this week.</div>');
// 				}
// 			}
// 		});
//
// 		// tablet category filters
// 		$('.control-group' ).find('.control-checkbox').children('input').on('click', function() {
// 			if ( $(this).is(':checked') ) {
// 				$(this).parent('.control-checkbox').addClass('active');
// 			} else if ( $(this).is(':not(:checked)') ) {
// 				$(this).parent('.control-checkbox').removeClass('active');
// 			}
// 		});
//
// 		// prev / next buttons clicked
// 		if (window.innerWidth < 1024){
// 			$(document).on('click', '.fc-button', function(){
//
// 				if ($('.fc-timeGridWeek-view').length){
//
// 					// set anchors
// 					var setDate = [];
// 					$('#calendar thead > tr > .fc-day-header').each(function(i){
// 						setDate.push($(this).data('date'));
// 						let date = $(this).children('span').text();
// 						let num = date.match(/\d+/g);
// 						let day=  date.match(/[a-zA-Z]+/g);
// 						if ($(this).children('a').length < 1){
// 							$(this).contents().wrap('<a href="#date-'+setDate[i]+'" class="scrollto"><span class="goto">Go to date</span> <span class="num">'+num+'</span><span class="day">'+day+'</span></a>');
// 						}
// 					});
//
// 					// disable anchors with no events
// 					$('.fc-axis').remove();
// 					$('.fc-time-grid-container > .fc-time-grid > .fc-content-skeleton > table > tbody > tr > td').each(function(i){
// 						$(this).attr('id', 'date-'+setDate[i]);
// 						if ($(this).children('.fc-content-col').children('.fc-event-container').children('a').length < 1){
// 							let disableDate = $(this).attr('id');
// 							$('#calendar thead > tr > .fc-day-header').each(function(i){
// 								if ($(this).children('a').attr('href') === '#'+disableDate){
// 									$(this).children('a').addClass('disabled');
// 								}
// 							});
// 						}
// 					});
//
// 					// if selected date exsists scroll to it
// 					$('#calendar thead > tr > .fc-day-header').each(function(i){
// 						if ($(this).hasClass('fc-selected')){
// 							let gotoDate = $(this).data('date');
// 							// $('html, body').animate({
// 							// 	scrollTop: $('#date-'+gotoDate).offset().top
// 							// }, 1000);
// 						}
// 					});
//
// 					// check date picker for a date
// 					if ($('#datepicker2').val() != ''){
// 						let selected_date = moment($('#datepicker2').val());
// 						selected_date = moment(selected_date).format('YYYY-MM-DD');
// 						$('#calendar thead > tr > .fc-day-header').each(function(){
// 							if ($(this).data('date') === selected_date){
// 								$(this).addClass('fc-selected');
// 							}
// 						});
// 					}
//
// 					// render events
// 					renderWeekView();
//
// 					let selectedCategories = [];
// 					$('.control-group' ).find('.control-checkbox').each(function(){
// 						if ($(this).hasClass('active')){
// 							let filterItem = $(this).data('category');
// 							if (selectedCategories.indexOf(filterItem) === -1) {
// 								selectedCategories.push(filterItem);
// 								var setCategories = selectedCategories.join(', .');
// 								$('.event').css('display', 'none');
// 								$('.'+setCategories).css('display', 'block');
// 							}
// 						}
// 					});
//
// 					// scroll to selected event
// 					// setTimeout(function(){
// 					// 	if ($('#datepicker').val() != ''){
// 					// 		let selected_date = moment($('#datepicker').val());
// 					// 		selected_date = moment(selected_date).format('YYYY-MM-DD');
// 					// 		$('#calendar thead > tr > .fc-day-header').each(function(){
// 					// 			if ($(this).data('date') === selected_date){
// 					// 				$(this).addClass('fc-selected');
// 					// 				let gotoDate = $(this).data('date');
// 					// 				$('html, body').animate({
// 					// 					scrollTop: $('#date-'+gotoDate).offset().top
// 					// 				}, 1000);
// 					// 			}
// 					// 		});
// 					// 	}
// 					// }, 400);
//
// 					// registererd event icon
// 					let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 					$('.registered').append(registeredIcon);
//
// 					// add prev - next dates
// 					if (window.innerWidth > 1024){
// 						// var view = calendar.view;
// 						// var current_start = view.activeStart;
// 						// var current_end = view.activeEnd;
// 						// var previous_start = moment(current_start).subtract(8,'d').format('MMMM DD');
// 						// var previous_end = moment(current_end).subtract(8,'d').format('MMMM DD');
// 						// var next_start = moment(current_start).add(7,'d').format('MMMM DD');
// 						// var next_end = moment(current_end).add(7,'d').format('MMMM DD');
// 						// var previous_dates = previous_start+' - '+previous_end;
// 						// var next_dates = next_start+' - '+next_end;
// 						$('.prev-dates').remove();
// 						$('.next-dates').remove();
// 						$('.fc-prev-button').append('<span class="prev-dates" style="left:80px;">'+previous_dates+'</span>');
// 						$('.fc-next-button').append('<span class="next-dates" style="right:80px;">'+next_dates+'</span>');
// 					}
// 					if (window.innerWidth < 1025){
// 						// var view = calendar.view;
// 						// var current_start = view.activeStart;
// 						// var current_end = view.activeEnd;
// 						// var previous_start = moment(current_start).subtract(8,'d').format('MMM DD');
// 						// var previous_end = moment(current_end).subtract(8,'d').format('MMM DD');
// 						// var next_start = moment(current_start).add(7,'d').format('MMM DD');
// 						// var next_end = moment(current_end).add(7,'d').format('MMM DD');
// 						// var previous_dates = previous_start+' - '+previous_end;
// 						// var next_dates = next_start+' - '+next_end;
// 						$('.prev-dates').remove();
// 						$('.next-dates').remove();
// 						$('.fc-prev-button').append('<span class="prev-dates" style="left:0;">'+previous_dates+'</span>');
// 						$('.fc-next-button').append('<span class="next-dates" style="right:0;">'+next_dates+'</span>');
// 					}
//
// 					// no events
// 					if ($('.events-wrapper').is(':empty')){
// 						$('.events-wrapper').html('<div class="no-events">There are no events scheduled this week.</div>');
// 					}
// 				}
//
// 				if ($('.fc-dayGridMonth-view').length){
// 					let selectedCategories = [];
// 					// $('#desktop-calendar-header ul li').not('.category-everything').each(function(){
// 					// 	if ($(this).hasClass('active')){
// 					// 		let filterItem = $(this).attr('id');
// 					// 		if (selectedCategories.indexOf(filterItem) === -1) {
// 					// 			selectedCategories.push(filterItem);
// 					// 			var setCategories = selectedCategories.join(', .');
// 					// 			$('.fc-event').css('display', 'none');
// 					// 			$('.'+setCategories).css('display', 'block');
// 					// 		}
// 					// 	}
// 					// });
//
// 					// registererd event icon
// 					let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 					$('.registered').append(registeredIcon);
//
// 					if ($(window).width() < 1025 && $(window).width() > 767){
// 						$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 							$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 								if (!$(this).hasClass('fc-limited')){
// 									$(this).addClass('fc-more-cell');
// 									let singleEvent = '<a class="fc-more single-more">1 event</a>';
// 									$(this).append(singleEvent);
// 								}
// 							});
// 						});
// 					}
//
// 					if ($(window).width() < 768){
// 						$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 							$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 								if (!$(this).hasClass('fc-limited')){
// 									$(this).addClass('fc-more-cell');
// 									let singleEvent = '<a class="fc-more single-more">+1</a>';
// 									$(this).append(singleEvent);
// 								}
// 							});
// 						});
// 					}
//
// 					$('.single-more').on('click', function(e){
// 						e.preventDefault();
//
// 						let singleEventContent = $(this).prev().html();
// 						let catName1 = $(this).prev().data('cat1');
// 						let catName2 = $(this).prev().data('cat2');
// 						let eventDate = $(this).prev().data('date');
//
// 						let modal_contents = '<div class="modal-content">' +
// 							'<div class="modal-event-date">'+eventDate+'</div>' +
// 							'<div class="row center-xs">';
//
// 						modal_contents += '<div class="col-xs-12">' +
// 							'<div class="modal-event modal-event-1">' +
// 							'<div class="modal-event-category-container">' +
// 								'<div class="modal-event-category first '+catName1+'">'+catName1+'</div>' +
// 								'<div class="modal-event-category second '+catName2+'">'+catName2+'</div>' +
// 							'</div>' +
// 							'<div class="modal-event-title">'+singleEventContent+'</div>' +
// 							'</div>' +
// 						'</div>';
//
// 						modal_contents += '</div></div>';
//
// 						//$('#modal').modal();
// 						$('#modal .modal-events').html(modal_contents);
// 					});
//
// 				}
//
// 			});
// 		}
//
// 		// tablet submit filters
// 		$('#advanced-submit-btn').on('click', function (e) {
// 			e.preventDefault();
//
// 			if ($('.onoffswitch-checkbox').is(':checked')){
// 				var calendar_view_select = 'Monthly';
// 			} else {
// 				var calendar_view_select = 'Weekly';
// 			}
//
// 			// month view
// 			if (calendar_view_select === 'disabled' || calendar_view_select === 'Monthly'){
//
// 				// calendar.changeView('dayGridMonth');
//
// 				$('#calendar .fc-prev-button .prev-dates').remove();
// 				$('#calendar .fc-next-button .next-dates').remove();
// 				$('.calendar-keys ul li.no-events').show();
// 				$('.calendar-keys p').show();
// 				$('.calendar-keys ul li.searched-event').removeClass('fill');
//
// 				// check datepicker for date
// 				if ($('#datepicker2').val()){
// 					let filteredDate = moment($('#datepicker2').val());
// 					let selected_date = moment(filteredDate).format('YYYY-MM-DD');
// 					// calendar.gotoDate(selected_date);
// 					// calendar.select(selected_date);
// 					// $('html, body').animate({
// 					// 	scrollTop: $('.fc-day-top[data-date="'+selected_date+'"]').offset().top
// 					// }, 1000);
// 				} else {
// 					let selected_date = new Date().getTime();
// 					selected_date = moment(selected_date).format('YYYY-MM-DD');
// 					// calendar.gotoDate(selected_date);
// 					// calendar.select(selected_date);
// 					// $('html, body').animate({
// 					// 	scrollTop: $('.fc-day-top[data-date="'+selected_date+'"]').offset().top
// 					// }, 1000);
// 				}
//
// 				// calendar.destroy();
// 				// calendar.render();
//
// 				var calendarKeys = '<div class="calendar-keys"><ul><li class="no-events">Not available</li><li class="searched-event">Searched event</li><li class="today-event">Today</li></ul><p>Tap each date to see full list of programs</p></div>';
// 				$('.fc-toolbar').append(calendarKeys);
// 				let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 				$('.registered').append(registeredIcon);
//
// 				if (window.innerWidth < 1025 && window.innerWidth > 767){
// 					$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 						$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 							if (!$(this).hasClass('fc-limited')){
// 								$(this).addClass('fc-more-cell');
// 								let singleEvent = '<a class="fc-more single-more">1 event</a>';
// 								$(this).append(singleEvent);
// 							}
// 						});
// 					});
// 				}
//
// 				if (window.innerWidth < 768){
// 					$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 						$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 							if (!$(this).hasClass('fc-limited')){
// 								$(this).addClass('fc-more-cell');
// 								let singleEvent = '<a class="fc-more single-more">+1</a>';
// 								$(this).append(singleEvent);
// 							}
// 						});
// 					});
// 				}
//
// 				$('.single-more').on('click', function(e){
// 					e.preventDefault();
//
// 					let singleEventContent = $(this).prev().html();
// 					let catName1 = $(this).prev().data('cat1');
// 					let catName2 = $(this).prev().data('cat2');
// 					let eventDate = $(this).prev().data('date');
//
// 					let modal_contents = '<div class="modal-content">' +
// 						'<div class="modal-event-date">'+eventDate+'</div>' +
// 						'<div class="row center-xs">';
//
// 					modal_contents += '<div class="col-xs-12">' +
// 						'<div class="modal-event modal-event-1">' +
// 						'<div class="modal-event-category-container">' +
// 							'<div class="modal-event-category first '+catName1+'">'+catName1+'</div>' +
// 							'<div class="modal-event-category second '+catName2+'">'+catName2+'</div>' +
// 						'</div>' +
// 						'<div class="modal-event-title">'+singleEventContent+'</div>' +
// 						'</div>' +
// 					'</div>';
//
// 					modal_contents += '</div></div>';
//
// 					//$('#modal').modal();
// 					$('#modal .modal-events').html(modal_contents);
// 				});
// 			}
//
// 			// week view
// 			if (calendar_view_select === 'Weekly') {
//
// 				// calendar.changeView('timeGridWeek');
//
// 				$('.calendar-keys ul li.no-events').hide();
// 				$('.calendar-keys p').hide();
// 				$('.calendar-keys ul li.searched-event').addClass('fill');
//
// 				// check date picker
// 				if ($('#datepicker2').val() != ''){
// 					let selected_date = moment($('#datepicker2').val());
// 					selected_date = moment(selected_date).format('YYYY-MM-DD');
// 					// calendar.gotoDate(selected_date);
// 					$('#calendar thead > tr > .fc-day-header').each(function(){
// 						if ($(this).data('date') === selected_date){
// 							$(this).addClass('fc-selected');
// 						}
// 					});
// 				} else {
// 					let selected_date = new Date().getTime();
// 					selected_date = moment(selected_date).format('YYYY-MM-DD');
// 					// calendar.gotoDate(selected_date);
// 					$('#calendar thead > tr > .fc-day-header').each(function(){
// 						if ($(this).data('date') === selected_date){
// 							$(this).addClass('fc-selected');
// 						}
// 					});
// 				}
//
// 				// add tab anchors
// 				var setDate = [];
// 				$('#calendar thead > tr > .fc-day-header').each(function(i){
// 					setDate.push($(this).data('date'));
// 					let date = $(this).children('span').text();
// 					let num = date.match(/\d+/g);
// 					let day=  date.match(/[a-zA-Z]+/g);
// 					if ($(this).children('a').length < 1){
// 						$(this).contents().wrap('<a href="#date-'+setDate[i]+'" class="scrollto"><span class="goto">Go to date</span> <span class="num">'+num+'</span><span class="day">'+day+'</span></a>');
// 					}
// 				});
//
// 				// disable anchors with no events
// 				$('.fc-axis').remove();
// 				$('.fc-time-grid-container > .fc-time-grid > .fc-content-skeleton > table > tbody > tr > td').each(function(i){
// 					$(this).attr('id', 'date-'+setDate[i]);
// 					if ($(this).children('.fc-content-col').children('.fc-event-container').children('a').length < 1){
// 						let disableDate = $(this).attr('id');
// 						$('#calendar thead > tr > .fc-day-header').each(function(i){
// 							if ($(this).children('a').attr('href') === '#'+disableDate){
// 								$(this).children('a').addClass('disabled');
// 							}
// 						});
// 					}
// 				});
//
// 				// render events
// 				renderWeekView();
//
// 				let selectedCategories = [];
// 				$('.control-group' ).find('.control-checkbox').each(function(){
// 					if ($(this).hasClass('active')){
// 						let filterItem = $(this).data('category');
// 						if (selectedCategories.indexOf(filterItem) === -1) {
// 							selectedCategories.push(filterItem);
// 							var setCategories = selectedCategories.join(', .');
// 							$('.event').css('display', 'none');
// 							$('.'+setCategories).css('display', 'block');
// 						}
// 					}
// 				});
//
// 				// scroll to event
// 				// setTimeout(function(){
// 				// 	if (hasScrolled != true){
// 				// 		$('#calendar thead > tr > .fc-day-header').each(function(i){
// 				// 			if ($(this).hasClass('fc-selected')){
// 				// 				let gotoDate = $(this).data('date');
// 				// 				$('html, body').animate({
// 				// 					scrollTop: $('#date-'+gotoDate).offset().top
// 				// 				}, 1000);
// 				// 			}
// 				// 		});
// 				// 		hasScrolled = true;
// 				// 	}
// 				// }, 400);
//
// 				// add prev - next dates
// 				if (window.innerWidth > 1024){
// 					// var view = calendar.view;
// 					// var current_start = view.activeStart;
// 					// var current_end = view.activeEnd;
// 					// var previous_start = moment(current_start).subtract(8,'d').format('MMMM DD');
// 					// var previous_end = moment(current_end).subtract(8,'d').format('MMMM DD');
// 					// var next_start = moment(current_start).add(7,'d').format('MMMM DD');
// 					// var next_end = moment(current_end).add(7,'d').format('MMMM DD');
// 					// var previous_dates = previous_start+' - '+previous_end;
// 					// var next_dates = next_start+' - '+next_end;
// 					$('.prev-dates').remove();
// 					$('.next-dates').remove();
// 					$('.fc-prev-button').append('<span class="prev-dates" style="left:80px;">'+previous_dates+'</span>');
// 					$('.fc-next-button').append('<span class="next-dates" style="right:80px;">'+next_dates+'</span>');
// 				}
// 				if (window.innerWidth < 1025){
// 					// var view = calendar.view;
// 					// var current_start = view.activeStart;
// 					// var current_end = view.activeEnd;
// 					// var previous_start = moment(current_start).subtract(8,'d').format('MMM DD');
// 					// var previous_end = moment(current_end).subtract(8,'d').format('MMM DD');
// 					// var next_start = moment(current_start).add(7,'d').format('MMM DD');
// 					// var next_end = moment(current_end).add(7,'d').format('MMM DD');
// 					// var previous_dates = previous_start+' - '+previous_end;
// 					// var next_dates = next_start+' - '+next_end;
// 					$('.prev-dates').remove();
// 					$('.next-dates').remove();
// 					$('.fc-prev-button').append('<span class="prev-dates" style="left:0;">'+previous_dates+'</span>');
// 					$('.fc-next-button').append('<span class="next-dates" style="right:0;">'+next_dates+'</span>');
// 				}
//
// 				// registererd event icon
// 				let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 				$('.registered').append(registeredIcon);
//
// 				// no events
// 				if ($('.events-wrapper').is(':empty')){
// 					$('.events-wrapper').html('<div class="no-events">There are no events scheduled this week.</div>');
// 				}
// 			}
//
// 			$('#tablet-calendar-settings').removeClass('active');
// 			$('.tablet-settings-overlay').removeClass('active');
// 		});
//
// 		// reset button
// 		// var $dropdown = $('#calendar-region').selectric();
// 		// $('#advanced-reset-btn').on('click', function (e) {
// 		// 	e.preventDefault();
// 		// 	$("#advanced-filter-form").trigger("reset");
// 		// 	$dropdown.refresh();
// 		// 	$('#tablet-calendar-settings .control').removeClass('active');
// 		// 	$('#tablet-calendar-settings .control input').prop('checked', false);
// 		// 	$('.fc-event').css('display', 'block');
// 		// 	$('.event').css('display', 'block');
// 		// });
//
//
//
//
// 		//////////////////////// EVENTS ////////////////////////
// 		let eventList = [
// 		{
// 			title  : '1 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 10, 28, 9),
// 			end    : new Date(2019, 10, 28, 13),
// 			className : ['category-cle-program', 'registered'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
// 			categoryName : ['CLE Program'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '2 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 10, 28, 9),
// 			end    : new Date(2019, 10, 28, 10),
// 			className : ['category-other', 'registered'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '3 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 10, 29, 9),
// 			end    : new Date(2019, 10, 29, 10),
// 			className : ['category-cle-program', 'registered'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
// 			categoryName : ['CLE Program'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '4 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 10, 29, 9),
// 			end    : new Date(2019, 10, 29, 10),
// 			className : ['category-other', 'registered'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '5 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 10, 30, 9),
// 			end    : new Date(2019, 10, 30, 10),
// 			className : ['category-cle-program'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
// 			categoryName : ['CLE Program'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '6 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 10, 30, 9),
// 			end    : new Date(2019, 10, 30, 10),
// 			className : ['category-section-seasonal'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
// 			categoryName : ['Section Seasonal'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '7 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 10, 30, 9),
// 			end    : new Date(2019, 10, 30, 10),
// 			className : ['category-informational-networking'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
// 			categoryName : ['Informational Networking'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '8 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 10, 31, 9),
// 			end    : new Date(2019, 10, 31, 10),
// 			className : ['category-administrative', 'category-informational-networking'], //add registered to the className array if it is a registered event (gives the event a small reigstered icon next to it)
// 			categoryName : ['Administrative', 'Informational Networking'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '9 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 11, 16, 9),
// 			end    : new Date(2019, 11, 16, 10),
// 			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '10 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 11, 16, 9),
// 			end    : new Date(2019, 11, 16, 10),
// 			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '11 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 11, 16, 9),
// 			end    : new Date(2019, 11, 16, 10),
// 			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '12 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 11, 16, 9),
// 			end    : new Date(2019, 11, 16, 10),
// 			className : ['category-cle-program'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
// 			categoryName : ['CLE Program'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '13 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 11, 20, 9),
// 			end    : new Date(2019, 11, 20, 10),
// 			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '14 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 11, 21, 9),
// 			end    : new Date(2019, 11, 21, 10),
// 			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '15 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 11, 22, 9),
// 			end    : new Date(2019, 11, 22, 10),
// 			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '16 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 11, 23, 9),
// 			end    : new Date(2019, 11, 23, 10),
// 			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '17 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 11, 23, 9),
// 			end    : new Date(2019, 11, 23, 12),
// 			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '18 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 11, 23, 9),
// 			end    : new Date(2019, 11, 23, 14),
// 			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '19 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 11, 23, 9),
// 			end    : new Date(2019, 11, 23, 15),
// 			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '20 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 11, 23, 9),
// 			end    : new Date(2019, 11, 23, 17),
// 			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '21 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 11, 24, 9),
// 			end    : new Date(2019, 11, 24, 10),
// 			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		{
// 			title  : '22 U.S. Supreme Court and New York Court of Appeals Round-Up 2019',
// 			thumb : 'event-thumb.jpg',
// 			excerpt : 'This program will discuss recent noteworthy cases from the 2018-2019 U.S. Supreme Court term and from the...',
// 			start  : new Date(2019, 11, 25, 9),
// 			end    : new Date(2019, 11, 25, 10),
// 			className : ['category-other'], // the class names are same as the name of the categories ie: 'cle-program' or 'informational-networking' or 'sectional-seasonal'
// 			categoryName : ['Other'] // the category names are what is used for the tooltips
// 		},
// 		];
//
// 		// initilizing calendar - https://fullcalendar.io/docs/v4
// 		// var calendarEl = document.getElementById('calendar');
//
// 		if (window.innerWidth > 1024){
// 			// var calendar = new Calendar(calendarEl, {
// 			// 	header: {
// 			// 		left: 'prev',
// 			// 		center: 'title',
// 			// 		right: 'next'
// 			// 	},
// 			// 	plugins: [ momentPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin ],
// 			// 	views: {
// 			// 		timeGridWeek: {
// 			// 			columnHeaderFormat: { day: 'numeric', weekday: 'long' },
// 			// 		},
// 			// 		dayGridMonth: {
// 			// 			eventLimit: 3,
// 			// 			columnHeaderFormat: { weekday: 'long' },
// 			// 		}
// 			// 	},
// 			// 	nextDayThreshold: '11:59:59',
// 			// 	editable: true,
// 			// 	events: eventList,
// 			// 	eventRender: function(info) {
// 			// 		let eventDate = info.event.start;
// 			// 		eventDate = moment(eventDate).format('YYYY-MM-DD');
// 			// 		$('.fc td.fc-day').each(function(i){
// 			// 			if ($(this).data('date') == eventDate){
// 			// 				$(this).addClass('has-events');
// 			// 			}
// 			// 		});
// 			// 		if (!$('.filter-all').hasClass('active')){
// 			// 			return filter(info.event);
// 			// 		}
// 			// 	},
// 			// 	eventLimitClick: function(info) {
// 			// 		let eventArray = info.segs;
// 			// 		let eventDate = eventArray[0].eventRange.range.start;
// 			// 		let eventDateEnd = eventArray[0].eventRange.range.end;
// 			// 		eventDate = moment(eventDate).format('MMMM DD, YYYY');
//             //
// 			// 		let modal_contents = '<div class="modal-content">' +
// 			// 			'<div class="modal-event-date">'+eventDate+'</div>' +
// 			// 			'<div class="row center-xs">';
//             //
// 			// 		for (var i = 0; i < eventArray.length; i++){
// 			// 			let cat1 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][0];
// 			// 			let cat2 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][1];
// 			// 			let title = eventArray[i]['eventRange']['def']['title'];
// 			// 			modal_contents += '<div class="col-xs-6">' +
// 			// 				'<div class="modal-event modal-event-'+i+'">' +
// 			// 				'<div class="modal-event-category-container">' +
// 			// 					'<div class="modal-event-category first '+cat1+'">'+cat1+'</div>' +
// 			// 					'<div class="modal-event-category second '+cat2+'">'+cat2+'</div>' +
// 			// 				'</div>' +
// 			// 				'<div class="modal-event-title">'+title+'</div>' +
// 			// 				'</div>' +
// 			// 			'</div>';
// 			// 		}
//             //
// 			// 		modal_contents += '</div></div>';
//             //
// 			// 		//$('#modal').modal();
// 			// 		$('#modal .modal-events').html(modal_contents);
// 			// 	},
// 			// 	eventMouseEnter: function (el) {
// 			// 		var tis = el.el;
// 			// 		let tooltipClass1 = 'class="' + el.event.classNames[0] + '"';
// 			// 		let tooltipClass2 = 'class="' + el.event.classNames[1] + '"';
// 			// 		let tooltipCategory1 = el.event.extendedProps.categoryName[0];
// 			// 		let tooltipCategory2 = el.event.extendedProps.categoryName[1];
//             //
// 			// 		let tooltip = '<div class="tooltip">'+
// 			// 		'<div ' + tooltipClass1 + '>' + tooltipCategory1 + '</div>' +
// 			// 		'<div ' + tooltipClass2 + '>' + tooltipCategory2 + '</div>' +
// 			// 		el.event.title +
// 			// 		'</div>';
//             //
//             //     let $tooltip = $(tooltip).appendTo('body');
//             //     $('.tooltip').find('.undefined').remove();
//             //     $('.tooltip').find('.registered').remove();
// 			// 		let offsetTop = $(tis).offset().top - ($('.tooltip').height() + 80);
// 			// 		let offsetLeft = $(tis).offset().left - ($('.tooltip').width() / 2);
// 			// 		$tooltip.css('top', offsetTop+'px');
// 			// 		$tooltip.css('left', offsetLeft+'px');
//             //
// 			// 		$(tis).mouseover(function(e) {
// 			// 			$(tis).css('z-index', 10000);
// 			// 			$tooltip.fadeIn('500');
// 			// 			$tooltip.fadeTo('10', 1.9);
// 			// 		}).mousemove(function(e) {
// 			// 			let offsetTop = e.pageY - ($('.tooltip').height() + 80);
// 			// 			let offsetLeft = e.pageX - ($('.tooltip').width() / 2);
// 			// 			$tooltip.css('top', offsetTop+'px');
// 			// 			$tooltip.css('left', offsetLeft+'px');
// 			// 		});
// 			// 	},
// 			// 	eventMouseLeave: function (el, view) {
// 			// 		$(el.el).css('z-index', 8);
// 			// 		$('.tooltip').remove();
// 			// 	},
// 			// 	dateClick: function () {
// 			// 		$('.tooltip').hide();
// 			// 	},
// 			// 	eventResizeStart: function () {
// 			// 		$('.tooltip').hide();
// 			// 	},
// 			// 	eventDragStart: function () {
// 			// 		$('.tooltip').hide();
// 			// 	},
// 			// 	viewDisplay: function () {
// 			// 		$('.tooltip').hide();
// 			// 	},
// 			// });
//             //
// 			// // Return Filters
// 			// function filter(event) {
// 			// 	var vals = [];
// 			// 	$('#desktop-calendar-header ul li.filter-cat').each(function() {
// 			// 		if ($(this).hasClass('active')){
// 			// 			vals.push($(this).data('cats'));
// 			// 		}
// 			// 	});
// 			// 	for (var i = 0; i < vals.length; i++){
// 			// 		if (vals.indexOf(event.classNames[i]) === -1) {
// 			// 			return false;
// 			// 		} else {
// 			// 			return true;
// 			// 		}
// 			// 	}
// 			// }
// 		}
//
// 		if (window.innerWidth < 1025 && window.innerWidth > 767){
// 			// var calendar = new Calendar(calendarEl, {
// 			// 	header: {
// 			// 		left: 'prev',
// 			// 		center: 'title',
// 			// 		right: 'next'
// 			// 	},
// 			// 	plugins: [ momentPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin ],
// 			// 	views: {
// 			// 		timeGridWeek: {
// 			// 			columnHeaderFormat: { day: 'numeric', weekday: 'short' },
// 			// 		},
// 			// 		dayGridMonth: {
// 			// 			eventLimit: 1,
// 			// 			eventLimitText: function (numEvents) {
// 			// 				return numEvents+ ' events';
// 			// 			},
// 			// 			columnHeaderFormat: { weekday: 'short' },
// 			// 		}
// 			// 	},
// 			// 	nextDayThreshold: '11:59:59',
// 			// 	editable: true,
// 			// 	events: eventList,
// 			// 	eventRender: function(info) {
// 			// 		let eventDate = info.event.start;
// 			// 		eventDate = moment(eventDate).format('YYYY-MM-DD');
// 			// 		$('.fc td.fc-day').each(function(i){
// 			// 			if ($(this).data('date') == eventDate){
// 			// 				$(this).addClass('has-events');
// 			// 			}
// 			// 		});
// 			// 		let modalDate = moment(info.event.start).format('MMMM DD, YYYY');
// 			// 		let modalCategory1 = info.event.extendedProps.categoryName[0];
// 			// 		let modalCategory2 = info.event.extendedProps.categoryName[1];
// 			// 		$(info.el).attr('data-date', modalDate);
// 			// 		$(info.el).attr('data-cat1', modalCategory1);
// 			// 		$(info.el).attr('data-cat2', modalCategory2);
// 			// 		return filter(info.event);
// 			// 	},
// 			// 	eventLimitClick: function(info) {
// 			// 		let eventArray = info.segs;
// 			// 		let eventDate = eventArray[0].eventRange.range.start;
// 			// 		let eventDateEnd = eventArray[0].eventRange.range.end;
// 			// 		eventDate = moment(eventDate).format('MMMM DD, YYYY');
//             //
// 			// 		let modal_contents = '<div class="modal-content">' +
// 			// 			'<div class="modal-event-date">'+eventDate+'</div>' +
// 			// 			'<div class="row center-xs">';
//             //
// 			// 		for (var i = 0; i < eventArray.length; i++){
// 			// 			let cat1 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][0];
// 			// 			let cat2 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][1];
// 			// 			let title = eventArray[i]['eventRange']['def']['title'];
// 			// 			modal_contents += '<div class="col-xs-6">' +
// 			// 				'<div class="modal-event modal-event-'+i+'">' +
// 			// 				'<div class="modal-event-category-container">' +
// 			// 					'<div class="modal-event-category first '+cat1+'">'+cat1+'</div>' +
// 			// 					'<div class="modal-event-category second '+cat2+'">'+cat2+'</div>' +
// 			// 				'</div>' +
// 			// 				'<div class="modal-event-title">'+title+'</div>' +
// 			// 				'</div>' +
// 			// 			'</div>';
// 			// 		}
//             //
// 			// 		modal_contents += '</div></div>';
//             //
// 			// 		//$('#modal').modal();
// 			// 		$('#modal .modal-events').html(modal_contents);
// 			// 	},
// 			// 	eventMouseEnter: function (el) {
// 			// 		var tis = el.el;
// 			// 		let tooltipClass1 = 'class="' + el.event.classNames[0] + '"';
// 			// 		let tooltipClass2 = 'class="' + el.event.classNames[1] + '"';
// 			// 		let tooltipCategory1 = el.event.extendedProps.categoryName[0];
// 			// 		let tooltipCategory2 = el.event.extendedProps.categoryName[1];
// 			//
// 			// 		let tooltip = '<div class="tooltip">'+
// 			// 		'<div ' + tooltipClass1 + '>' + tooltipCategory1 + '</div>' +
// 			// 		'<div ' + tooltipClass2 + '>' + tooltipCategory2 + '</div>' +
// 			// 		el.event.title +
// 			// 		'</div>';
//             //
// 			// 		let $tooltip = $(tooltip).appendTo('body');
// 			// 		$('.tooltip').find('.undefined').remove();
// 			// 		$('.tooltip').find('.registered').remove();
// 			// 		let offsetTop = $(tis).offset().top - ($('.tooltip').height() + 80);
// 			// 		let offsetLeft = $(tis).offset().left - ($('.tooltip').width() / 2);
// 			// 		$tooltip.css('top', offsetTop+'px');
// 			// 		$tooltip.css('left', offsetLeft+'px');
//             //
// 			// 		$(tis).mouseover(function(e) {
// 			// 			$(tis).css('z-index', 10000);
// 			// 			$tooltip.fadeIn('500');
// 			// 			$tooltip.fadeTo('10', 1.9);
// 			// 		}).mousemove(function(e) {
// 			// 			let offsetTop = e.pageY - ($('.tooltip').height() + 80);
// 			// 			let offsetLeft = e.pageX - ($('.tooltip').width() / 2);
// 			// 			$tooltip.css('top', offsetTop+'px');
// 			// 			$tooltip.css('left', offsetLeft+'px');
// 			// 		});
// 			// 	},
// 			// 	eventMouseLeave: function (el, view) {
// 			// 		$(el.el).css('z-index', 8);
// 			// 		$('.tooltip').remove();
// 			// 	},
// 			// 	dateClick: function () {
// 			// 		$('.tooltip').hide();
// 			// 	},
// 			// 	eventResizeStart: function () {
// 			// 		$('.tooltip').hide();
// 			// 	},
// 			// 	eventDragStart: function () {
// 			// 		$('.tooltip').hide();
// 			// 	},
// 			// 	viewDisplay: function () {
// 			// 		$('.tooltip').hide();
// 			// 	},
// 			// });
//
// 			// Return Filters
// 			function filter(event) {
// 				var vals = [];
// 				$('#tablet-calendar-settings .control').each(function() {
// 					if ($(this).hasClass('active')){
// 						vals.push($(this).data('category'));
// 					}
// 				});
// 				for (var i = 0; i < vals.length; i++){
// 					if (vals.indexOf(event.classNames[i]) === -1) {
// 						return false;
// 					} else {
// 						return true;
// 					}
// 				}
// 			}
//
//             // function filter(event) {
//             // 	var vals = [];
//             // 	$('#desktop-calendar-header ul li.filter-cat').each(function() {
//             // 		if ($(this).hasClass('active')){
//             // 			vals.push($(this).data('cats'));
//             // 		}
//             // 	});
//             // 	for (var i = 0; i < vals.length; i++){
//             // 		if (vals.indexOf(event.classNames[i]) === -1) {
//             // 			return false;
//             // 		} else {
//             // 			return true;
//             // 		}
//             // 	}
//             // }
// 		}
//
// 		if (window.innerWidth < 768){
// 			// var calendar = new Calendar(calendarEl, {
// 			// 	header: {
// 			// 		left: 'prev',
// 			// 		center: 'title',
// 			// 		right: 'next'
// 			// 	},
// 			// 	titleFormat: { month: 'short', day: 'numeric' },
// 			// 	plugins: [ momentPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin ],
// 			// 	views: {
// 			// 		timeGridWeek: {
// 			// 			columnHeaderFormat: { day: 'numeric', weekday: 'short' },
// 			// 		},
// 			// 		dayGridMonth: {
// 			// 			eventLimit: 1,
// 			// 			eventLimitText: function (numEvents) {
// 			// 				return '+'+numEvents;
// 			// 			},
// 			// 			columnHeaderFormat: { weekday: 'short' },
// 			// 		}
// 			// 	},
// 			// 	nextDayThreshold: '11:59:59',
// 			// 	editable: true,
// 			// 	events: eventList,
// 			// 	eventRender: function(info) {
// 			// 		let eventDate = info.event.start;
// 			// 		eventDate = moment(eventDate).format('YYYY-MM-DD');
// 			// 		$('.fc td.fc-day').each(function(i){
// 			// 			if ($(this).data('date') == eventDate){
// 			// 				$(this).addClass('has-events');
// 			// 			}
// 			// 		});
// 			// 		let modalDate = moment(info.event.start).format('MMMM DD, YYYY');
// 			// 		let modalCategory1 = info.event.extendedProps.categoryName[0];
// 			// 		let modalCategory2 = info.event.extendedProps.categoryName[1];
// 			// 		$(info.el).attr('data-date', modalDate);
// 			// 		$(info.el).attr('data-cat1', modalCategory1);
// 			// 		$(info.el).attr('data-cat2', modalCategory2);
// 			// 		return filter(info.event);
// 			// 	},
// 			// 	eventLimitClick: function(info) {
// 			// 		let eventArray = info.segs;
// 			// 		let eventDate = eventArray[0].eventRange.range.start;
// 			// 		let eventDateEnd = eventArray[0].eventRange.range.end;
// 			// 		eventDate = moment(eventDate).format('MMMM DD, YYYY');
//             //
// 			// 		let modal_contents = '<div class="modal-content">' +
// 			// 			'<div class="modal-event-date">'+eventDate+'</div>' +
// 			// 			'<div class="row center-xs">';
//             //
// 			// 		for (var i = 0; i < eventArray.length; i++){
// 			// 			let cat1 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][0];
// 			// 			let cat2 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][1];
// 			// 			let title = eventArray[i]['eventRange']['def']['title'];
// 			// 			modal_contents += '<div class="col-xs-6">' +
// 			// 				'<div class="modal-event modal-event-'+i+'">' +
// 			// 				'<div class="modal-event-category-container">' +
// 			// 					'<div class="modal-event-category first '+cat1+'">'+cat1+'</div>' +
// 			// 					'<div class="modal-event-category second '+cat2+'">'+cat2+'</div>' +
// 			// 				'</div>' +
// 			// 				'<div class="modal-event-title">'+title+'</div>' +
// 			// 				'</div>' +
// 			// 			'</div>';
// 			// 		}
//             //
// 			// 		modal_contents += '</div></div>';
//             //
// 			// 		//$('#modal').modal();
// 			// 		$('#modal .modal-events').html(modal_contents);
// 			// 	},
// 			// 	eventMouseEnter: function (el) {
// 			// 		var tis = el.el;
// 			// 		let tooltipClass1 = 'class="' + el.event.classNames[0] + '"';
// 			// 		let tooltipClass2 = 'class="' + el.event.classNames[1] + '"';
// 			// 		let tooltipCategory1 = el.event.extendedProps.categoryName[0];
// 			// 		let tooltipCategory2 = el.event.extendedProps.categoryName[1];
// 			//
// 			// 		let tooltip = '<div class="tooltip">'+
// 			// 		'<div ' + tooltipClass1 + '>' + tooltipCategory1 + '</div>' +
// 			// 		'<div ' + tooltipClass2 + '>' + tooltipCategory2 + '</div>' +
// 			// 		el.event.title +
// 			// 		'</div>';
//             //
// 			// 		let $tooltip = $(tooltip).appendTo('body');
// 			// 		$('.tooltip').find('.undefined').remove();
// 			// 		$('.tooltip').find('.registered').remove();
// 			// 		let offsetTop = $(tis).offset().top - ($('.tooltip').height() + 80);
// 			// 		let offsetLeft = $(tis).offset().left - ($('.tooltip').width() / 2);
// 			// 		$tooltip.css('top', offsetTop+'px');
// 			// 		$tooltip.css('left', offsetLeft+'px');
//             //
// 			// 		$(tis).mouseover(function(e) {
// 			// 			$(tis).css('z-index', 10000);
// 			// 			$tooltip.fadeIn('500');
// 			// 			$tooltip.fadeTo('10', 1.9);
// 			// 		}).mousemove(function(e) {
// 			// 			let offsetTop = e.pageY - ($('.tooltip').height() + 80);
// 			// 			let offsetLeft = e.pageX - ($('.tooltip').width() / 2);
// 			// 			$tooltip.css('top', offsetTop+'px');
// 			// 			$tooltip.css('left', offsetLeft+'px');
// 			// 		});
// 			// 	},
// 			// 	eventMouseLeave: function (el, view) {
// 			// 		$(el.el).css('z-index', 8);
// 			// 		$('.tooltip').remove();
// 			// 	},
// 			// 	dateClick: function () {
// 			// 		$('.tooltip').hide();
// 			// 	},
// 			// 	eventResizeStart: function () {
// 			// 		$('.tooltip').hide();
// 			// 	},
// 			// 	eventDragStart: function () {
// 			// 		$('.tooltip').hide();
// 			// 	},
// 			// 	viewDisplay: function () {
// 			// 		$('.tooltip').hide();
// 			// 	},
// 			// });
//
// 			// Return Filters
// 			function filter(event) {
// 				var vals = [];
// 				$('#tablet-calendar-settings .control').each(function() {
// 					if ($(this).hasClass('active')){
// 						vals.push($(this).data('category'));
// 					}
// 				});
// 				for (var i = 0; i < vals.length; i++){
// 					if (vals.indexOf(event.classNames[i]) === -1) {
// 						return false;
// 					} else {
// 						return true;
// 					}
// 				}
// 			}
// 		}
//
// 		// render calendar
// 		// calendar.render();
// 		// calendar.changeView('dayGridMonth');
//
// 		$('.calendar-keys').remove();
// 		var calendarKeys = '<div class="calendar-keys"><ul><li class="no-events">Not available</li><li class="searched-event">Searched event</li><li class="today-event">Today</li></ul><p>Tap each date to see full list of programs</p></div>';
// 		$('.fc-toolbar').append(calendarKeys);
//
// 		// registererd event icon
// 		let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 		$('.registered').append(registeredIcon);
//
// 		// single events
// 		if (window.innerWidth < 1025 && window.innerWidth > 767){
// 			$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 				$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 					if (!$(this).hasClass('fc-limited')){
// 						$(this).addClass('fc-more-cell');
// 						let singleEvent = '<a class="fc-more single-more">1 event</a>';
// 						$(this).append(singleEvent);
// 					}
// 				});
// 			});
// 		}
//
// 		if (window.innerWidth < 768){
// 			$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 				$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 					if (!$(this).hasClass('fc-limited')){
// 						$(this).addClass('fc-more-cell');
// 						let singleEvent = '<a class="fc-more single-more">+1</a>';
// 						$(this).append(singleEvent);
// 					}
// 				});
// 			});
// 		}
//
// 		// single event modal
// 		$('.single-more').on('click', function(e){
// 			e.preventDefault();
//
// 			let singleEventContent = $(this).prev().html();
// 			let catName1 = $(this).prev().data('cat1');
// 			let catName2 = $(this).prev().data('cat2');
// 			let eventDate = $(this).prev().data('date');
//
// 			let modal_contents = '<div class="modal-content">' +
// 				'<div class="modal-event-date">'+eventDate+'</div>' +
// 				'<div class="row center-xs">';
//
// 			modal_contents += '<div class="col-xs-12">' +
// 				'<div class="modal-event modal-event-1">' +
// 				'<div class="modal-event-category-container">' +
// 					'<div class="modal-event-category first '+catName1+'">'+catName1+'</div>' +
// 					'<div class="modal-event-category second '+catName2+'">'+catName2+'</div>' +
// 				'</div>' +
// 				'<div class="modal-event-title">'+singleEventContent+'</div>' +
// 				'</div>' +
// 			'</div>';
//
// 			modal_contents += '</div></div>';
//
// 			//$('#modal').modal();
// 			$('#modal .modal-events').html(modal_contents);
// 		});
//
// 		// resize event
// 		var rtime;
// 		var timeout = false;
// 		var delta = 200;
// 		var hasScrolled = false;
// 		$(window).on('resize', function(){
// 			rtime = new Date();
// 			if (timeout === false) {
// 				timeout = true;
// 				setTimeout(resizeend, delta);
// 			}
// 		});
//
// 		function resizeend() {
// 			if (new Date() - rtime < delta) {
// 				setTimeout(resizeend, delta);
// 			} else {
// 				timeout = false;
//
// 				// if (window.innerWidth > 1024){
// 				// 	var calendar = new Calendar(calendarEl, {
// 				// 		header: {
// 				// 			left: 'prev',
// 				// 			center: 'title',
// 				// 			right: 'next'
// 				// 		},
// 				// 		plugins: [ momentPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin ],
// 				// 		views: {
// 				// 			timeGridWeek: {
// 				// 				columnHeaderFormat: { day: 'numeric', weekday: 'long' },
// 				// 			},
// 				// 			dayGridMonth: {
// 				// 				eventLimit: 3,
// 				// 				columnHeaderFormat: { weekday: 'long' },
// 				// 			}
// 				// 		},
// 				// 		nextDayThreshold: '11:59:59',
// 				// 		editable: true,
// 				// 		events: eventList,
// 				// 		eventRender: function(info) {
// 				// 			let eventDate = info.event.start;
// 				// 			eventDate = moment(eventDate).format('YYYY-MM-DD');
// 				// 			$('.fc td.fc-day').each(function(i){
// 				// 				if ($(this).data('date') == eventDate){
// 				// 					$(this).addClass('has-events');
// 				// 				}
// 				// 			});
// 				// 			if (!$('.filter-all').hasClass('active')){
// 				// 				return filter(info.event);
// 				// 			}
// 				// 		},
// 				// 		eventLimitClick: function(info) {
// 				// 			let eventArray = info.segs;
// 				// 			let eventDate = eventArray[0].eventRange.range.start;
// 				// 			let eventDateEnd = eventArray[0].eventRange.range.end;
// 				// 			eventDate = moment(eventDate).format('MMMM DD, YYYY');
//                 //
// 				// 			let modal_contents = '<div class="modal-content">' +
// 				// 				'<div class="modal-event-date">'+eventDate+'</div>' +
// 				// 				'<div class="row center-xs">';
//                 //
// 				// 			for (var i = 0; i < eventArray.length; i++){
// 				// 				let cat1 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][0];
// 				// 				let cat2 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][1];
// 				// 				let title = eventArray[i]['eventRange']['def']['title'];
// 				// 				modal_contents += '<div class="col-xs-6">' +
// 				// 					'<div class="modal-event modal-event-'+i+'">' +
// 				// 					'<div class="modal-event-category-container">' +
// 				// 						'<div class="modal-event-category first '+cat1+'">'+cat1+'</div>' +
// 				// 						'<div class="modal-event-category second '+cat2+'">'+cat2+'</div>' +
// 				// 					'</div>' +
// 				// 					'<div class="modal-event-title">'+title+'</div>' +
// 				// 					'</div>' +
// 				// 				'</div>';
// 				// 			}
//                 //
// 				// 			modal_contents += '</div></div>';
//                 //
// 				// 			//$('#modal').modal();
// 				// 			$('#modal .modal-events').html(modal_contents);
// 				// 		},
// 				// 		eventMouseEnter: function (el) {
// 				// 			var tis = el.el;
// 				// 			let tooltipClass1 = 'class="' + el.event.classNames[0] + '"';
// 				// 			let tooltipClass2 = 'class="' + el.event.classNames[1] + '"';
// 				// 			let tooltipCategory1 = el.event.extendedProps.categoryName[0];
// 				// 			let tooltipCategory2 = el.event.extendedProps.categoryName[1];
// 				//
// 				// 			let tooltip = '<div class="tooltip">'+
// 				// 			'<div ' + tooltipClass1 + '>' + tooltipCategory1 + '</div>' +
// 				// 			'<div ' + tooltipClass2 + '>' + tooltipCategory2 + '</div>' +
// 				// 			el.event.title +
// 				// 			'</div>';
//                 //
// 				// 			let $tooltip = $(tooltip).appendTo('body');
// 				// 			$('.tooltip').find('.undefined').remove();
// 				// 			$('.tooltip').find('.registered').remove();
// 				// 			let offsetTop = $(tis).offset().top - ($('.tooltip').height() + 80);
// 				// 			let offsetLeft = $(tis).offset().left - ($('.tooltip').width() / 2);
// 				// 			$tooltip.css('top', offsetTop+'px');
// 				// 			$tooltip.css('left', offsetLeft+'px');
//                 //
// 				// 			$(tis).mouseover(function(e) {
// 				// 				$(tis).css('z-index', 10000);
// 				// 				$tooltip.fadeIn('500');
// 				// 				$tooltip.fadeTo('10', 1.9);
// 				// 			}).mousemove(function(e) {
// 				// 				let offsetTop = e.pageY - ($('.tooltip').height() + 80);
// 				// 				let offsetLeft = e.pageX - ($('.tooltip').width() / 2);
// 				// 				$tooltip.css('top', offsetTop+'px');
// 				// 				$tooltip.css('left', offsetLeft+'px');
// 				// 			});
// 				// 		},
// 				// 		eventMouseLeave: function (el, view) {
// 				// 			$(el.el).css('z-index', 8);
// 				// 			$('.tooltip').remove();
// 				// 		},
// 				// 		dateClick: function () {
// 				// 			$('.tooltip').hide();
// 				// 		},
// 				// 		eventResizeStart: function () {
// 				// 			$('.tooltip').hide();
// 				// 		},
// 				// 		eventDragStart: function () {
// 				// 			$('.tooltip').hide();
// 				// 		},
// 				// 		viewDisplay: function () {
// 				// 			$('.tooltip').hide();
// 				// 		},
// 				// 	});
//                 //
// 				// 	// Return Filters
// 				// 	function filter(event) {
// 				// 		var vals = [];
// 				// 		$('#desktop-calendar-header ul li.filter-cat').each(function() {
// 				// 			if ($(this).hasClass('active')){
// 				// 				vals.push($(this).data('cats'));
// 				// 			}
// 				// 		});
// 				// 		for (var i = 0; i < vals.length; i++){
// 				// 			if (vals.indexOf(event.classNames[i]) === -1) {
// 				// 				return false;
// 				// 			} else {
// 				// 				return true;
// 				// 			}
// 				// 		}
// 				// 	}
// 				// }
// 				// if (window.innerWidth < 1025 && window.innerWidth > 767){
// 				// 	var calendar = new Calendar(calendarEl, {
// 				// 		header: {
// 				// 			left: 'prev',
// 				// 			center: 'title',
// 				// 			right: 'next'
// 				// 		},
// 				// 		plugins: [ momentPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin ],
// 				// 		views: {
// 				// 			timeGridWeek: {
// 				// 				columnHeaderFormat: { day: 'numeric', weekday: 'short' },
// 				// 			},
// 				// 			dayGridMonth: {
// 				// 				eventLimit: 1,
// 				// 				eventLimitText: function (numEvents) {
// 				// 					return numEvents+ ' events';
// 				// 				},
// 				// 				columnHeaderFormat: { weekday: 'short' },
// 				// 			}
// 				// 		},
// 				// 		nextDayThreshold: '11:59:59',
// 				// 		editable: true,
// 				// 		events: eventList,
// 				// 		eventRender: function(info) {
// 				// 			let eventDate = info.event.start;
// 				// 			eventDate = moment(eventDate).format('YYYY-MM-DD');
// 				// 			$('.fc td.fc-day').each(function(i){
// 				// 				if ($(this).data('date') == eventDate){
// 				// 					$(this).addClass('has-events');
// 				// 				}
// 				// 			});
// 				// 			let modalDate = moment(info.event.start).format('MMMM DD, YYYY');
// 				// 			let modalCategory1 = info.event.extendedProps.categoryName[0];
// 				// 			let modalCategory2 = info.event.extendedProps.categoryName[1];
// 				// 			$(info.el).attr('data-date', modalDate);
// 				// 			$(info.el).attr('data-cat1', modalCategory1);
// 				// 			$(info.el).attr('data-cat2', modalCategory2);
// 				// 			return filter(info.event);
// 				// 		},
// 				// 		eventLimitClick: function(info) {
// 				// 			let eventArray = info.segs;
// 				// 			let eventDate = eventArray[0].eventRange.range.start;
// 				// 			let eventDateEnd = eventArray[0].eventRange.range.end;
// 				// 			eventDate = moment(eventDate).format('MMMM DD, YYYY');
//                 //
// 				// 			let modal_contents = '<div class="modal-content">' +
// 				// 				'<div class="modal-event-date">'+eventDate+'</div>' +
// 				// 				'<div class="row center-xs">';
//                 //
// 				// 			for (var i = 0; i < eventArray.length; i++){
// 				// 				let cat1 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][0];
// 				// 				let cat2 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][1];
// 				// 				let title = eventArray[i]['eventRange']['def']['title'];
// 				// 				modal_contents += '<div class="col-xs-6">' +
// 				// 					'<div class="modal-event modal-event-'+i+'">' +
// 				// 					'<div class="modal-event-category-container">' +
// 				// 						'<div class="modal-event-category first '+cat1+'">'+cat1+'</div>' +
// 				// 						'<div class="modal-event-category second '+cat2+'">'+cat2+'</div>' +
// 				// 					'</div>' +
// 				// 					'<div class="modal-event-title">'+title+'</div>' +
// 				// 					'</div>' +
// 				// 				'</div>';
// 				// 			}
//                 //
// 				// 			modal_contents += '</div></div>';
//                 //
// 				// 			//$('#modal').modal();
// 				// 			$('#modal .modal-events').html(modal_contents);
// 				// 		},
// 				// 		eventMouseEnter: function (el) {
// 				// 			var tis = el.el;
// 				// 			let tooltipClass1 = 'class="' + el.event.classNames[0] + '"';
// 				// 			let tooltipClass2 = 'class="' + el.event.classNames[1] + '"';
// 				// 			let tooltipCategory1 = el.event.extendedProps.categoryName[0];
// 				// 			let tooltipCategory2 = el.event.extendedProps.categoryName[1];
// 				//
// 				// 			let tooltip = '<div class="tooltip">'+
// 				// 			'<div ' + tooltipClass1 + '>' + tooltipCategory1 + '</div>' +
// 				// 			'<div ' + tooltipClass2 + '>' + tooltipCategory2 + '</div>' +
// 				// 			el.event.title +
// 				// 			'</div>';
//                 //
// 				// 			let $tooltip = $(tooltip).appendTo('body');
// 				// 			$('.tooltip').find('.undefined').remove();
// 				// 			$('.tooltip').find('.registered').remove();
// 				// 			let offsetTop = $(tis).offset().top - ($('.tooltip').height() + 80);
// 				// 			let offsetLeft = $(tis).offset().left - ($('.tooltip').width() / 2);
// 				// 			$tooltip.css('top', offsetTop+'px');
// 				// 			$tooltip.css('left', offsetLeft+'px');
//                 //
// 				// 			$(tis).mouseover(function(e) {
// 				// 				$(tis).css('z-index', 10000);
// 				// 				$tooltip.fadeIn('500');
// 				// 				$tooltip.fadeTo('10', 1.9);
// 				// 			}).mousemove(function(e) {
// 				// 				let offsetTop = e.pageY - ($('.tooltip').height() + 80);
// 				// 				let offsetLeft = e.pageX - ($('.tooltip').width() / 2);
// 				// 				$tooltip.css('top', offsetTop+'px');
// 				// 				$tooltip.css('left', offsetLeft+'px');
// 				// 			});
// 				// 		},
// 				// 		eventMouseLeave: function (el, view) {
// 				// 			$(el.el).css('z-index', 8);
// 				// 			$('.tooltip').remove();
// 				// 		},
// 				// 		dateClick: function () {
// 				// 			$('.tooltip').hide();
// 				// 		},
// 				// 		eventResizeStart: function () {
// 				// 			$('.tooltip').hide();
// 				// 		},
// 				// 		eventDragStart: function () {
// 				// 			$('.tooltip').hide();
// 				// 		},
// 				// 		viewDisplay: function () {
// 				// 			$('.tooltip').hide();
// 				// 		},
// 				// 	});
//                 //
// 				// 	// Return Filters
// 				// 	function filter(event) {
// 				// 		var vals = [];
// 				// 		$('#tablet-calendar-settings .control').each(function() {
// 				// 			if ($(this).hasClass('active')){
// 				// 				vals.push($(this).data('category'));
// 				// 			}
// 				// 		});
// 				// 		for (var i = 0; i < vals.length; i++){
// 				// 			if (vals.indexOf(event.classNames[i]) === -1) {
// 				// 				return false;
// 				// 			} else {
// 				// 				return true;
// 				// 			}
// 				// 		}
// 				// 	}
// 				// }
// 				// if (window.innerWidth < 768){
// 				// 	var calendar = new Calendar(calendarEl, {
// 				// 		header: {
// 				// 			left: 'prev',
// 				// 			center: 'title',
// 				// 			right: 'next'
// 				// 		},
// 				// 		titleFormat: { month: 'short', day: 'numeric' },
// 				// 		plugins: [ momentPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin ],
// 				// 		views: {
// 				// 			timeGridWeek: {
// 				// 				columnHeaderFormat: { day: 'numeric', weekday: 'short' },
// 				// 			},
// 				// 			dayGridMonth: {
// 				// 				eventLimit: 1,
// 				// 				eventLimitText: function (numEvents) {
// 				// 					return '+'+numEvents;
// 				// 				},
// 				// 				columnHeaderFormat: { weekday: 'short' },
// 				// 			}
// 				// 		},
// 				// 		nextDayThreshold: '11:59:59',
// 				// 		editable: true,
// 				// 		events: eventList,
// 				// 		eventRender: function(info) {
// 				// 			let eventDate = info.event.start;
// 				// 			eventDate = moment(eventDate).format('YYYY-MM-DD');
// 				// 			$('.fc td.fc-day').each(function(i){
// 				// 				if ($(this).data('date') == eventDate){
// 				// 					$(this).addClass('has-events');
// 				// 				}
// 				// 			});
// 				// 			let modalDate = moment(info.event.start).format('MMMM DD, YYYY');
// 				// 			let modalCategory1 = info.event.extendedProps.categoryName[0];
// 				// 			let modalCategory2 = info.event.extendedProps.categoryName[1];
// 				// 			$(info.el).attr('data-date', modalDate);
// 				// 			$(info.el).attr('data-cat1', modalCategory1);
// 				// 			$(info.el).attr('data-cat2', modalCategory2);
// 				// 			return filter(info.event);
// 				// 		},
// 				// 		eventLimitClick: function(info) {
// 				// 			let eventArray = info.segs;
// 				// 			let eventDate = eventArray[0].eventRange.range.start;
// 				// 			let eventDateEnd = eventArray[0].eventRange.range.end;
// 				// 			eventDate = moment(eventDate).format('MMMM DD, YYYY');
//                 //
// 				// 			let modal_contents = '<div class="modal-content">' +
// 				// 				'<div class="modal-event-date">'+eventDate+'</div>' +
// 				// 				'<div class="row center-xs">';
//                 //
// 				// 			for (var i = 0; i < eventArray.length; i++){
// 				// 				let cat1 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][0];
// 				// 				let cat2 = eventArray[i]['eventRange']['def']['extendedProps']['categoryName'][1];
// 				// 				let title = eventArray[i]['eventRange']['def']['title'];
// 				// 				modal_contents += '<div class="col-xs-6">' +
// 				// 					'<div class="modal-event modal-event-'+i+'">' +
// 				// 					'<div class="modal-event-category-container">' +
// 				// 						'<div class="modal-event-category first '+cat1+'">'+cat1+'</div>' +
// 				// 						'<div class="modal-event-category second '+cat2+'">'+cat2+'</div>' +
// 				// 					'</div>' +
// 				// 					'<div class="modal-event-title">'+title+'</div>' +
// 				// 					'</div>' +
// 				// 				'</div>';
// 				// 			}
//                 //
// 				// 			modal_contents += '</div></div>';
//                 //
// 				// 			//$('#modal').modal();
// 				// 			$('#modal .modal-events').html(modal_contents);
// 				// 		},
// 				// 		eventMouseEnter: function (el) {
// 				// 			var tis = el.el;
// 				// 			let tooltipClass1 = 'class="' + el.event.classNames[0] + '"';
// 				// 			let tooltipClass2 = 'class="' + el.event.classNames[1] + '"';
// 				// 			let tooltipCategory1 = el.event.extendedProps.categoryName[0];
// 				// 			let tooltipCategory2 = el.event.extendedProps.categoryName[1];
// 				//
// 				// 			let tooltip = '<div class="tooltip">'+
// 				// 			'<div ' + tooltipClass1 + '>' + tooltipCategory1 + '</div>' +
// 				// 			'<div ' + tooltipClass2 + '>' + tooltipCategory2 + '</div>' +
// 				// 			el.event.title +
// 				// 			'</div>';
//                 //
// 				// 			let $tooltip = $(tooltip).appendTo('body');
// 				// 			$('.tooltip').find('.undefined').remove();
// 				// 			$('.tooltip').find('.registered').remove();
// 				// 			let offsetTop = $(tis).offset().top - ($('.tooltip').height() + 80);
// 				// 			let offsetLeft = $(tis).offset().left - ($('.tooltip').width() / 2);
// 				// 			$tooltip.css('top', offsetTop+'px');
// 				// 			$tooltip.css('left', offsetLeft+'px');
//                 //
// 				// 			$(tis).mouseover(function(e) {
// 				// 				$(tis).css('z-index', 10000);
// 				// 				$tooltip.fadeIn('500');
// 				// 				$tooltip.fadeTo('10', 1.9);
// 				// 			}).mousemove(function(e) {
// 				// 				let offsetTop = e.pageY - ($('.tooltip').height() + 80);
// 				// 				let offsetLeft = e.pageX - ($('.tooltip').width() / 2);
// 				// 				$tooltip.css('top', offsetTop+'px');
// 				// 				$tooltip.css('left', offsetLeft+'px');
// 				// 			});
// 				// 		},
// 				// 		eventMouseLeave: function (el, view) {
// 				// 			$(el.el).css('z-index', 8);
// 				// 			$('.tooltip').remove();
// 				// 		},
// 				// 		dateClick: function () {
// 				// 			$('.tooltip').hide();
// 				// 		},
// 				// 		eventResizeStart: function () {
// 				// 			$('.tooltip').hide();
// 				// 		},
// 				// 		eventDragStart: function () {
// 				// 			$('.tooltip').hide();
// 				// 		},
// 				// 		viewDisplay: function () {
// 				// 			$('.tooltip').hide();
// 				// 		},
// 				// 	});
//                 //
// 				// 	// Return Filters
// 				// 	function filter(event) {
// 				// 		var vals = [];
// 				// 		$('#tablet-calendar-settings .control').each(function() {
// 				// 			if ($(this).hasClass('active')){
// 				// 				vals.push($(this).data('category'));
// 				// 			}
// 				// 		});
// 				// 		for (var i = 0; i < vals.length; i++){
// 				// 			if (vals.indexOf(event.classNames[i]) === -1) {
// 				// 				return false;
// 				// 			} else {
// 				// 				return true;
// 				// 			}
// 				// 		}
// 				// 	}
//                 //
// 				// 	if ($('.fc-timeGridWeek-view').length){
// 				// 		$('.prev-dates').remove();
// 				// 		$('.next-dates').remove();
// 				// 	}
// 				// }
//
// 				// month view
// 				if ($('.fc-dayGridMonth-view').length){
//
// 					// calendar.destroy();
// 					// $('#calendar').html(' ');
// 					// calendar.render();
// 					// calendar.changeView('dayGridMonth');
//
// 					$('#calendar .fc-prev-button .prev-dates').remove();
// 					$('#calendar .fc-next-button .next-dates').remove();
// 					$('#prettydropdown-calendar-date-display li').removeClass('selected');
// 					$('#prettydropdown-calendar-date-display li[data-value="Monthly"]').addClass('selected');
// 					$('.onoffswitch input').prop('checked', true);
//
// 					// check datepicker for date
// 					if ($('#datepicker').val()){
// 						let filteredDate = moment($('#datepicker').val());
// 						let selected_date = moment(filteredDate).format('YYYY-MM-DD');
// 						// calendar.gotoDate(selected_date);
// 						// calendar.select(selected_date);
// 						// $('html, body').animate({
// 						// 	scrollTop: $('.fc-day-top[data-date="'+selected_date+'"]').offset().top
// 						// }, 1000);
// 					} else {
// 						let selected_date = new Date().getTime();
// 						selected_date = moment(selected_date).format('YYYY-MM-DD');
// 						// calendar.gotoDate(selected_date);
// 						// calendar.select(selected_date);
// 						// $('html, body').animate({
// 						// 	scrollTop: $('.fc-day-top[data-date="'+selected_date+'"]').offset().top
// 						// }, 1000);
// 					}
// 					let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 					$('.registered').append(registeredIcon);
//
// 					if (window.innerWidth < 1025 && window.innerWidth > 767){
// 						$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 							$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 								if (!$(this).hasClass('fc-limited')){
// 									$(this).addClass('fc-more-cell');
// 									let singleEvent = '<a class="fc-more single-more">1 event</a>';
// 									$(this).append(singleEvent);
// 								}
// 							});
// 						});
// 					}
//
// 					if (window.innerWidth < 768){
// 						$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 							$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 								if (!$(this).hasClass('fc-limited')){
// 									$(this).addClass('fc-more-cell');
// 									let singleEvent = '<a class="fc-more single-more">+1</a>';
// 									$(this).append(singleEvent);
// 								}
// 							});
// 						});
// 					}
//
// 					$('.single-more').on('click', function(e){
// 						e.preventDefault();
//
// 						let singleEventContent = $(this).prev().html();
// 						let catName1 = $(this).prev().data('cat1');
// 						let catName2 = $(this).prev().data('cat2');
// 						let eventDate = $(this).prev().data('date');
//
// 						let modal_contents = '<div class="modal-content">' +
// 							'<div class="m≤odal-event-date">'+eventDate+'</div>' +
// 							'<div class="row center-xs">';
//
// 						modal_contents += '<div class="col-xs-12">' +
// 							'<div class="modal-event modal-event-1">' +
// 							'<div class="modal-event-category-container">' +
// 								'<div class="modal-event-category first '+catName1+'">'+catName1+'</div>' +
// 								'<div class="modal-event-category second '+catName2+'">'+catName2+'</div>' +
// 							'</div>' +
// 							'<div class="modal-event-title">'+singleEventContent+'</div>' +
// 							'</div>' +
// 						'</div>';
//
// 						modal_contents += '</div></div>';
//
// 						//$('#modal').modal();
// 						$('#modal .modal-events').html(modal_contents);
// 					});
// 				}
//
// 				// week view
// 				if ($('.fc-timeGridWeek-view').length) {
//
// 					// calendar.destroy();
// 					// $('#calendar').html(' ');
// 					// calendar.render();
// 					// calendar.changeView('timeGridWeek');
//
// 					$('#prettydropdown-calendar-date-display li').removeClass('selected');
// 					$('#prettydropdown-calendar-date-display li[data-value="Weekly"]').addClass('selected');
// 					$('.onoffswitch input').prop('checked', false);
//
// 					// check date picker
// 					if ($('#datepicker').val() != ''){
// 						let selected_date = moment($('#datepicker').val());
// 						selected_date = moment(selected_date).format('YYYY-MM-DD');
// 						// calendar.gotoDate(selected_date);
// 						$('#calendar thead > tr > .fc-day-header').each(function(){
// 							if ($(this).data('date') === selected_date){
// 								$(this).addClass('fc-selected');
// 							}
// 						});
// 					} else {
// 						let selected_date = new Date().getTime();
// 						selected_date = moment(selected_date).format('YYYY-MM-DD');
// 						// calendar.gotoDate(selected_date);
// 						$('#calendar thead > tr > .fc-day-header').each(function(){
// 							if ($(this).data('date') === selected_date){
// 								$(this).addClass('fc-selected');
// 							}
// 						});
// 					}
//
// 					// add tab anchors
// 					var setDate = [];
// 					$('#calendar thead > tr > .fc-day-header').each(function(i){
// 						setDate.push($(this).data('date'));
// 						let date = $(this).children('span').text();
// 						let num = date.match(/\d+/g);
// 						let day=  date.match(/[a-zA-Z]+/g);
// 						if ($(this).children('a').length < 1){
// 							$(this).contents().wrap('<a href="#date-'+setDate[i]+'" class="scrollto"><span class="goto">Go to date</span> <span class="num">'+num+'</span><span class="day">'+day+'</span></a>');
// 						}
// 					});
//
// 					// disable anchors with no events
// 					$('.fc-axis').remove();
// 					$('.fc-time-grid-container > .fc-time-grid > .fc-content-skeleton > table > tbody > tr > td').each(function(i){
// 						$(this).attr('id', 'date-'+setDate[i]);
// 						if ($(this).children('.fc-content-col').children('.fc-event-container').children('a').length < 1){
// 							let disableDate = $(this).attr('id');
// 							$('#calendar thead > tr > .fc-day-header').each(function(i){
// 								if ($(this).children('a').attr('href') === '#'+disableDate){
// 									$(this).children('a').addClass('disabled');
// 								}
// 							});
// 						}
// 					});
//
// 					// render events
// 					renderWeekView();
//
// 					// scroll to event
// 					// setTimeout(function(){
// 					// 	if (hasScrolled != true){
// 					// 		$('#calendar thead > tr > .fc-day-header').each(function(i){
// 					// 			if ($(this).hasClass('fc-selected')){
// 					// 				let gotoDate = $(this).data('date');
// 					// 				$('html, body').animate({
// 					// 					scrollTop: $('#date-'+gotoDate).offset().top
// 					// 				}, 1000);
// 					// 			}
// 					// 		});
// 					// 		hasScrolled = true;
// 					// 	}
// 					// }, 400);
//
// 					// add prev - next dates
// 					if (window.innerWidth > 1024){
// 						var view = calendar.view;
// 						var current_start = view.activeStart;
// 						var current_end = view.activeEnd;
// 						var previous_start = moment(current_start).subtract(8,'d').format('MMMM DD');
// 						var previous_end = moment(current_end).subtract(8,'d').format('MMMM DD');
// 						var next_start = moment(current_start).add(7,'d').format('MMMM DD');
// 						var next_end = moment(current_end).add(7,'d').format('MMMM DD');
// 						var previous_dates = previous_start+' - '+previous_end;
// 						var next_dates = next_start+' - '+next_end;
// 						$('.prev-dates').remove();
// 						$('.next-dates').remove();
// 						$('.fc-prev-button').append('<span class="prev-dates" style="left:80px;">'+previous_dates+'</span>');
// 						$('.fc-next-button').append('<span class="next-dates" style="right:80px;">'+next_dates+'</span>');
// 					}
// 					if (window.innerWidth < 1025){
// 						// var view = calendar.view;
// 						// var current_start = view.activeStart;
// 						// var current_end = view.activeEnd;
// 						// var previous_start = moment(current_start).subtract(8,'d').format('MMM DD');
// 						// var previous_end = moment(current_end).subtract(8,'d').format('MMM DD');
// 						// var next_start = moment(current_start).add(7,'d').format('MMM DD');
// 						// var next_end = moment(current_end).add(7,'d').format('MMM DD');
// 						// var previous_dates = previous_start+' - '+previous_end;
// 						// var next_dates = next_start+' - '+next_end;
// 						$('.prev-dates').remove();
// 						$('.next-dates').remove();
// 						$('.fc-prev-button').append('<span class="prev-dates" style="left:0;">'+previous_dates+'</span>');
// 						$('.fc-next-button').append('<span class="next-dates" style="right:0;">'+next_dates+'</span>');
// 					}
//
// 					// registererd event icon
// 					let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 					$('.registered').append(registeredIcon);
//
// 					// no events
// 					if ($('.events-wrapper').is(':empty')){
// 						$('.events-wrapper').html('<div class="no-events">There are no events scheduled this week.</div>');
// 					}
// 				}
//
// 				$('.calendar-keys').remove();
// 				var calendarKeys = '<div class="calendar-keys"><ul><li class="no-events">Not available</li><li class="searched-event">Searched event</li><li class="today-event">Today</li></ul><p>Tap each date to see full list of programs</p></div>';
// 				$('.fc-toolbar').append(calendarKeys);
//
// 				$('.onoffswitch input').on('click', function(){
// 					if ($(this).is(':checked')){
// 						// calendar.changeView('dayGridMonth');
// 						$('#calendar .fc-prev-button .prev-dates').remove();
// 						$('#calendar .fc-next-button .next-dates').remove();
// 						$('.calendar-keys ul li.no-events').show();
// 						$('.calendar-keys p').show();
// 						$('.calendar-keys ul li.searched-event').removeClass('fill');
//
// 						if (window.innerWidth < 1025 && window.innerWidth > 767){
// 							$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 								$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 									if (!$(this).hasClass('fc-limited')){
// 										$(this).addClass('fc-more-cell');
// 										let singleEvent = '<a class="fc-more single-more">1 event</a>';
// 										$(this).append(singleEvent);
// 									}
// 								});
// 							});
// 						}
//
// 						if (window.innerWidth < 768){
// 							$('#calendar .fc-view .fc-body .fc-widget-content .fc-day-grid-container .fc-row').each(function(i){
// 								$(this).children('.fc-content-skeleton').children('table').children('tbody').children('tr').not('.fc-limited').children('td.fc-event-container').each(function(i){
// 									if (!$(this).hasClass('fc-limited')){
// 										$(this).addClass('fc-more-cell');
// 										let singleEvent = '<a class="fc-more single-more">+1</a>';
// 										$(this).append(singleEvent);
// 									}
// 								});
// 							});
// 						}
//
// 						$('.single-more').on('click', function(e){
// 							e.preventDefault();
//
// 							let singleEventContent = $(this).prev().html();
// 							let catName1 = $(this).prev().data('cat1');
// 							let catName2 = $(this).prev().data('cat2');
// 							let eventDate = $(this).prev().data('date');
//
// 							let modal_contents = '<div class="modal-content">' +
// 								'<div class="modal-event-date">'+eventDate+'</div>' +
// 								'<div class="row center-xs">';
//
// 							modal_contents += '<div class="col-xs-12">' +
// 								'<div class="modal-event modal-event-1">' +
// 								'<div class="modal-event-category-container">' +
// 									'<div class="modal-event-category first '+catName1+'">'+catName1+'</div>' +
// 									'<div class="modal-event-category second '+catName2+'">'+catName2+'</div>' +
// 								'</div>' +
// 								'<div class="modal-event-title">'+singleEventContent+'</div>' +
// 								'</div>' +
// 							'</div>';
//
// 							modal_contents += '</div></div>';
//
// 							//$('#modal').modal();
// 							$('#modal .modal-events').html(modal_contents);
// 						});
// 					} else {
// 						// calendar.changeView('timeGridWeek');
// 						$('.calendar-keys ul li.no-events').hide();
// 						$('.calendar-keys p').hide();
// 						$('.calendar-keys ul li.searched-event').addClass('fill');
//
// 						// check date picker
// 						if ($('#datepicker2').val() != ''){
// 							let selected_date = moment($('#datepicker2').val());
// 							selected_date = moment(selected_date).format('YYYY-MM-DD');
// 							// calendar.gotoDate(selected_date);
// 							$('#calendar thead > tr > .fc-day-header').each(function(){
// 								if ($(this).data('date') === selected_date){
// 									$(this).addClass('fc-selected');
// 								}
// 							});
// 						} else {
// 							let selected_date = new Date().getTime();
// 							selected_date = moment(selected_date).format('YYYY-MM-DD');
// 							// calendar.gotoDate(selected_date);
// 							$('#calendar thead > tr > .fc-day-header').each(function(){
// 								if ($(this).data('date') === selected_date){
// 									$(this).addClass('fc-selected');
// 								}
// 							});
// 						}
//
// 						// add tab anchors
// 						var setDate = [];
// 						$('#calendar thead > tr > .fc-day-header').each(function(i){
// 							setDate.push($(this).data('date'));
// 							let date = $(this).children('span').text();
// 							let num = date.match(/\d+/g);
// 							let day=  date.match(/[a-zA-Z]+/g);
// 							if ($(this).children('a').length < 1){
// 								$(this).contents().wrap('<a href="#date-'+setDate[i]+'" class="scrollto"><span class="goto">Go to date</span> <span class="num">'+num+'</span><span class="day">'+day+'</span></a>');
// 							}
// 						});
//
// 						// disable anchors with no events
// 						$('.fc-axis').remove();
// 						$('.fc-time-grid-container > .fc-time-grid > .fc-content-skeleton > table > tbody > tr > td').each(function(i){
// 							$(this).attr('id', 'date-'+setDate[i]);
// 							if ($(this).children('.fc-content-col').children('.fc-event-container').children('a').length < 1){
// 								let disableDate = $(this).attr('id');
// 								$('#calendar thead > tr > .fc-day-header').each(function(i){
// 									if ($(this).children('a').attr('href') === '#'+disableDate){
// 										$(this).children('a').addClass('disabled');
// 									}
// 								});
// 							}
// 						});
//
// 						// render events
// 						// renderWeekView();
//
// 						// scroll to event
// 						// setTimeout(function(){
// 						// 	if (hasScrolled != true){
// 						// 		$('#calendar thead > tr > .fc-day-header').each(function(i){
// 						// 			if ($(this).hasClass('fc-selected')){
// 						// 				let gotoDate = $(this).data('date');
// 						// 				$('html, body').animate({
// 						// 					scrollTop: $('#date-'+gotoDate).offset().top
// 						// 				}, 1000);
// 						// 			}
// 						// 		});
// 						// 		hasScrolled = true;
// 						// 	}
// 						// }, 400);
//
// 						// registererd event icon
// 						let registeredIcon = '<img class="registered" src="../images/registered-event-icon.png">';
// 						$('.registered').append(registeredIcon);
// 					}
// 				});
//
// 			}
// 		}
//
// 	},
// 	finalize() {
// 		// JavaScript to be fired on the home page, after the init JS
//
// 	},
// };
