import Swiper from "swiper/dist/js/swiper.js";
import "waypoints/lib/jquery.waypoints.min.js";
import selectric from "selectric";

export default {
    init() {
        // if($('#top-alert-banner').length) {
        //     $('#top-alert-banner .close').on('click', function(e){
        //         e.preventDefault();
        //         $('#top-alert-banner').hide();
        //     })
        // }

        if ($('.excerpt .short .read-more').length && $('.excerpt .full .read-more').length) {
            $('.excerpt .short .read-more').on('click', function (e) {
                e.stopPropagation();
                $('.excerpt .short').hide();
                $('.excerpt .full').show();

                return false;
            });

            $('.excerpt .full .read-more').on('click', function (e) {
                e.stopPropagation();
                $('.excerpt .full').hide();
                $('.excerpt .short').show();

                return false;
            });
        }

        // cookies
        if ($("#cookie_action_accept").length) {
            $("#cookie_action_accept").focus();
            $("#cookie_action_accept, #cookie_action_reject").on("click", function () {
                $(".skip-link").focus();
            });
        }

        // video pause
        $(".pause-play-btn").on("click", function (e) {
            e.preventDefault();
            var video = $(this).next().get(0);
            if (video.paused === false) {
                video.pause();
                $(this).removeClass("pause").addClass("play");
            }
            else {
                video.play();
                $(this).removeClass("play").addClass("pause");
            }
        });

        // Bookmark a page
        $('.add-to-bookmarks').on('click', function(e) {
            e.preventDefault();

            var bookmarkUrl = $(this).attr('href');
            var bookmarkTitle = document.title;

            if (window.sidebar && window.sidebar.addPanel) { 
                window.sidebar.addPanel(bookmarkTitle, bookmarkUrl, '');
            } else if (window.external && ('AddFavorite' in window.external)) { 
                window.external.AddFavorite(bookmarkUrl, bookmarkTitle);
            } else {
                alert('Press ' + (navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Cmd' : 'Ctrl') + '+D to bookmark this page.');
            }
        });

        // Share link
        $('.share-link').on('click', function(e) {
            e.preventDefault();

            if (navigator.share) {
                navigator.share({
                    title: document.title,
                    url: $(this).attr('href')
                }).then(() => {
                    console.log('share event complete');
                }).catch(console.error);
            } else {
                var href = $(this).attr('href');
                var $temp = $("<input>");
                $("body").append($temp);
                $temp.val(href).select();
                document.execCommand("copy");
                $temp.remove();

                $(this).text('Copied link to clipboard');
            }
        });

        /**
         * Wraps the "Hello, …" text in the NYSBA login url
         */
        if ($(".top-bar-nav .login:contains('Hello, ')").length > 0) {
            const loginLink = (typeof NYSBA !== "undefined" && NYSBA.myNysbaPage) ? decodeURI(NYSBA.myNysbaPage) : "https://portal.nysba.org/s/#/profile/my_info";
            const login = $(".top-bar-nav .login");
            const subMenu = login.next('.sub-menu');
            const loginText = login.clone().children().remove().end().text().replace("|", "");
            const loginUrl = `<a href="${loginLink}"><span class="text">${loginText}</span><span class="bar">|</span></a>`;
            const logoutUrl = login.find("a");

            login.empty();
            $(loginUrl).appendTo(login);
            $(logoutUrl).appendTo(login);

            $(subMenu.clone()).appendTo(login);
            subMenu.remove();
        }
        else if ($(".top-bar-nav .login .sub-menu").length > 0) {
            const subMenu = $(".top-bar-nav .login .sub-menu");
            subMenu.remove();
        }

        // browser check

        // Opera 8.0+
        var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;
        if (isOpera == true) {
            $("html").addClass("opera");
        }

        // Firefox 1.0+
        var isFirefox = typeof InstallTrigger !== "undefined";
        if (isFirefox == true) {
            $("html").addClass("firefox");

            // fixing banner iamges - object-fit cover
            if ($(".image-banner").length) {
                let photo = $(".banner-photo img").attr("src");
                $(".banner-photo").attr("style", "background:url(" + photo + ") 50% no-repeat;");
                $(".banner-photo picture img").hide();
            }

            if ($(".video-banner-image").length) {
                let photo = $(".video-banner-image").attr("src");
                $(".video-banner-container").attr("style", "background:url(" + photo + ") 50% no-repeat;");
                $(".video-banner-image").hide();
            }
        }

        // Safari 3.0+ "[object HTMLElementConstructor]"
        var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window["safari"] || (typeof safari !== "undefined" && safari.pushNotification));
        if (isSafari == true) {
            $("html").addClass("safari");
        }

        // Internet Explorer 6-11
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        if (isIE == true) {
            $("html").addClass("ie");

            // fixing banner iamges - object-fit cover
            if ($(".image-banner").length) {
                let photo = $(".banner-photo img").attr("src");
                $(".banner-photo").attr("style", "background:url(" + photo + ") 50% no-repeat;");
                $(".banner-photo picture img").hide();
            }

            if ($(".video-banner-image").length) {
                let photo = $(".video-banner-image").attr("src");
                $(".video-banner-container").attr("style", "background:url(" + photo + ") 50% no-repeat;");
                $(".video-banner-image").hide();
            }

            if ($(".double-cta-img").length) {
                let photo = $(".double-cta-img picture img").attr("src");
                $(".double-cta-img").attr("style", "background:url(" + photo + ") 50% no-repeat;");
                $(".double-cta-img picture img").hide();
            }

            if ($(".photo-content .image-cnt .photo").length) {
                let photo = $(".photo-content .image-cnt .photo picture img").attr("src");
                $(".photo-content .image-cnt .photo").attr("style", "background:url(" + photo + ") 50% no-repeat;");
                $(".photo-content .image-cnt .photo picture img").hide();
            }

            if ($(".timeline-slider .slider-bg").length) {
                let photo = $(".timeline-slider .slider-bg picture img").attr("src");
                $(".timeline-slider .slider-bg").attr("style", "background:url(" + photo + ") 50% no-repeat;");
                $(".timeline-slider .slider-bg picture img").hide();
            }
        }

        // Edge 20+
        var isEdge = !isIE && !!window.StyleMedia;
        if (isEdge == true) {
            $("html").addClass("edge");
        }

        // Chrome 1+
        var isChrome = !!window.chrome && !!window.chrome.webstore;
        if (isChrome == true) {
            $("html").addClass("chrome");
        }


        // scrollto
        if ($(".scrollto").length > 0) {
            $(".scrollto").on("click", function (e) {
                e.preventDefault();
                let section = $(this).attr("href");
                $("html, body").animate({
                    scrollTop: $(section).offset().top
                }, 1000);
            });
        }


        // form stuff
        let fname = $(".contact-form .gform_fields > li > ul > li:first-child .name_first label").text();
        let lname = $(".contact-form .gform_fields > li > ul > li:first-child .name_last label").text();
        $(".contact-form .gform_fields > li > ul > li:first-child .name_first label")
            .html(fname + " <span class=\"gfield_required\">*<span class=\"sr-only\"> Required</span></span>");
        $(".contact-form .gform_fields > li > ul > li:first-child .name_last label")
            .html(lname + " <span class=\"gfield_required\">*<span class=\"sr-only\"> Required</span></span>");


        // link focus
        $("a").on("click", function () {
            $(this).addClass("active-link");
        }).on("blur", function () {
            $(this).removeClass("active-link");
        });


        // menu buttons
        $("header .curve-btn").each(function () {
            let btnText = $(this).text();
            $(this).html("<span>" + btnText + "</span>");
        });

        // hamburger
        $(".hamburger").on("click", function () {
            $(this).toggleClass("is-active");
            if ($(this).hasClass("is-active")) {
                $("header").addClass("menu-active");
                $("body").addClass("no-scroll fixed");
            }
            else {
                $("header").removeClass("menu-active");
                $("body").removeClass("no-scroll fixed");
            }
        });

        $(window).on("resize", function () {
            if (window.innerWidth > 1023) {
                $("header").removeClass("menu-active");
                $("body").removeClass("no-scroll");
                $(".hamburger").removeClass("is-active");
            }
        });

        if ($(".template-general-flexible-side-tab")) {
            let hash = location.hash.replace("#", "");

            if (hash && $("." + hash + "").length) {
                let tabs = $(".sidebar-tabs a");
                let sectOld = $(".flex-main");
                let newTab = $(".sidebar-tabs a." + hash + "");
                let sectNew = $(".flex-main." + hash + "");

                setTimeout(function () {
                    if ($(window).width() > 768) {
                        tabs.removeClass("active");
                        tabs.removeAttr("aria-selected");
                        tabs.attr("tabindex", "-1");
                        newTab.attr("aria-selected", "true");
                        newTab.removeAttr("tabindex");
                        sectOld.removeClass("active");
                        sectOld.attr("hidden", true);
                        sectNew.removeAttr("hidden");
                        sectNew.addClass("active");
                    }
                    else {
                        tabs.removeClass("active");
                        newTab.addClass("active");
                        sectOld.removeClass("active");
                        sectOld.attr("hidden", true);
                        sectNew.removeAttr("hidden");
                        sectNew.addClass("active");
                    }

                    $("html, body").animate({
                        scrollTop: $("#scroll-to-tabs").offset().top - 50
                    }, 500);
                }, 700);
            }
        }

        if ($(".single-committees")) {
            let hash = location.hash.replace("#", "");
            if (hash && hash != "") {
                let arrTabs = hash.split(",");
                if (arrTabs.length > 0) {
                    $.each(arrTabs, function (index, value) {
                        if (value != "" && $(".sidebar-tabs a." + value + "").length) {
                            let tabs = $(".sidebar-tabs a");
                            let sectOld = $(".flex-main");
                            let newTab = $(".sidebar-tabs a." + value + "");
                            let sectNew = $(".flex-main." + value + "");

                            setTimeout(function () {
                                if ($(window).width() > 768) {
                                    tabs.removeClass("active");
                                    tabs.removeAttr("aria-selected");
                                    tabs.attr("tabindex", "-1");
                                    newTab.attr("aria-selected", "true");
                                    newTab.removeAttr("tabindex");
                                    sectOld.removeClass("active");
                                    sectOld.attr("hidden", true);
                                    sectNew.removeAttr("hidden");
                                    sectNew.addClass("active");
                                }
                                else {
                                    tabs.removeClass("active");
                                    newTab.addClass("active");
                                    sectOld.removeClass("active");
                                    sectOld.attr("hidden", true);
                                    sectNew.removeAttr("hidden");
                                    sectNew.addClass("active");
                                }

                                $("html, body").animate({
                                    scrollTop: $("#scroll-to-tabs").offset().top - 50
                                }, 500);
                            }, 500);
                            return false;
                        }
                        else {
                            return false;
                        }
                    });
                }
            }

        }

        // submenu back
        $(".tablet-main-menu-btn").on("click", function () {
            $(".submenu").removeClass("active");
        });

        $(".link-to-regular-search-filter").on("click", function () {
            let search = $("input[name=\"search-w\"]").val();
            let link = $("#link-to-home").val();
            let linkTo = link + "?s=" + search + "";
            $(this).attr("href", linkTo);
            return true;
        });

        $(".link-to-advanced-search-filter").on("click", function () {
            let search = $("input[name=\"search-w\"]").val();
            let link = $("#link-to-home").val();
            let linkTo = link + "?s=" + search + "&advanced=1";
            $(this).attr("href", linkTo);
            return true;
        });

        let $body = $("body");

        // const categoryFilterElms = document.querySelectorAll('.check-filter-input');
        // const categoryTagsElm = document.getElementById('category-tags');
        // const categoryTagPlaceholder = document.getElementById('tag-placeholder');
        // if (categoryFilterElms.length && categoryTagsElm) {
        //     /**
        //      * An array holder of category strings for Rest API call
        //      * @type {*[]}
        //      */
        //     const categories = [];
        //     /**
        //      * The HTML element builder
        //      * @param {string} name The Practice Area name
        //      * @param {string} slug The Practice Area slug
        //      * @returns {`<fieldset class="checkbox-field"><input id="${string}" type="checkbox" name="news-type[]" value="${string}" /><label for="${string}">${string}</label></fieldset>`}
        //      */
        //     const fieldData = function (name, slug) {
        //         return `<fieldset class="checkbox-field"><input id="${slug}" type="checkbox" name="news-type[]" value="${slug}" /><label for="${slug}">${name}</label></fieldset>`;
        //     };
        //     /**
        //      * API fetch for Practice Area "tags" based on the categories selected, and sets
        //      * the HTML for the span placeholder for the fieldset
        //      *
        //      * @param {array} categories An array of categories to get practice areas for
        //      */
        //     const getPracticeAreas = function(categories) {
        //         if (typeof BLOG !== "undefined") {
        //             const {apiUrl} = BLOG;
        //             const config = {params: {categories}};
        //
        //             axios
        //                 .get(apiUrl, config)
        //                 .then(function ({data, status}) {
        //                     if (status === 200) {
        //                         categoryTagsElm.classList.add('hide');
        //                         categoryTagPlaceholder.innerHTML = '';
        //                         const arrayData = Object.entries(data);
        //                         if (arrayData.length) {
        //                             for (const [key, value] of arrayData) {
        //                                 categoryTagPlaceholder.insertAdjacentHTML('beforeend', fieldData(value, key));
        //                             }
        //                             categoryTagsElm.classList.remove('hide');
        //                         }
        //                     }
        //                 })
        //                 .catch(function (error) {
        //                     console.error(error)
        //                 });
        //         }
        //     };
        //
        //     /**
        //      * Click binder to add or remove the category from the array
        //      * and used to make the API call for the latest list of
        //      * Practice Area "tags"
        //      */
        //     [...categoryFilterElms].map(function (elm) {
        //         elm.addEventListener('click', function (elm) {
        //             const elmVal = elm.target.value;
        //             const valIndex = categories.indexOf(elmVal);
        //             if (valIndex > -1) categories.splice(valIndex, 1)
        //             else categories.push(elm.target.value);
        //             getPracticeAreas(categories);
        //         })
        //     })
        // }

        // Ajax Load News
        if ($("#news-filters-form")) {

            $body.on("submit", "#news-filters-form", function () {
                let sidebar = $("#sidebar").html();
                let mobileSidebar = $('#mobile-sidebar').html();
                let theForm = $(this);
                $.ajax({
                    url: NEWS_AJAX.ajax_url,
                    data: theForm.serialize() + "&action=filter_posts",
                    type: "POST",
                    beforeSend: function () {
                        $("body").addClass("ajax-sort");
                    },
                    success: function (data) {
                        $("#load-news-posts").html(data);
                        $("#sidebar").html(sidebar);
                        $('#mobile-sidebar').html(mobileSidebar);

                        $("html, body").animate({
                            scrollTop: $("#load-news-posts").offset().top
                        }, 700);

                        setTimeout(function () {
                            postStickySidebar();
                        }, 800);
                    },
                });

                $(this).find("#pagenumbertoloadpost").val(1);

                return false;
            });

            $body.on("click", "#news-filters-form #reset-btn", function () {
                $(".check-filter-input:checked").prop("checked", false);
                $("#news-filters-form #pagenumbertoloadpost").val(1);
                $("#news-filters-form select").val("");
                $("#prettydropdown-news-type ul > li").removeClass("selected");
                $("#prettydropdown-news-type ul > li:first-child").addClass("selected");
                $("#news-filters-form #news-keyword").val("");
                $("#news-filters-form").trigger("submit");
                return false;
            });
            loadPagination($("#news-filters-form"));

        }

        function loadPagination(formData) {
            $body.on("click", "nav.pagination a.page-numbers", function () {
                const $pageToLoadField = formData.find("#pagenumbertoloadpost");
                const $current = $('nav.pagination .page-numbers.current').text();
                if ($(this).hasClass("next") && $(this).hasClass("page-numbers")) {
                    $('#pagenumbertoloadpost').val(parseInt($current) + 1);
                }
                if ($(this).hasClass("prev") && $(this).hasClass("page-numbers")) {
                    $('#pagenumbertoloadpost').val(parseInt($current) - 1);
                }
                if ($(this).hasClass("page-numbers") && !$(this).hasClass("prev") && !$(this).hasClass("next")) {
                    $('#pagenumbertoloadpost').val(parseInt($(this).text()));
                }
                formData.trigger("submit");

                return false;
            });
        }

        //End Ajax Load News

        let sidebarBlock;

        if ($("#sidebar-search-page").length) {
            sidebarBlock = new StickySidebar("#sidebar-search-page", {
                containerSelector: "#main-content",
                innerWrapperSelector: ".sidebar__inner",
                topSpacing: 0,
                bottomSpacing: 0
            });

            $(window).on("resize", function () {
                if ($(window).width() > 768) {
                    sidebarBlock.updateSticky();
                }
                else {
                    sidebarBlock.destroy();
                }
            });
        }


        //Start Ajax Search
        if ($("#search-filter")) {

            $body.on("submit", "#search-filter", function () {
                let theForm = $(this);
                $.ajax({
                    url: NEWS_AJAX.ajax_url,
                    data: theForm.serialize() + "&action=search_posts",
                    type: "POST",
                    beforeSend: function () {
                        $("body").addClass("ajax-sort");
                    },
                    success: function (data) {
                        $("#content").html(data);
                        let count = $(data).filter("#count-posts-to-search").text();
                        $("#show-count-search-posts").text(count);

                        $("html, body").animate({
                            scrollTop: $("#main-content").offset().top
                        }, 700);

                        setTimeout(function () {
                            sidebarBlock.calcDimensions();
                        }, 1300);

                    },
                });

                $(this).find("#pagenumbertoloadpost").val(1);

                return false;
            });

            loadPagination($("#search-filter"));

            $body.on("click", "#search-filter #reset-btn", function () {
                $("select").val("");
                $("select").trigger("change");
                $("select").selectric("refresh");
                $("#news-filters-form #pagenumbertoloadpost").val(1);
                $("#search-filter").trigger("submit");
                return false;
            });

        }

        if ($("#advanced-search-form")) {

            loadPagination($("#advanced-search-form"));

            $body.on("submit", "#advanced-search-form", function () {
                let theForm = $(this);
                $.ajax({
                    url: NEWS_AJAX.ajax_url,
                    data: theForm.serialize() + "&action=search_posts",
                    type: "POST",
                    beforeSend: function () {
                        $("body").addClass("ajax-sort");
                    },
                    success: function (data) {
                        $("#content").html(data);
                        let count = $(data).filter("#count-posts-to-search").text();
                        $("#show-count-search-posts").text(count);

                        $("html, body").animate({
                            scrollTop: $("#main-content").offset().top
                        }, 700);

                        setTimeout(function () {
                            sidebarBlock.calcDimensions();
                        }, 1300);
                    },
                });

                $(this).find("#pagenumbertoloadpost").val(1);

                return false;
            });

            $body.on("click", "#advanced-search-form #reset-btn-adv", function () {
                $("#advanced-search-form #pagenumbertoloadpost").val("1");
                $("#count-post-filtered").val("10");
                $("select").val("");
                $("select").trigger("change");
                $("select").selectric("refresh");
                $("#advanced-search-form").trigger("submit");
                return false;
            });

            $body.on("click", "#click-to-filter", function () {
                let countPost = $("#search-display-count").val();
                $("#count-post-filtered").val(countPost);
                $("html, body").animate({
                    scrollTop: $("#advanced-search-form").offset().top
                }, 600);

                return false;
            });


            $body.on("change", "#search-result-type", function () {
                let types = $(this).val();
                let newsCat = $("#select_news_category");
                let dataList = $("#select_date_list");
                let committeesCat = $("#select_committees_category");
                let newsCatSelect = $("#select_news_category select");
                let dataListSelect = $("#select_date_list select");
                let committeesCatSelect = $("#select_committees_category select");

                if (types.length || types.indexOf("all") != -1 || types.indexOf("post") != -1 || types.indexOf("committees") != -1) {

                    if (types.indexOf("all") != -1) {
                        newsCat.removeClass("hidden");
                        dataList.removeClass("hidden");
                        committeesCat.removeClass("hidden");
                    }
                    else if (types.indexOf("post") != -1 || types.indexOf("committees") != -1) {

                        if (types.indexOf("post") != -1 && types.indexOf("committees") != -1) {
                            newsCat.removeClass("hidden");
                            dataList.removeClass("hidden");
                            committeesCat.removeClass("hidden");
                        }
                        else if (types.indexOf("post") != -1 && types.indexOf("committees") == -1) {
                            newsCat.removeClass("hidden");
                            dataList.removeClass("hidden");
                            committeesCat.addClass("hidden");
                            committeesCatSelect.val("");
                        }
                        else if (types.indexOf("post") == -1 && types.indexOf("committees") != -1) {
                            newsCat.addClass("hidden");
                            dataList.addClass("hidden");
                            committeesCat.removeClass("hidden");
                            newsCatSelect.val("");
                            dataListSelect.val("");
                        }

                    }
                    else {
                        newsCat.addClass("hidden");
                        dataList.addClass("hidden");
                        committeesCat.addClass("hidden");

                        newsCatSelect.val("");
                        dataListSelect.val("");
                        committeesCatSelect.val("");
                    }

                }
                else {
                    newsCat.addClass("hidden");
                    dataList.addClass("hidden");
                    committeesCat.addClass("hidden");

                    newsCatSelect.val("");
                    dataListSelect.val("");
                    committeesCatSelect.val("");
                }
                newsCatSelect.selectric("refresh");
                dataListSelect.selectric("refresh");
                committeesCatSelect.selectric("refresh");

            });

            function checkValueSelect() {
                let selectD = $("#advanced-search-form select");
                let html = "";

                if (selectD.length) {
                    selectD.each(function () {
                        let id = $(this).attr("id");
                        let valueSelect = $(this).find("option:selected");
                        if (id != "search-date-display") {
                            if (valueSelect.length != 0) {
                                valueSelect.each(function () {
                                    if ($(this).val() && $(this).text())
                                        html = html + "<a href=\"#\" data-text=\"" + $(this)
                                            .text() + "\" data-id=\"" + id + "\" data-value=\"" + $(this)
                                                .val() + "\">" + $(this).text() + "</a>";
                                });
                            }
                        }
                        else {
                            if (valueSelect.val() && valueSelect.text()) {
                                html = html + "<a href=\"#\" data-text=\"" + valueSelect.text() + "\" data-id=\"" + id + "\" data-value=\"" + valueSelect.val() + "\">" + valueSelect.text() + "</a>";
                            }

                        }
                    });
                }
                $(".to-filtered-value-check-filters").html(html);
            }

            $body.on("change", "#advanced-search-form select", function () {
                checkValueSelect();
            });

            $body.on("click", ".to-filtered-value-check-filters a", function () {
                let idSelect = $(this).attr("data-id");
                let valueSelect = $(this).attr("data-value");
                let textSelect = $(this).attr("data-text");
                $("#" + idSelect + " option:selected").each(function () {
                    if ($(this).val() == valueSelect) {
                        this.selected = false;
                    }
                });
                $("select").trigger("change");
                $("select").selectric("refresh");
                $("#advanced-search-form").trigger("submit");
                return false;
            });
        }
        //end Advanced Search Options

        if ($("#header-search-form")) {
            $body.on("change", "#header-search-form #keyword-search-desktop", function () {
                let search = $(this).val();
                let link = $(this).parent().find("#check-advanced-link").val();
                let newAdvanced = link + "?s=" + search + "&advanced=1";
                $("#link-to-advanced-search").attr("href", newAdvanced);
            });
        }

        // nav tabs
        function navTabs() {
            $(".submenu .tabs li button").attr("aria-selected", "false");
            $(".submenu .tabs li:first-child button").attr("aria-selected", "true");
            if ($(".tabbed").length) {
                const tabbed = document.querySelector(".tabbed");
                const tablist = tabbed.querySelector(".tabs");
                const tabs = tablist.querySelectorAll("button");
                const panels = tabbed.querySelectorAll("[id^=\"nav-section\"]");

                const switchTab = (oldTab, newTab) => {
                    newTab.focus();
                    newTab.removeAttribute("tabindex");
                    newTab.setAttribute("aria-selected", "true");
                    oldTab.removeAttribute("aria-selected");
                    oldTab.setAttribute("tabindex", "-1");
                    let index = Array.prototype.indexOf.call(tabs, newTab);
                    let oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
                    if (oldIndex) panels[oldIndex].hidden = true;
                    panels[index].hidden = false;
                };

                tablist.setAttribute("role", "tablist");

                Array.prototype.forEach.call(tabs, (tab, i) => {
                    tab.setAttribute("role", "tab");
                    tab.setAttribute("id", "nav-tab" + (i + 1));
                    tab.setAttribute("tabindex", "-1");
                    tab.parentNode.setAttribute("role", "presentation");

                    tab.addEventListener("click", e => {
                        e.preventDefault();
                        let currentTab = tablist.querySelector("[aria-selected]");
                        if (e.currentTarget !== currentTab) {
                            switchTab(currentTab, e.currentTarget);
                        }
                    });

                    tab.addEventListener("keydown", e => {
                        let index = Array.prototype.indexOf.call(tabs, e.currentTarget);
                        let dir = e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : e.which === 40 ? "down" : null;
                        if (dir !== null) {
                            e.preventDefault();
                            dir === "down" ? panels[i].focus() : tabs[dir] ? switchTab(e.currentTarget, tabs[dir]) : void 0;
                        }
                    });
                });

                Array.prototype.forEach.call(panels, (panel, i) => {
                    panel.setAttribute("role", "tabpanel");
                    panel.setAttribute("tabindex", "-1");
                    let id = panel.getAttribute("id");
                    panel.setAttribute("aria-labelledby", tabs[i].id);
                    panel.hidden = true;
                });

                tabs[0].removeAttribute("tabindex");
                tabs[0].setAttribute("aria-selected", "true");
                panels[0].hidden = false;
            }
        }


        // set tabindex
        const allLinks = document.querySelectorAll("li.parent .submenu a, li.parent .submenu button");

        allLinks.forEach(link => {
            link.setAttribute("tabindex", "-1");
        });

        const loginLinks = document.querySelectorAll(".top-bar-nav .login a");
        const loginMenu = document.querySelector(".top-bar-nav .login .sub-menu");
        const searchBtn = document.querySelector(".js-modal.search");
        const loginPrev = document.querySelector(".top-bar-nav .login").previousElementSibling;
        const loginPrevLink = loginPrev.querySelector("a");

        const openDD = () => {
            loginMenu.style.display = "block";
        }

        const closeDD = () => {
            loginMenu.style.display = "none";
        }

        loginLinks.forEach(link => {
            link.addEventListener("focus", openDD);
        });

        searchBtn.addEventListener("focus", closeDD);
        loginPrevLink.addEventListener("focus", closeDD);


        // Desktop Top nav prepend contents to top-bar-tablet-mobile container when screen is tablet or smaller
        if (window.innerWidth > 1024) {
            // when mouse hovers over a parent then add the classes to open the submenu
            $(".parent").on("mouseenter", function () {
                $(this).children(".submenu").addClass("active");
                if ($(".submenu").hasClass("active")) {
                    $(".menu-background").addClass("active");
                    $(this).children("a").addClass("submenu-open");
                }

                $(this).children(".submenu").attr("aria-hidden", "false");
                $(this).children(".mobile-submenu-arrow").attr("aria-expanded", "true");

                const allLinks = document.querySelectorAll("li.parent .submenu a, li.parent .submenu button");

                allLinks.forEach(link => {
                    link.setAttribute("tabindex", "0");
                });
                
            });

            // when user focuses over a parent then add the classes to open the submenu
            // $(".parent").on("focusin", function() {
            //     $(this).children(".submenu").addClass("active");
            //     $(this).children(".submenu").attr("aria-hidden", "false");
            //     $(this).children(".mobile-submenu-arrow").attr("aria-expanded", "true");

            //     const allLinks = document.querySelectorAll("li.parent .submenu a, li.parent .submenu button");

            //     allLinks.forEach(link => {
            //         link.setAttribute("tabindex", "0");
            //     });

            //     if ($(".submenu").hasClass("active")) {
            //         $(".menu-background").addClass("active");
            //         $(this).children("a").addClass("submenu-open");
            //     }
            // });

            // when mouse hovers off a parent then add the classes to hide the submenu
            $(".parent").on("mouseleave", function () {
                $(this).children(".submenu").removeClass("active");
                $(".menu-background").removeClass("active");
                $(this).children("a").removeClass("submenu-open");

                $(this).children(".submenu").attr("aria-hidden", "true");
                $(this).children(".mobile-submenu-arrow").attr("aria-expanded", "false");

                const allLinks = document.querySelectorAll("li.parent .submenu a, li.parent .submenu button");

                allLinks.forEach(link => {
                    link.setAttribute("tabindex", "-1");
                });
            });

            // when user focuses off a parent then add the classes to hide the submenu
            // $(".parent").on("focusout", function() {
            //     $(this).children(".submenu").removeClass("active");
            //     $(this).children(".submenu").attr("aria-hidden", "true");
            //     $(this).children(".mobile-submenu-arrow").attr("aria-expanded", "false");

            //     const allLinks = document.querySelectorAll("li.parent .submenu a, li.parent .submenu button");

            //     allLinks.forEach(link => {
            //         link.setAttribute("tabindex", "-1");
            //     });

            //     $(".menu-background").removeClass("active");
            //     $(this).children("a").removeClass("submenu-open");
            // });

            $(".parent button").on("click", function() {

                if (!$(this).next().hasClass("active")) {
                    $(this).next().addClass("active");
                    $(".menu-background").addClass("active");
                    $(this).prev().addClass("submenu-open");

                    $(this).next().attr("aria-hidden", "false");
                    $(this).attr("aria-expanded", "true");
    
                    const allLinks = document.querySelectorAll("li.parent .submenu a, li.parent .submenu button");
    
                    allLinks.forEach(link => {
                        link.setAttribute("tabindex", "0");
                    });

                } else {
                    $(this).next().removeClass("active");
                    $(".menu-background").removeClass("active");
                    $(this).prev().removeClass("submenu-open");

                    $(this).next().attr("aria-hidden", "true");
                    $(this).attr("aria-expanded", "false");
    
                    const allLinks = document.querySelectorAll("li.parent .submenu a, li.parent .submenu button");
    
                    allLinks.forEach(link => {
                        link.setAttribute("tabindex", "-1");
                    });
                } 
            });

            navTabs();

        }
        else if (window.innerWidth < 1025) {
            
            $(".parent button").on("click", function() {
                
                console.log("foo");

                if (!$(this).next().hasClass("active")) {
                    $(this).next().addClass("active");
                    $(".menu-background").addClass("active");
                    $(this).prev().addClass("submenu-open");

                    $(this).next().attr("aria-hidden", "false");
                    $(this).attr("aria-expanded", "true");
    
                    const allLinks = document.querySelectorAll("li.parent .submenu a, li.parent .submenu button");
    
                    allLinks.forEach(link => {
                        link.setAttribute("tabindex", "0");
                    });

                } else {
                    $(this).next().removeClass("active");
                    $(".menu-background").removeClass("active");
                    $(this).prev().removeClass("submenu-open");

                    $(this).next().attr("aria-hidden", "true");
                    $(this).attr("aria-expanded", "false");
    
                    const allLinks = document.querySelectorAll("li.parent .submenu a, li.parent .submenu button");
    
                    allLinks.forEach(link => {
                        link.setAttribute("tabindex", "-1");
                    });
                } 
            });

            navTabs();
        }


        // same thing as above just incase the user decides to re-size the screen these functions will still fire
        $(window).resize(function () {
            if (window.innerWidth > 1024) {
                $(".parent").on("mouseenter", function () {
                    $(this).children(".submenu").addClass("active");
                    $(this).children(".submenu").attr("aria-hidden", "false");
                    $(this).prev().attr("aria-expanded", "true");

                    const allLinks = document.querySelectorAll("li.parent .submenu a, li.parent .submenu button");

                    allLinks.forEach(link => {
                        link.setAttribute("tabindex", "0");
                    });
                });
                $(".parent").on("mouseleave", function () {
                    $(this).children(".submenu").removeClass("active");
                    $(this).children(".submenu").attr("aria-hidden", "true");
                    $(this).prev().attr("aria-expanded", "false");

                    const allLinks = document.querySelectorAll("li.parent .submenu a, li.parent .submenu button");

                    allLinks.forEach(link => {
                        link.setAttribute("tabindex", "-1");
                    });
                });
                navTabs();
            }
            else if (!$(window).width() < 1025) {
                $(".parent").off("mouseenter");
                $(".parent").off("mouseleave");
                $(".parent").off("focusin");
                $(".parent").off("focusout");
                $(".mobile-submenu-arrow > a").on("click", function () {
                    $(this).closest(".parent").children(".submenu").addClass("active");
                    const scrollElement = $(".nav-desktop-tablet-mobile");
                    scrollElement.scrollTop(0);
                });
            }
        });


        function topBarNav() {
            if (window.innerWidth > 1024) {
                if ($(".top-bar-tablet-mobile .top-bar-nav").length) {
                    let topBar = $(".top-bar-tablet-mobile .top-bar-nav");
                    $(".topnav .top-bar-container .top-bar-desktop .menu-utility-navigation-container").html(topBar);
                }
                if ($(".bottom-menu .top-bar-nav").length) {
                    let topBar = $(".bottom-menu .top-bar-nav");
                    $(".topnav .top-bar-container .top-bar-desktop .menu-utility-navigation-container").html(topBar);
                }
                if ($(".top-bar-desktop #google_language_translator").length < 1) {
                    let translate = $(".top-bar-tablet-mobile #google_language_translator");
                    $(".top-bar-desktop").prepend(translate);
                }
            }
            else if (window.innerWidth < 1025 && window.innerWidth > 767) {
                if ($(".topnav .top-bar-container .top-bar-desktop .top-bar-nav").length) {
                    let topBar = $(".topnav .top-bar-container .top-bar-desktop .top-bar-nav");
                    $(".top-bar-tablet-mobile").html(topBar);
                }
                if ($(".bottom-menu .top-bar-nav").length) {
                    let topBar = $(".bottom-menu .top-bar-nav");
                    $(".top-bar-tablet-mobile").append(topBar);
                }
                if ($(".top-bar-tablet-mobile #google_language_translator").length < 1) {
                    let translate = $(".top-bar-desktop #google_language_translator");
                    $(".top-bar-tablet-mobile").prepend(translate);
                }
                $(".topnav .navigation-container .navigation ul li.parent").each(function () {
                    if ($(this).children(".submenu").children(".submenu-links-container")
                        .children(".submenu-links:first-child").children(".submenu-nav").children("li")
                        .children(".overview-link").length < 1) {
                        var parentLink = $(this).children("a").clone();
                        if (parentLink[0].text !== "Sections & Committees") {
                            $(this).children(".submenu").children(".submenu-links-container")
                                .children(".submenu-links:first-child").children(".submenu-nav")
                                .prepend("<li><a href=\"" + parentLink[0].href + "\" class=\"overview-link\">" + parentLink[0].text + " Overview</a></li>");
                        }
                    }
                });
            }
            else if (window.innerWidth < 768) {
                if ($(".topnav .top-bar-container .top-bar-desktop .top-bar-nav").length) {
                    let topBar = $(".topnav .top-bar-container .top-bar-desktop .top-bar-nav");
                    $(".bottom-menu").html(topBar);
                }
                if ($(".top-bar-tablet-mobile .top-bar-nav").length) {
                    let topBar = $(".top-bar-tablet-mobile .top-bar-nav");
                    $(".bottom-menu").html(topBar);
                }
                if ($(".top-bar-tablet-mobile #google_language_translator").length < 1) {
                    let translate = $(".top-bar-desktop #google_language_translator");
                    $(".top-bar-tablet-mobile").prepend(translate);
                }
                $(".topnav .navigation-container .navigation ul li.parent").each(function () {
                    if ($(this).children(".submenu").children(".submenu-links-container")
                        .children(".submenu-links:first-child").children(".submenu-nav").children("li")
                        .children(".overview-link").length < 1) {
                        var parentLink = $(this).children("a").clone();
                        if (parentLink[0].text !== "Sections & Committees") {
                            $(this).children(".submenu").children(".submenu-links-container")
                                .children(".submenu-links:first-child").children(".submenu-nav")
                                .prepend("<li><a href=\"" + parentLink[0].href + "\" class=\"overview-link\">" + parentLink[0].text + " Overview</a></li>");
                        }
                    }
                });
            }
        }

        topBarNav();

        $(window).on("resize", function () {
            topBarNav();
        });


        // Footer language icons when hovered over or focused add class to display the tooltip / when not then remove class to it
        $(".supported-languages-container > ul > li").on("mouseenter", function () {
            $(this).find(".language-tooltip").addClass("show");
        }).on("mouseleave", function () {
            $(this).find(".language-tooltip").removeClass("show");
        });

        $(".supported-languages-container > ul > li > a").on("focusin", function () {
            $(this).parent().find(".language-tooltip").addClass("show");
        }).on("focusout", function () {
            $(this).parent().find(".language-tooltip").removeClass("show");
        });


        // same thing as below just incase the user decides to re-size the screen these functions will still fire
        let executed = true;
        $(window).resize(function () {
            if (executed === true) {
                if (window.innerWidth < 1301) {
                    let leftFooterContent = $(".footer > .row > .col-xs-5").html();
                    $(".footer > .row > .col-xs-7").prepend(leftFooterContent);
                    executed = false;
                }
            }
            else if (executed === false) {
                if (window.innerWidth > 1300) {
                    $(".footer > .row > .col-xs-7 > .footer-email-form-container, .footer > .row > .col-xs-7 > .footer-social-icons, .footer > .row > .col-xs-7 > .footer-copyright-location")
                        .remove();
                    executed = false;
                }
            }
        });


        // when window size is less than 1300px move the contents of the right side of the footer into the left side. This will activate the tablet styling of the footer
        if (window.innerWidth < 1301) {
            let leftFooterContent = $(".footer > .row > .col-xs-5").html();
            $(".footer > .row > .col-xs-7").prepend(leftFooterContent);
            executed = false;
        }


        // when window size is less than 768px copy the list items in the footer with "mobile-dropdown" class and add them into the mobile dropdown.  This will activate the mobile styling of the footer
        if (window.innerWidth < 768) {
            $(".footer-social-icons > ul").addClass("row");
            $(".footer-social-icons > ul > li").addClass("col-xs-4");
            $(".supported-languages-container > ul").addClass("row");
            $(".supported-languages-container > ul > li").addClass("col-xs-3");
            let mobileDropdownItems = $("li.mobile-dropdown").clone();
            $("ul.mobile-dropdown").append(mobileDropdownItems);
        }
        else if (window.innerWidth > 767) {
            $(".footer-social-icons > ul").removeClass("row");
            $(".footer-social-icons > ul > li").removeClass("col-xs-4");
            $(".supported-languages-container > ul").removeClass("row");
            $(".supported-languages-container > ul > li").removeClass("col-xs-3");
        }


        // same thing as above just incase the user decides to re-size the screen these functions will still fire
        $(window).resize(function () {
            if (window.innerWidth < 768) {
                $(".footer-social-icons > ul").addClass("row");
                $(".footer-social-icons > ul > li").addClass("col-xs-4");
                $(".supported-languages-container > ul").addClass("row");
                $(".supported-languages-container > ul > li").addClass("col-xs-3");
            }
            else if (window.innerWidth > 767) {
                $(".footer-social-icons > ul").removeClass("row");
                $(".footer-social-icons > ul > li").removeClass("col-xs-4");
                $(".supported-languages-container > ul").removeClass("row");
                $(".supported-languages-container > ul > li").removeClass("col-xs-3");
            }
        });


        // dropdowns

        $(".advanced-search-date-content").on("click", function (e) {
            e.stopPropagation();
        });

        $("html").on("click", function () {
            if ($(".advanced-search-date-content").hasClass("show")) {
                $(".advanced-search-date-content").removeClass("show");
            }
        });


        // categories
        $(".category-list li label input").on("click", function () {
            if ($(this).is(":checked")) {
                $(this).parent().addClass("active");
            }
            else {
                $(this).parent().removeClass("active");
            }
        });

        $(".category-list li label input").on("focus", function () {
            $(".category-list li label").removeClass("focus");
            $(this).parent().addClass("focus");
        }).on("blur", function () {
            $(".category-list li label").removeClass("focus");
        });


        // change svg image to inline
        $("img.svg").each(function () {
            var $img = $(this);
            var imgID = $img.attr("id");
            var imgClass = $img.attr("class");
            var imgURL = $img.attr("src");
            $.get(imgURL, function (data) {
                var $svg = $(data).find("svg");
                if (typeof imgID !== "undefined") {
                    $svg = $svg.attr("id", imgID);
                }
                if (typeof imgClass !== "undefined") {
                    $svg = $svg.attr("class", imgClass + " replaced-svg");
                }
                $svg = $svg.removeAttr("xmlns:a");
                if (!$svg.attr("viewBox") && $svg.attr("height") && $svg.attr("width")) {
                    $svg.attr("viewBox", "0 0 " + $svg.attr("height") + " " + $svg.attr("width"));
                }
                $img.replaceWith($svg);
            }, "xml");
        });


        // button style
        $("button[type=\"submit\"]").each(function () {
            let btnTxt = $(this).text();
            $(this).html("<span>" + btnTxt + "</span>");
        });


        // plus minus
        /*$('.plus-minus-container .plus').on('click', function (e) {
            e.preventDefault();
            let p = $(this).prev().val();
            p++;
            $(this).prev().val(p);
        });

        $('.plus-minus-container .minus').on('click', function (e) {
            e.preventDefault();
            let m = $(this).next().val();
            if (m > 0) {
                m = m - 1;
                $(this).next().val(m);
            }
        });

        $('.plus-minus-container input').on('input', function (e) {
            let parse = parseInt($(this).val(), 10);
            if (parse > 999) {
                $(this).val('1');
            }
            else {
                $(this).val(parse);
            }
        });*/


        // cart sticky sidebar
        function cartStickySidebar() {
            let sidebar;
            if ((window.innerWidth > 1024 && window.innerWidth > 767) && !$('.single-products')) {
                sidebar = new StickySidebar("#cart", {
                    containerSelector: "#main-content",
                    innerWrapperSelector: ".sidebar__inner",
                    topSpacing: 0,
                    bottomSpacing: 0
                });
                sidebar.updateSticky();
            }
        }

        if ($("#cart").length) {
            cartStickySidebar();
        }
        $(window).on("resize", function () {
            if ($("#cart").length) {
                cartStickySidebar();
            }
        });


        // post sticky sidebar
        function postStickySidebar() {
            let sidebar;
            if (window.innerWidth > 1024 && window.innerWidth > 767) {
                sidebar = new StickySidebar("#sidebar", {
                    containerSelector: "#main-content",
                    innerWrapperSelector: ".sidebar__inner",
                    topSpacing: 0,
                    bottomSpacing: 0
                });
                sidebar.updateSticky();
            }
        }

        if ($("#sidebar").length) {
            postStickySidebar();
        }
        $(window).on("resize", function () {
            if ($("#sidebar").length) {
                postStickySidebar();
            }
        });


        // tablet sticky sidebar
        function tabletStickySidebar() {
            let sidebar;
            if (window.innerWidth < 1024 && window.innerWidth > 767) {
                $(".sidebar-tabs").removeAttr("style");
                sidebar = new StickySidebar("#sections-sidebar", {
                    containerSelector: "#main-content",
                    innerWrapperSelector: ".sidebar__inner",
                    topSpacing: 0,
                    bottomSpacing: 0
                });
                sidebar.updateSticky();
            }
            else {
                $(".sidebar-tabs").removeAttr("style");
            }
        }

        if ($("#sections-sidebar .tablet-sidebar").length) {
            tabletStickySidebar();
        }
        $(window).on("resize", function () {
            if ($("#sections-sidebar .tablet-sidebar").length) {
                tabletStickySidebar();
            }
        });


        // alert
        $(".alert-close").on("click", function (e) {
            e.preventDefault();
            if ($(this).parent().parent().hasClass("flex-main")) {
                let section = $(this).parent().parent().attr("id");
                $(".sidebar-tabs li").each(function () {
                    let tab = $(this).children("a").data("section");
                    if (section === tab) {
                        $(this).children("a").focus();
                    }
                });
            }
            if ($(this).parent().parent().hasClass("wrapper")) {
                if (!$(this).parent().parent().hasClass("listing-sections")) {
                    $(this).parent().parent().remove();
                }
                else {
                    $(this).parent().remove();
                }
            }
        });


        // toggle
        $(".title-list-trigger").on("click", function (e) {
            e.preventDefault();
            $(this).parent().children(".title-list-submenu").toggleClass("open");
            if ($(this).parent().children(".title-list-submenu").hasClass("open")) {
                $(this).children("img.minus").removeClass("off");
                $(this).children("img.plus").addClass("off");
            }
            else {
                $(this).children("img.plus").removeClass("off");
                $(this).children("img.minus").addClass("off");
            }
        });


        // accordion
        $(".accordion-item-title > a").on("click", function (e) {
            e.preventDefault();
            if ($(this).hasClass("open")) {
                $(this).removeClass("open");
                $(this).parent().next().slideUp("slow").attr("aria-expanded", "false");
            }
            else {
                $(".accordion-item-title a").removeClass("open");
                $(this).addClass("open");
                $(".accordion-item-content").slideUp("slow");
                $(".accordion-item-content").attr("aria-expanded", "false");
                $(this).parent().next().slideDown("slow").attr("aria-expanded", "true");
            }
        });


        // tablet / mobile sidebar
        $(".gradient-border > .circle").on("click", function () {
            if (!$("#tablet-sticky-cart-form, .tablet-sticky-cart-body, .circle > img, .exit, .renew-btn")
                .hasClass("active")) {
                $("#tablet-sticky-cart-form, .tablet-sticky-cart-body, .circle > img, .exit, .renew-btn")
                    .addClass("active");
                $("#tablet-cart").attr("aria-expanded", "true");
            }
            else {
                $("#tablet-sticky-cart-form, .tablet-sticky-cart-body, .circle > img, .exit, .renew-btn")
                    .removeClass("active");
                $("#tablet-cart").attr("aria-expanded", "false");
            }
        });


        // media slider
        let mediaSliders = [];
        if ($(".media-slider").length) {
            $(".media-slider .swiper-container").each(function (i) {
                var swiperParams = {
                    loop: true,
                    slidesPerView: 1.6,
                    centeredSlides: true,
                    loopAdditionalSlides: 1,
                    touchStartPreventDefault: false,
                    preventClicks: false,
                    spaceBetween: 140,
                    simulateTouch: false,
                    autoHeight: true,
                    calculateHeight: true,
                    slideToClickedSlide: false,
                    keyboard: {
                        enabled: true,
                        onlyInViewport: true,
                    },
                    navigation: {
                        nextEl: ".media-slider .slide-controls .swiper-button-next",
                        prevEl: ".media-slider .slide-controls .swiper-button-prev",
                    },
                    pagination: {
                        el: ".media-slider .swiper-pagination",
                        type: "fraction"
                    },
                    breakpoints: {
                        1024: {
                            slidesPerView: 1,
                            spaceBetween: 100,
                        },
                        767: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        }
                    },
                    a11y: {
                        enabled: true,
                        prevSlideMessage: "Previous Slide",
                        nextSlideMessage: "Next Slide",
                        firstSlideMessage: "This is the first slide",
                        lastSlideMessage: "This is the last slide"
                    },
                    on: {
                        init: function () {
                            $(".media-slider .swiper-slide, .media-slider .swiper-slide a").attr("tabindex", "-1");
                            $(".media-slider .swiper-slide-active, .media-slider .swiper-slide-active a")
                                .attr("tabindex", "0");
                        },
                        transitionStart: function () {
                            $(".media-slider .swiper-slide, .media-slider .swiper-slide a").attr("tabindex", "-1");
                            $(".media-slider .swiper-slide-active, .media-slider .swiper-slide-active a")
                                .attr("tabindex", "0");
                        },
                        slideChange: function () {
                            setTimeout(function () {
                                $(".media-slider .swiper-slide-active").focus();
                            }, 200);
                        },
                    },
                };
                let mediaSlider = new Swiper($(this), swiperParams);
                mediaSliders.push(mediaSlider);
            });

            mediaSliders.forEach(function (val, idx) {
                val.$el.find(".tablet-slide-controls .swiper-button-next").on("click", function () {
                    val.slideNext();
                });
                val.$el.find(".tablet-slide-controls .swiper-button-prev").on("click", function () {
                    val.slidePrev();
                });
                val.$el.next().children(".swiper-button-next").on("click", function () {
                    val.slideNext();
                });
                val.$el.next().children(".swiper-button-prev").on("click", function () {
                    val.slidePrev();
                });
            });
        }


        // resource slider
        if ($(".resource-slider").length) {
            let resourceSwiper = new Swiper(".resource-slider .swiper-container", {
                loop: true,
                slidesPerView: 1.6,
                centeredSlides: true,
                spaceBetween: 140,
                simulateTouch: false,
                autoHeight: true,
                loopAdditionalSlides: 1,
                slideToClickedSlide: false,
                navigation: {
                    nextEl: ".resource-slider .tablet-slide-controls .swiper-button-next",
                    prevEl: ".resource-slider .tablet-slide-controls .swiper-button-prev",
                },
                keyboard: {
                    enabled: true,
                    onlyInViewport: true,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 1.3,
                        spaceBetween: 100
                    },
                    767: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    }
                },
                a11y: {
                    enabled: true,
                    prevSlideMessage: "Previous Slide",
                    nextSlideMessage: "Next Slide",
                    firstSlideMessage: "This is the first slide",
                    lastSlideMessage: "This is the last slide"
                },
                on: {
                    init: function () {
                        $(".resource-slider .swiper-slide, .resource-slider .swiper-slide a").attr("tabindex", "-1");
                        $(".resource-slider .swiper-slide-active, .resource-slider .swiper-slide-active a")
                            .attr("tabindex", "0");
                    },
                    transitionStart: function () {
                        $(".resource-slider .swiper-slide, .resource-slider .swiper-slide a").attr("tabindex", "-1");
                        $(".resource-slider .swiper-slide-active, .resource-slider .swiper-slide-active a")
                            .attr("tabindex", "0");
                        $(".resource-slider .swiper-slide .before, .resource-slider .swiper-slide .after")
                            .css("visibility", "hidden");
                        $(".resource-slider .swiper-slide-active .before, .resource-slider .swiper-slide-active .after")
                            .css("visibility", "visible");
                    },
                    slideChange: function () {
                        setTimeout(function () {
                            $(".resource-slider .swiper-slide-active").focus();
                        }, 200);
                    },
                },
            });

            if (window.innerWidth < 768) {
                resourceSwiper.navigation.destroy();
                $(".resource-slider .mobile-slide-controls .swiper-button-next").on("click", function () {
                    resourceSwiper.slideNext();
                });
                $(".resource-slider .mobile-slide-controls .swiper-button-prev").on("click", function () {
                    resourceSwiper.slidePrev();
                });
            }

            var rtime;
            var timeout = false;
            var delta = 200;
            $(window).resize(function () {
                rtime = new Date();
                if (timeout === false) {
                    timeout = true;
                    setTimeout(resizeend, delta);
                }
            });

            function resizeend() {
                if (new Date() - rtime < delta) {
                    setTimeout(resizeend, delta);
                }
                else {
                    timeout = false;
                    if (window.innerWidth <= 1024 && window.innerWidth > 768) {
                        resourceSwiper.navigation.destroy();
                        resourceSwiper.navigation.init();
                    }
                    else if (window.innerWidth < 768) {
                        resourceSwiper.navigation.destroy();
                        $(".resource-slider .mobile-slide-controls .swiper-button-next").on("click", function () {
                            resourceSwiper.slideNext();
                        });
                        $(".resource-slider .mobile-slide-controls .swiper-button-prev").on("click", function () {
                            resourceSwiper.slidePrev();
                        });
                    }
                    else {
                        resourceSwiper.navigation.init();
                    }
                }
            }
        }


        // timeline slider
        if ($(".timeline-slider").length) {
            let timelineSwiper = new Swiper(".timeline-slider .swiper-container", {
                slidesPerView: 1.5,
                spaceBetween: 150,
                slidesOffsetBefore: 110,
                slidesOffsetAfter: 110,
                autoHeight: true,
                simulateTouch: false,
                slideToClickedSlide: false,
                keyboard: {
                    enabled: true,
                    onlyInViewport: true,
                },
                navigation: {
                    nextEl: ".timeline-slider .swiper-button-next",
                    prevEl: ".timeline-slider .swiper-button-prev",
                },
                pagination: {
                    el: ".timeline-slider .swiper-pagination",
                    type: "fraction"
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 50,
                        slidesOffsetBefore: 0,
                        slidesOffsetAfter: 0,
                    },
                    767: {
                        autoHeight: false,
                        slidesPerView: 1,
                        spaceBetween: 25,
                        slidesOffsetBefore: 0,
                        slidesOffsetAfter: 0,
                    },
                },
                a11y: {
                    enabled: true,
                    prevSlideMessage: "Previous Slide",
                    nextSlideMessage: "Next Slide",
                    firstSlideMessage: "This is the first slide",
                    lastSlideMessage: "This is the last slide"
                },
                on: {
                    init: function () {
                        $(".timeline-slider .swiper-slide, .timeline-slider .swiper-slide a").attr("tabindex", "-1");
                        $(".timeline-slider .swiper-slide-active, .timeline-slider .swiper-slide-active a")
                            .attr("tabindex", "0");
                        setTimeout(function () {
                            $(".swiper-button-disabled").attr("tabindex", "-1");
                        }, 200);
                    },
                    transitionStart: function () {
                        $(".timeline-slider .swiper-slide, .timeline-slider .swiper-slide a").attr("tabindex", "-1");
                        $(".timeline-slider .swiper-slide-active, .timeline-slider .swiper-slide-active a")
                            .attr("tabindex", "0");
                    },
                    slideChange: function () {
                        setTimeout(function () {
                            $(".timeline-slider .swiper-slide-active").focus();
                            $(".swiper-button-prev, .swiper-button-next").attr("tabindex", "0");
                            $(".swiper-button-disabled").attr("tabindex", "-1");
                        }, 200);
                    },
                },
            });

            timelineSwiper.snapGrid[timelineSwiper.snapGrid.length - 1] = timelineSwiper.slidesGrid[timelineSwiper.slidesGrid.length - 1];

            if (window.innerWidth < 768) {
                timelineSwiper.navigation.destroy();
                $(".timeline-slider .mobile-slide-controls .swiper-button-next").on("click", function () {
                    timelineSwiper.slideNext();
                });
                $(".timeline-slider .mobile-slide-controls .swiper-button-prev").on("click", function () {
                    timelineSwiper.slidePrev();
                });
            }

            var rtime;
            var timeout = false;
            var delta = 200;
            $(window).resize(function () {
                rtime = new Date();
                if (timeout === false) {
                    timeout = true;
                    setTimeout(resizeend, delta);
                }
            });

            function resizeend() {
                if (new Date() - rtime < delta) {
                    setTimeout(resizeend, delta);
                }
                else {
                    timeout = false;
                    if (window.innerWidth > 1024) {
                        timelineSwiper.destroy();
                        setTimeout(function () {
                            let timelineSwiper = new Swiper(".timeline-slider .swiper-container", {
                                slidesPerView: 1.5,
                                spaceBetween: 150,
                                slidesOffsetBefore: 110,
                                slidesOffsetAfter: 110,
                                autoHeight: true,
                                simulateTouch: false,
                                slideToClickedSlide: false,
                                keyboard: {
                                    enabled: true,
                                    onlyInViewport: true,
                                },
                                navigation: {
                                    nextEl: ".timeline-slider .swiper-button-next",
                                    prevEl: ".timeline-slider .swiper-button-prev",
                                },
                                pagination: {
                                    el: ".timeline-slider .swiper-pagination",
                                    type: "fraction"
                                },
                                breakpoints: {
                                    1024: {
                                        slidesPerView: 1,
                                        spaceBetween: 50,
                                        slidesOffsetBefore: 0,
                                        slidesOffsetAfter: 0,
                                    },
                                    767: {
                                        slidesPerView: 1,
                                        spaceBetween: 25,
                                        slidesOffsetBefore: 0,
                                        slidesOffsetAfter: 0,
                                    },
                                },
                                a11y: {
                                    enabled: true,
                                    prevSlideMessage: "Previous Slide",
                                    nextSlideMessage: "Next Slide",
                                    firstSlideMessage: "This is the first slide",
                                    lastSlideMessage: "This is the last slide"
                                },
                                on: {
                                    init: function () {
                                        $(".timeline-slider .swiper-slide, .timeline-slider .swiper-slide a")
                                            .attr("tabindex", "-1");
                                        $(".timeline-slider .swiper-slide-active, .timeline-slider .swiper-slide-active a")
                                            .attr("tabindex", "0");
                                        setTimeout(function () {
                                            $(".swiper-button-disabled").attr("tabindex", "-1");
                                        }, 200);
                                    },
                                    transitionStart: function () {
                                        $(".timeline-slider .swiper-slide, .timeline-slider .swiper-slide a")
                                            .attr("tabindex", "-1");
                                        $(".timeline-slider .swiper-slide-active, .timeline-slider .swiper-slide-active a")
                                            .attr("tabindex", "0");
                                    },
                                    slideChange: function () {
                                        setTimeout(function () {
                                            $(".timeline-slider .swiper-slide-active").focus();
                                            $(".swiper-button-prev, .swiper-button-next").attr("tabindex", "0");
                                            $(".swiper-button-disabled").attr("tabindex", "-1");
                                        }, 200);
                                    },
                                },
                            });
                            timelineSwiper.snapGrid[timelineSwiper.snapGrid.length - 1] = timelineSwiper.slidesGrid[timelineSwiper.slidesGrid.length - 1];
                        }, 200);
                    }
                    else if (window.innerWidth < 768) {
                        setTimeout(function () {
                            let timelineSwiper = new Swiper(".timeline-slider .swiper-container", {
                                slidesPerView: 1.5,
                                spaceBetween: 150,
                                slidesOffsetBefore: 110,
                                slidesOffsetAfter: 110,
                                autoHeight: false,
                                simulateTouch: false,
                                slideToClickedSlide: false,
                                keyboard: {
                                    enabled: true,
                                    onlyInViewport: true,
                                },
                                navigation: {
                                    nextEl: ".timeline-slider .swiper-button-next",
                                    prevEl: ".timeline-slider .swiper-button-prev",
                                },
                                pagination: {
                                    el: ".timeline-slider .swiper-pagination",
                                    type: "fraction"
                                },
                                breakpoints: {
                                    1024: {
                                        slidesPerView: 1,
                                        spaceBetween: 50,
                                        slidesOffsetBefore: 0,
                                        slidesOffsetAfter: 0,
                                    },
                                    767: {
                                        slidesPerView: 1,
                                        spaceBetween: 25,
                                        slidesOffsetBefore: 0,
                                        slidesOffsetAfter: 0,
                                    },
                                },
                                a11y: {
                                    enabled: true,
                                    prevSlideMessage: "Previous Slide",
                                    nextSlideMessage: "Next Slide",
                                    firstSlideMessage: "This is the first slide",
                                    lastSlideMessage: "This is the last slide"
                                },
                                on: {
                                    init: function () {
                                        $(".timeline-slider .swiper-slide, .timeline-slider .swiper-slide a")
                                            .attr("tabindex", "-1");
                                        $(".timeline-slider .swiper-slide-active, .timeline-slider .swiper-slide-active a")
                                            .attr("tabindex", "0");
                                        setTimeout(function () {
                                            $(".swiper-button-disabled").attr("tabindex", "-1");
                                        }, 200);
                                    },
                                    transitionStart: function () {
                                        $(".timeline-slider .swiper-slide, .timeline-slider .swiper-slide a")
                                            .attr("tabindex", "-1");
                                        $(".timeline-slider .swiper-slide-active, .timeline-slider .swiper-slide-active a")
                                            .attr("tabindex", "0");
                                    },
                                    slideChange: function () {
                                        setTimeout(function () {
                                            $(".timeline-slider .swiper-slide-active").focus();
                                            $(".swiper-button-prev, .swiper-button-next").attr("tabindex", "0");
                                            $(".swiper-button-disabled").attr("tabindex", "-1");
                                        }, 200);
                                    },
                                },
                            });
                            timelineSwiper.snapGrid[timelineSwiper.snapGrid.length - 1] = timelineSwiper.slidesGrid[timelineSwiper.slidesGrid.length - 1];

                            timelineSwiper.navigation.destroy();
                            $(".timeline-slider .mobile-slide-controls .swiper-button-next").on("click", function () {
                                timelineSwiper.slideNext();
                            });
                            $(".timeline-slider .mobile-slide-controls .swiper-button-prev").on("click", function () {
                                timelineSwiper.slidePrev();
                            });
                        }, 200);
                    }
                }
            }
        }


        // quote slider
        if ($(".quote-slider").length) {
            let quoteSwiper = new Swiper(".quote-slider .swiper-container", {
                loop: true,
                slidesPerView: 1,
                centeredSlides: true,
                spaceBetween: 110,
                autoHeight: true,
                simulateTouch: false,
                slideToClickedSlide: false,
                keyboard: {
                    enabled: true,
                    onlyInViewport: true,
                },
                navigation: {
                    nextEl: ".quote-slider .slide-controls .swiper-button-next",
                    prevEl: ".quote-slider .slide-controls .swiper-button-prev",
                },
                pagination: {
                    el: ".quote-slider .swiper-pagination",
                    type: "fraction"
                },
                breakpoints: {
                    1024: {
                        spaceBetween: 50,
                    },
                    767: {
                        spaceBetween: 25,
                    },
                },
                a11y: {
                    enabled: true,
                    prevSlideMessage: "Previous Slide",
                    nextSlideMessage: "Next Slide",
                    firstSlideMessage: "This is the first slide",
                    lastSlideMessage: "This is the last slide"
                },
                on: {
                    init: function () {
                        setTimeout(function () {
                            $(".quote-slider .swiper-slide, .quote-slider .swiper-slide a").attr("tabindex", "-1");
                            $(".quote-slider .swiper-slide .swiper-button-prev").attr("tabindex", "-1");
                            $(".quote-slider .swiper-slide .swiper-button-next").attr("tabindex", "-1");
                            $(".quote-slider .swiper-slide .slide-controls div").attr("tabindex", "-1");
                            $(".quote-slider .swiper-slide-active, .quote-slider .swiper-slide-active a")
                                .attr("tabindex", "0");
                            $(".quote-slider .swiper-slide-active .swiper-button-prev").attr("tabindex", "0");
                            $(".quote-slider .swiper-slide-active .swiper-button-next").attr("tabindex", "0");
                        }, 200);
                    },
                    transitionStart: function () {
                        setTimeout(function () {
                            $(".quote-slider .swiper-slide, .quote-slider .swiper-slide a").attr("tabindex", "-1");
                            $(".quote-slider .swiper-slide .swiper-button-prev").attr("tabindex", "-1");
                            $(".quote-slider .swiper-slide .swiper-button-next").attr("tabindex", "-1");
                            $(".quote-slider .swiper-slide .slide-controls div").attr("tabindex", "-1");
                            $(".quote-slider .swiper-slide-active, .quote-slider .swiper-slide-active a")
                                .attr("tabindex", "0");
                            $(".quote-slider .swiper-slide-active .swiper-button-prev").attr("tabindex", "0");
                            $(".quote-slider .swiper-slide-active .swiper-button-next").attr("tabindex", "0");
                        }, 200);
                    },
                    slideChange: function () {
                        setTimeout(function () {
                            $(".quote-slider .swiper-slide-active").focus();
                        }, 200);
                    },
                },
            });

            if (window.innerWidth < 1024) {
                quoteSwiper.navigation.destroy();
                $(".quote-slider .mobile-slide-controls .swiper-button-next").on("click", function () {
                    quoteSwiper.slideNext();
                });
                $(".quote-slider .mobile-slide-controls .swiper-button-prev").on("click", function () {
                    quoteSwiper.slidePrev();
                });
            }

            var rtime;
            var timeout = false;
            var delta = 200;
            $(window).resize(function () {
                rtime = new Date();
                if (timeout === false) {
                    timeout = true;
                    setTimeout(resizeend, delta);
                }
            });

            function resizeend() {
                if (new Date() - rtime < delta) {
                    setTimeout(resizeend, delta);
                }
                else {
                    timeout = false;
                    if (window.innerWidth < 1024) {
                        quoteSwiper.navigation.destroy();
                        $(".quote-slider .mobile-slide-controls .swiper-button-next").on("click", function () {
                            quoteSwiper.slideNext();
                        });
                        $(".quote-slider .mobile-slide-controls .swiper-button-prev").on("click", function () {
                            quoteSwiper.slidePrev();
                        });
                    }
                    else {
                        quoteSwiper.navigation.init();
                    }
                }
            }
        }


        // modal overflow focus
        $(".js-modal").on("click", function () {
            setTimeout(function () {
                $("#js-modal-content").attr("tabindex", "0");
            }, 200);
        });


        // photo content mobile
        if (window.innerWidth < 768) {
            $(".photo-content").each(function () {
                $(this).children(".row").each(function () {
                    if ($(this).children(".col-xs-5").next().hasClass("col-xs-6")) {
                        let photo = $(this).children(".col-xs-6");
                        $(this).children(".col-xs-6").remove();
                        $(this).addClass("flipped");
                        $(this).prepend(photo);
                    }
                });
            });
        }
        $(window).on("resize", function () {
            if (window.innerWidth < 768) {
                $(".photo-content").each(function () {
                    $(this).children(".row").each(function () {
                        if ($(this).children(".col-xs-5").next().hasClass("col-xs-6")) {
                            let photo = $(this).children(".col-xs-6");
                            $(this).children(".col-xs-6").remove();
                            $(this).addClass("flipped");
                            $(this).prepend(photo);
                        }
                    });
                });
            }
            else {
                $(".photo-content").each(function () {
                    $(this).children(".row").each(function () {
                        if ($(this).hasClass("flipped")) {
                            let photo = $(this).children(".col-xs-6");
                            $(this).children(".col-xs-6").remove();
                            $(this).removeClass("flipped");
                            $(this).append(photo);
                        }
                    });
                });
            }
        });


        // tabs
        (function () {
            if ($(".general-tabbed").length) {

                // const tabbed = document.querySelector(".general-tabbed");
                // const tablist = tabbed.querySelector("ul");
                // const tabs = tablist.querySelectorAll("a");
                // const panels = tabbed.querySelectorAll("[id^=\"section\"]");

                // const switchTab = (oldTab, newTab) => {
                //     newTab.focus();
                //     newTab.removeAttribute("tabindex");
                //     newTab.setAttribute("aria-selected", "true");
                //     oldTab.removeAttribute("aria-selected");
                //     oldTab.setAttribute("tabindex", "-1");
                //     let index = Array.prototype.indexOf.call(tabs, newTab);
                //     let oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
                //     panels[oldIndex].hidden = true;
                //     panels[index].hidden = false;
                // };

                // tablist.setAttribute("role", "tablist");

                // Array.prototype.forEach.call(tabs, (tab, i) => {
                //     tab.setAttribute("role", "tab");
                //     tab.setAttribute("id", "tab" + (i + 1));
                //     tab.setAttribute("tabindex", "-1");
                //     tab.parentNode.setAttribute("role", "presentation");

                //     tab.addEventListener("click", e => {
                //         e.preventDefault();
                //         let currentTab = tablist.querySelector("[aria-selected]");
                //         if (e.currentTarget !== currentTab) {
                //             switchTab(currentTab, e.currentTarget);
                //         }
                //     });

                //     tab.addEventListener("keydown", e => {
                //         let index = Array.prototype.indexOf.call(tabs, e.currentTarget);
                //         let dir = e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : e.which === 40 ? "down" : null;
                //         if (dir !== null) {
                //             e.preventDefault();
                //             dir === "down" ? panels[i].focus() : tabs[dir] ? switchTab(e.currentTarget, tabs[dir]) : void 0;
                //         }
                //     });
                // });

                // Array.prototype.forEach.call(panels, (panel, i) => {
                //     panel.setAttribute("role", "tabpanel");
                //     panel.setAttribute("tabindex", "-1");
                //     let id = panel.getAttribute("id");
                //     panel.setAttribute("aria-labelledby", tabs[i].id);
                //     panel.hidden = true;
                // });

                // tabs[0].removeAttribute("tabindex");
                // tabs[0].setAttribute("aria-selected", "true");
                // panels[0].hidden = false;
            }

        })();

        // mobile tabs drop down
        function mobileTabs() {
            if (window.innerWidth < 1025) {
                $(".general-tabbed .mobile-dd").off();
                $(".general-tabbed ul li a").off();
                let currentTab = $(".general-tabbed a[aria-selected=\"true\"] span").text();
                $(".general-tabbed .mobile-dd").children(".text").text(currentTab);
                $(".general-tabbed .mobile-dd").on("click", function (e) {
                    e.preventDefault();
                    if ($(this).hasClass("active")) {
                        $(this).removeClass("active");
                        $(this).next().slideUp(400);
                    }
                    else {
                        $(this).addClass("active");
                        $(this).next().slideDown(400);
                    }
                });
                $(".general-tabbed ul li a").on("click", function (e) {
                    e.preventDefault();
                    let tab = $(this).children("span").text();
                    $(".general-tabbed .mobile-dd").children(".text").text(tab);
                    $(".general-tabbed .mobile-dd").removeClass("active");
                    $(".general-tabbed ul[role=\"tablist\"]").slideUp(400);
                });
            }
            else {
                $(".tabs .tab-wrapper ul").removeAttr("style");
                $(".general-tabbed .mobile-dd").off();
                $(".general-tabbed ul li a").off();
            }
        }

        if ($(".general-tabbed .mobile-dd").length) {
            mobileTabs();
        }
        $(window).on("resize", function () {
            if ($(".general-tabbed .mobile-dd").length) {
                mobileTabs();
            }
        });


        // scrollto
        if ($(".scrollto").length > 0) {
            $(".scrollto").on("click", function (e) {
                e.preventDefault();
                let section = $(this).attr("href");
                $("html, body").animate({
                    scrollTop: $(section).offset().top
                }, 1000);
            });
        }

        // aside tabs
        let isSet = 0;

        function mobileSidebarTabs() {
            if (window.innerWidth < 1025 && window.innerWidth > 767) {

                isSet = 0;

                $(".flex-main").attr("hidden", false);
                $(".flex-main").removeClass("active");

                $("aside .sidebar-tabs li").each(function () {
                    if ($(this).children("a").attr("aria-selected")) {
                        let activeTab = $(this).children("a").data("section");
                        $("#" + activeTab).addClass("active");
                        $(".tablet-sidebar .sidebar-tabs li").each(function () {
                            let findTab = $(this).children("a").data("section");
                            if (activeTab == findTab) {
                                $(this).children("a").addClass("active");
                            }
                        });
                    }
                });

                $(".sidebar-tabs li a").not(".toggle-sidebar").off();
                $(".sidebar-tabs li a").not(".toggle-sidebar").on("click", function (e) {
                    e.preventDefault();
                    let tab = $(this).data("section");
                    $(".flex-main").removeClass("active");
                    $("#" + tab).delay(100).addClass("active");
                    $(".sidebar-tabs li a").removeClass("active");
                    let section = $(this).attr("href");
                    $("html, body").animate({
                        scrollTop: $(section).offset().top
                    }, 400);
                    $(this).addClass("active");
                    $(".sections-sidebar #content").removeClass("sidebar-open");
                    if ($(".toggle-sidebar").hasClass("open")) {
                        $(".toggle-sidebar").removeClass("open");
                        $(".tablet-sidebar").removeClass("open");
                    }
                });

            }
            else if (window.innerWidth < 768) {

                $(".sidebar-tabs li").each(function () {
                    if ($(this).children("a").hasClass("active")) {
                        let activeTab = $(this).children("a").text();
                        $(".mobile-dd .text").text(activeTab);
                    }
                });
                $(".mobile-dd").off();
                $(".mobile-dd").on("click", function (e) {
                    e.preventDefault();
                    if ($(this).hasClass("active")) {
                        $(this).removeClass("active");
                        $(this).next().slideUp(400);
                    }
                    else {
                        $(this).addClass("active");
                        $(this).next().slideDown(400);
                    }
                });
                $(".sidebar-tabs li a").not(".toggle-sidebar").off();
                $(".sidebar-tabs li a").not(".toggle-sidebar").on("click", function (e) {
                    e.preventDefault();
                    let tab = $(this).text();
                    $(".mobile-dd").children(".text").text(tab);
                    $(".mobile-dd").removeClass("active");
                    $(".sidebar-tabs").slideUp(400);
                    let activeTab = $(this).data("section");
                    $(".flex-main").removeClass("active");
                    $("#" + activeTab).delay(100).addClass("active");
                    $(".sidebar-tabs li a").removeClass("active");
                    $(this).addClass("active");
                });
            }
            else {

                $(".mobile-dd").off();

                if (isSet == 0) {
                    $(".sidebar-tabs li a").removeAttr("aria-selected");
                    $(".sidebar-tabs li a").removeClass("active");
                    const tabbed = document.querySelector(".flex-sections");
                    const tablist = tabbed.querySelector("ul.sidebar-tabs");
                    const tabs = tablist.querySelectorAll("a");
                    const panels = tabbed.querySelectorAll("[id^=\"flex-section\"]");

                    const switchTab = (oldTab, newTab) => {
                        newTab.focus();
                        newTab.removeAttribute("tabindex");
                        newTab.setAttribute("aria-selected", "true");
                        oldTab.removeAttribute("aria-selected");
                        oldTab.setAttribute("tabindex", "-1");
                        let index = Array.prototype.indexOf.call(tabs, newTab);
                        let oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
                        panels[oldIndex].hidden = true;
                        panels[index].hidden = false;
                    };

                    tablist.setAttribute("role", "tablist");

                    Array.prototype.forEach.call(tabs, (tab, i) => {
                        tab.setAttribute("role", "tab");
                        tab.setAttribute("id", "tab" + (i + 1));
                        tab.setAttribute("tabindex", "-1");
                        tab.parentNode.setAttribute("role", "presentation");

                        tab.addEventListener("click", e => {
                            e.preventDefault();
                            let currentTab = tablist.querySelector("[aria-selected]");
                            if (e.currentTarget !== currentTab) {
                                switchTab(currentTab, e.currentTarget);
                            }
                        });

                        tab.addEventListener("keydown", e => {
                            let index = Array.prototype.indexOf.call(tabs, e.currentTarget);
                            let dir = e.which === 38 ? index - 1 : e.which === 40 ? index + 1 : e.which === 37 ? "left" : null;
                            if (dir !== null) {
                                e.preventDefault();
                                dir === "right" ? panels[i].focus() : tabs[dir] ? switchTab(e.currentTarget, tabs[dir]) : void 0;
                            }
                        });
                    });

                    Array.prototype.forEach.call(panels, (panel, i) => {
                        panel.setAttribute("role", "tabpanel");
                        panel.setAttribute("tabindex", "-1");
                        let id = panel.getAttribute("id");
                        panel.setAttribute("aria-labelledby", tabs[i].id);
                        panel.hidden = true;
                    });

                    tabs[0].removeAttribute("tabindex");
                    tabs[0].setAttribute("aria-selected", "true");
                    panels[0].hidden = false;

                    isSet = 1;
                }

            }
        }

        if ($(".sidebar-tabs").length) {
            mobileSidebarTabs();
        }
        $(window).on("resize", function () {
            if ($(".sidebar-tabs").length) {
                mobileSidebarTabs();
            }
        });


        // sections tablet sidebar
        $(".toggle-sidebar").on("click", function (e) {
            e.preventDefault();
            if ($(this).hasClass("open")) {
                $(this).removeClass("open");
                $(".tablet-sidebar").removeClass("open");
                $(".sections-sidebar #content").removeClass("sidebar-open");
            }
            else {
                $(this).addClass("open");
                $(".tablet-sidebar").addClass("open");
                $(".sections-sidebar #content").addClass("sidebar-open");
            }
        });


        // leadership tablet filters
        $(".tablet-open-filters").on("click", function (e) {
            e.preventDefault();
            $(this).addClass("open");
            $(".leaders-filter").addClass("open");
            $(".the-leaders").addClass("open");
            $(".advanced-search").addClass("open");
            $(".advanced-search-form-container").addClass("open");
        });

        $(".the-leaders .leaders-filter .close, .advanced-search-form-container .close").on("click", function (e) {
            e.preventDefault();
            $("header").show();
            $("main.main").removeClass("open");
            $("body").removeClass("no-scroll");
            $("body").removeClass("fixed");
            $(".tablet-open-filters").removeClass("open");
            $(".leaders-filter").removeClass("open");
            $(".the-leaders").removeClass("open");
            $(".advanced-search").removeClass("open");
            $(".advanced-search-form-container").removeClass("open");
        });


        // news tablet filters
        $(".tablet-open-filters").on("click", function (e) {
            e.preventDefault();
            $(".news-filters").addClass("open");
            $(".news-filters .close").focus();
            $("main").addClass("open");
            $("body a, body button, body input").attr("tabindex", "-1");
            $(".news-filters a, .news-filters input, .news-filters select, .news-filters .prettydropdown ul, .news-filters button")
                .removeAttr("tabindex");
            $("body").addClass("no-scroll");
            $("body").addClass("fixed");
            $("header").hide();
        });

        $(".news-filters .close").on("click", function (e) {
            e.preventDefault();
            $(".news-filters").removeClass("open");
            $("main").removeClass("open");
            $("body a, body button, body input").removeAttr("tabindex");
            $(".news-filters a, .news-filters input, .news-filters select, .news-filters .prettydropdown ul, .news-filters button")
                .attr("tabindex", "-1");
            $("body").removeClass("no-scroll");
            $("body").removeClass("fixed");
            $("header").show();
            $(".tablet-open-filters").focus();
        });


        // programs tablet filters
        $(".program-open-filters").on("click", function (e) {
            e.preventDefault();
            $(".program-filters-sidebar").addClass("open");
            $(".program-filters-sidebar .close").focus();
            $("main").addClass("open");
            $("body a, body button, body input").attr("tabindex", "-1");
            $(".program-filters a, .program-filters input, .program-filters select, .program-filters .prettydropdown ul, .program-filters button")
                .removeAttr("tabindex");
            $("body").addClass("no-scroll");
            $("body").addClass("fixed");
            $("header").hide();
        });

        $(".program-filters-sidebar .close").on("click", function (e) {
            e.preventDefault();
            $(".program-filters-sidebar").removeClass("open");
            $("main").removeClass("open");
            $("body a, body button, body input").removeAttr("tabindex");
            $(".program-filters a, .program-filters input, .program-filters select, .program-filters .prettydropdown ul, .program-filters button")
                .attr("tabindex", "-1");
            $("body").removeClass("no-scroll");
            $("body").removeClass("fixed");
            $("header").show();
            $(".program-open-filters").focus();
        });

        $(".search .sidebar .close").on("click", function (e) {
            e.preventDefault();
            $(".tablet-open-filters").removeClass("open");
            $("main").removeClass("open");
            $("body a, body button, body input").removeAttr("tabindex");
            $(".search .sidebar a, .search .sidebar input, .search .sidebar select, .search .sidebar button")
                .attr("tabindex", "-1");
            $("body").removeClass("no-scroll");
            $("header").show();
            $(".tablet-open-filters").focus();
        });


        // mobile quicklinks
        function quickLinks() {
            if (window.innerWidth < 768) {
                let numQuicklinks = $(".quicklinks .quicklinks-container .col-xs-4").length;
                let quickLinks = $(".quicklinks .quicklinks-container .col-xs-4").not(":nth-child(-n+3)");
                if (numQuicklinks > 3) {
                    $(".quicklinks .load-more").css("display", "flex");
                }
                $(".load-more-container .row").append(quickLinks);
                $(".load-more-trigger").off();
                $(".load-more-trigger").on("click", function (e) {
                    e.preventDefault();
                    if ($(".load-more-container").is(":visible")) {
                        $(".load-more-container").slideUp("slow");
                        $(".load-more-title").text("Load More");
                        $(".load-more-trigger").removeClass("open");
                        $(".load-more-container").attr("aria-expanded", "false");
                    }
                    else if ($(".load-more-container").not(":visible")) {
                        $(".load-more-container").slideDown("slow");
                        $(".load-more-title").text("Load Less");
                        $(".load-more-trigger").addClass("open");
                        $(".load-more-container").attr("aria-expanded", "true");
                        $(".load-more-container .row .col-xs-4:first-child a").focus();
                    }
                });
            }
            else {
                if ($(".load-more-container .row .col-xs-4").length) {
                    let quickLinksReset = $(".load-more-container .row .col-xs-4");
                    $(".load-more-container .row").html(" ");
                    $(".quicklinks .quicklinks-container").append(quickLinksReset);
                    $(".quicklinks .load-more").css("display", "none");
                }
            }
        }

        quickLinks();

        $(window).on("resize", function () {
            quickLinks();
        });


        // parallax patterns
        $(".pattern").each(function () {
            var img = $(this);
            var imgParent = $(this).parent();

            function parallaxImg() {
                //var speed = img.data('speed');
                var speed = 0.6;
                var imgY = imgParent.offset().top;
                var winY = $(window).scrollTop();
                var winH = $(window).height();
                var parentH = imgParent.innerHeight();
                var winBottom = winY + winH;

                if (winBottom > imgY && winY < imgY + parentH) {
                    var imgBottom = ((winBottom - imgY) * speed);
                    var imgTop = winH + parentH;
                    var imgPercent = ((imgBottom / imgTop) * 100) + (50 - (speed * 50));
                }
                img.css({
                    //top: imgPercent + '%',
                    transform: "translateY(-" + imgPercent + "%)"
                });
            }

            $(document).on({
                scroll: function () {
                    parallaxImg();
                }
            });
        });


        // leaders keyboard nav
        $(".leader-cnt .photo-cnt .overlay a").on("focus", function () {
            $(".leader-cnt .photo-cnt .overlay").css("opacity", "0");
            $(this).parent().css("opacity", "1");
            if (!$(this).parent().parent().parent().parent().hasClass("on")) {
                $(this).parent().parent().parent().parent().addClass("on");
            }
        });

        // section listing keyboard nav
        $(".sections-list ul li a").on("focus", function () {
            $(this).parent().css("opacity", "1");
            if (!$(this).parent().hasClass("on")) {
                $(this).parent().addClass("on");
            }
        });


        // cle programs sidebar
        $(".sidebar-filters li a").on("click", function (e) {
            e.stopPropagation();
            e.preventDefault();
            $(this).removeClass("active");
            if ($(this).hasClass("open")) {
                $(this).removeClass("open");
                $(this).next().slideUp(400);
            }
            else {
                $(this).addClass("open");
                $(this).next().slideDown(400);
            }
            Waypoint.refreshAll();
        });

        $(".sidebar-filters li ul.sub-filters .radio-field input[type=\"radio\"]").on("change", function () {
            if ($(".custom-date-radio").is(":checked")) {
                $(".custom-date").slideDown(400);
            }
            else {
                $(".custom-date").slideUp(400);
            }
            Waypoint.refreshAll();
        });

        $(".sidebar-filters li").on("blur", function (e) {
            $(this).children("a").removeClass("open");
            $(this).children(".sub-filters").slideUp(400);
        });


        // programs
        $(".credit-types .toggle-btn").on("click", function (e) {
            e.preventDefault();
            if ($(this).hasClass("open")) {
                $(this).removeClass("open");
                $(this).prev().slideUp(400);
            }
            else {
                $(this).addClass("open");
                $(this).prev().slideDown(400);
            }
            Waypoint.refreshAll();
        });


        $body.bind('DOMSubtreeModified', '#listing-top', function () {
            if ($(".sections-list li.reveal").length > 0) {
                $(this).find('li').addClass('on');
            }
        })

        // back to top
        // if ($('.back-top').length > 0) {
        //
        // 	let btn_left = $('.program-filters-sidebar aside').offset().left;
        // 	$('.back-top').css('left', btn_left + 'px');
        //
        // 	$(window).on('resize', function () {
        // 		let btn_left = $('.program-filters-sidebar aside').offset().left;
        // 		$('.back-top').css('left', btn_left + 'px');
        // 		Waypoint.refreshAll();
        // 	});
        //
        // 	$('.program-filters-sidebar aside').each(function (e) {
        // 		var show_hide_btn1 = $(this);
        // 		var waypoint_show_hide_btn1 = new Waypoint({
        // 			element: show_hide_btn1[0],
        // 			handler: function (direction) {
        // 				if (direction === 'down') {
        // 					$('.back-top').addClass('on');
        // 				}
        // 				if (direction === 'up') {
        // 					$('.back-top').removeClass('on');
        // 				}
        // 			},
        // 			offset: function () {
        // 				return this.context.innerHeight() - this.adapter.outerHeight() - 100;
        // 			}
        // 		});
        // 	});
        //
        //
        // 	$('.listing-sections .pagination').each(function (e) {
        // 		var hideBtn1 = $(this);
        // 		var waypoint_hideBtn1 = new Waypoint({
        // 			element: hideBtn1[0],
        // 			handler: function (direction) {
        // 				if (direction === 'down') {
        // 					$('.back-top').removeClass('on');
        // 				}
        // 			},
        // 			offset: 'bottom-in-view'
        // 		});
        // 	});
        // 	$('.listing-sections .pagination').each(function (e) {
        // 		var hideBtn2 = $(this);
        // 		var waypoint_hideBtn2 = new Waypoint({
        // 			element: hideBtn2[0],
        // 			handler: function (direction) {
        // 				if (direction === 'up') {
        // 					$('.back-top').addClass('on');
        // 				}
        // 			},
        // 			offset: 'bottom-in-view'
        // 		});
        // 	});
        // }

        // if ( typeof USER !== undefined && USER.checkSession !== undefined ) {
        // 	const data = (USER.id) ? USER.id : '';
        // 	$.get(USER.checkSession, data, response => console.log(response));
        // }

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        const selectElms = $('select').selectric();
        var base = window.location.protocol + "//" + window.location.host;
        // $('a').each(function(){
        //     if (!$(this).hasClass('goog-te-menu-value')){
        //         $('a:not([href^="'+base+'"])').attr("target", "_blank");
        //     }
        // });

        $(".ginput_container_select").each(function () {
            var label = $(this).prev("label").attr("for");
            $(this).children(".selectric-wrapper").children(".selectric-input").attr("aria-label", label);
        });

        // set patterns
        $(".pattern").each(function () {
            var img = $(this);
            var imgParent = $(this).parent();

            function parallaxImg() {
                var speed = 0.6;
                var imgY = imgParent.offset().top;
                var winY = $(window).scrollTop();
                var winH = $(window).height();
                var parentH = imgParent.innerHeight();
                var winBottom = winY + winH;

                if (winBottom > imgY && winY < imgY + parentH) {
                    var imgBottom = ((winBottom - imgY) * speed);
                    var imgTop = winH + parentH;
                    var imgPercent = ((imgBottom / imgTop) * 100) + (50 - (speed * 50));
                }
                img.css({
                    opacity: "1",
                    transform: "translateY(-" + imgPercent + "%)"
                });
            }

            parallaxImg();
        });

        if ($(".reveal").length > 0) {
            $(".reveal").each(function (e) {
                var reveal = $(this);
                var waypoint_reveal = new Waypoint({
                    element: reveal[0],
                    handler: function (direction) {
                        if (direction === "down") {
                            reveal.addClass("on");

                        }
                    },
                    offset: "100%"
                });
            });
            // $('.sections-list li').each(function(index){
            // 	$(this).css({'transition-delay' : 0.02*(1+index) + 's'});
            // });
        }


        // check fav for tooltip
        if ($(".fav-link").hasClass("checked")) {
            $(".fav-tooltip").text("Remove from favorite");
        }
        else {
            $(".fav-tooltip").text("Save to My NYSBA favorite");
        }
    },
    toggleChecked(elm) {
        elm.checked === true ? $(elm).parent().addClass("checked") : $(elm).parent().removeClass("checked");
    },
    /**
     * Triggers the updating post meta based on
     * @param elm
     * @param postID
     */
    setUserMeta(elm, postID) {
        const url = USER.ajaxurl;
        const data = { postID: postID, userID: USER.userID, action: "user_favorite_post", nonce: USER.nonce };

        try {

            function checkFav() {
                if ($(".fav-link").hasClass("checked")) {
                    $(".fav-tooltip").text("Remove from favorite");
                }
                else {
                    $(".fav-tooltip").text("Save to My NYSBA favorite");
                }
            }

            $.post(url, data, response => console.log(response))
                .done(() => {
                    elm.disabled = false;
                    this.toggleChecked(elm);
                    checkFav();
                });

        } catch (e) { console.log("AJAX Err: " + e); }
    },
    /**
     * Copy's the url from the defined elm
     * @param elm
     */
    copyURL(elm) {
        const copy = elm.dataset.copy || elm.value || "";

        if (copy.length) {
            const el = document.createElement("textarea");
            const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
            el.value = copy;
            el.setAttribute("readonly", "");
            el.style.cssText = "position:absolute; left:-9999px";
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            const tooltip = elm.querySelector('.source-link-tooltip');
            const copiedText = typeof COPY_POST !== "undefined" ? COPY_POST.tooltipCopiedText : 'URL Copied';

            if (tooltip) {
                const beforeTooltipText = tooltip.innerText;
                tooltip.innerText = copiedText;
                setTimeout(() => tooltip.innerText = beforeTooltipText, 1500);
            }

            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
        }
    }
};
