import Swiper from "swiper/dist/js/swiper.js";

export default {
  	init() {
        if ($('.programs-slider')) {
            const swiper = new Swiper('.programs-slider .swiper-container', {
                loop: true,
                autoplay: true,
                spaceBetween: 0,
                slidesPerView: 3,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 1
                    },
                }
            });

            const swiperSlide = $('.programs-slider__slide');
            swiperSlide.mouseenter(function() {
                swiper.autoplay.stop();
            });
            swiperSlide.mouseleave(function() {
                swiper.autoplay.start();
            });
        }
	},
	finalize() {
	},
};
