/** import local dependencies */
import "@babel/polyfill";
import Router from "./util/Router";
import common from "./routes/common";
import resizeSensor from "./util/resizeSensor";
import stickySidebar from "./util/jquery-sticky-sidebar";
import Modal from "./util/jquery-accessible-modal-window-aria";

/** import local dependencies */
import home from "./routes/home";
import pageTemplateTemplateGeneralFlexV2 from "./routes/general-flex";
import pageTemplateTemplateCleLanding from "./routes/cle-programs";
import pageTemplateTemplateCleListing from "./routes/cle-listing";
import pageTemplateTemplateCleDetail from "./routes/cle-detail";
import pageTemplateTemplatePublicResourcesLanding from "./routes/public-resources";
import pageTemplateTemplatePublicationMaster from "./routes/publication-master";
import pageTemplateTemplateMyNysba from "./routes/my-nysba";
import pageTemplateTemplateCalendar from "./routes/calendar";
import publicationLandingPage from "./routes/publication-issue";
import singlePublications from "./routes/single-publications";
import singlePost from "./routes/single";

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
    /** All pages */
    common,
    resizeSensor,
    stickySidebar,
    Modal,
    home,
    singlePost,
    pageTemplateTemplateCleLanding,
    pageTemplateTemplateCleListing,
    pageTemplateTemplateCleDetail,
    pageTemplateTemplatePublicResourcesLanding,
    pageTemplateTemplatePublicationMaster,
    pageTemplateTemplateMyNysba,
    pageTemplateTemplateCalendar,
		pageTemplateTemplateGeneralFlexV2,
    publicationLandingPage,
    singlePublications,
});



/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
