import Swiper from "swiper/dist/js/swiper.js";

export default {
  	init() {
        if ($('.helpful-links-slider')) {
            const swiper = new Swiper('.helpful-links-slider .swiper-container', {
                loop: true,
                autoplay: true,
                spaceBetween: 0,
                slidesPerView: 1,
                loop: true,
                navigation: {
                    nextEl: '.nav-next',
                    prevEl: '.nav-prev',
                },
                pagination: {
                    el: '.nav-pagination',
                    type: 'bullets',
                }
            });
            const swiperSlide = $('.helpful-links-slider__slide');
            swiperSlide.mouseenter(function() {
                swiper.autoplay.stop();
            });
            swiperSlide.mouseleave(function() {
                swiper.autoplay.start();
            });
        }
	},
	finalize() {
	},
};
