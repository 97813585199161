export default {
	init() {
		$('.source-link').on('click', function (e) {
			e.preventDefault();

			var $temp = $("<textarea>");
			$("body").append($temp);

			$temp.val(window.location.href).select();
			document.execCommand("copy");
			$temp.remove();

			alert("URL copied to clipboard");
		});
	}
};
