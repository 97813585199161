import Swiper from 'swiper/dist/js/swiper.js'

export default {
    init () {

        if ($('.programs-slider-v2')) {

            $('.programs-slider-v2 .swiper-container').each(function (index, element) {
                var swiper_element = $(this)
                var $next = $(this).parents('.programs-slider-v2').find('.swiper-button-next')
                var $prev = $(this).parents('.programs-slider-v2').find('.swiper-button-prev')
                var $nav = $(this).parents('.programs-slider-v2').find('.swiper-pagination')

                const swiper = new Swiper(swiper_element[0], {
                    loop: false,
                    autoplay: false,
                    spaceBetween: 0,
                    slidesPerView: 3,
                    allowTouchMove: false,
                    pagination: {
                        el: $nav,
                        type: 'fraction',
                    },
                    breakpoints: {
                        1024: {
                            slidesPerView: 1,
                            loop: true,
                            autoplay: true,
                            llowTouchMove: true,
                            navigation: {
                                nextEl: $next,
                                prevEl: $prev,
                            },
                        },
                        768: {
                            slidesPerView: 1,
                            loop: true,
                            autoplay: true,
                            allowTouchMove: true,
                            navigation: {
                                nextEl: $next,
                                prevEl: $prev,
                            },
                        },
                    }
                })
            })

        }

        if ($('.programs-slider')) {
            $('.programs-slider .swiper-container').each(function (index, element) {
                var swiper_element = $(this)
                var $next = $(this).parents('.programs-slider').find('.swiper-button-next')
                var $prev = $(this).parents('.programs-slider').find('.swiper-button-prev')
                var $nav = $(this).parents('.programs-slider').find('.swiper-pagination')
                if ($(this).parent().hasClass('no-slider')) {
                    const swiper = new Swiper(swiper_element[0], {
                        autoplay: true,
                        spaceBetween: 0,
                        slidesPerView: 3,
                        navigation: {
                            nextEl: $next,
                            prevEl: $prev,
                        },
                        pagination: {
                            el: $nav,
                            type: 'fraction',
                        },
                        breakpoints: {
                            1024: {
                                slidesPerView: 2,
                                loop: true,
                            },
                            768: {
                                slidesPerView: 1,
                                loop: true,
                            },
                        }
                    })
                } else {
                    const swiper = new Swiper(swiper_element[0], {
                        loop: true,
                        autoplay: true,
                        spaceBetween: 0,
                        slidesPerView: 3,
                        navigation: {
                            nextEl: $next,
                            prevEl: $prev,
                        },
                        pagination: {
                            el: $nav,
                            type: 'fraction',
                        },
                        breakpoints: {
                            1024: {
                                slidesPerView: 2
                            },
                            768: {
                                slidesPerView: 1
                            },
                        }
                    })
                }


                const swiperSlide = $('.programs-slider__slide')
                swiperSlide.mouseenter(function () {
                    swiper.autoplay.stop()
                })
                swiperSlide.mouseleave(function () {
                    swiper.autoplay.start()
                })


            })

        }
    },
    finalize () {
    },
}
