import Swiper from "swiper/dist/js/swiper.js";

export default {
  	init() {


		// swiper hero
        if ($(".hero-banner").length) {

			const titles = [];
			const images = [];

			const slides = document.querySelectorAll(".hero-banner .swiper-slide");

			slides.forEach(slide => {
				titles.push(slide.dataset.title);
				images.push(slide.dataset.image);
			});

			const flipBtns = () => {
				if (window.innerWidth < 768) {
					slides.forEach(slide => {
						const btns = slide.querySelector(".btn-cnt");
						const btn = btns.lastElementChild;
						btns.insertAdjacentElement("afterbegin", btn);
						//console.log(btn);
					});
				} else {
					slides.forEach(slide => {
						const btns = slide.querySelector(".btn-cnt");
						const btn = btns.lastElementChild;
						btns.insertAdjacentElement("beforeend", btn);
						//console.log(btn);
					});
				}
			}

			flipBtns();

			window.addEventListener("resize", flipBtns);

            let heroSwiper = new Swiper(".hero-banner .swiper-container", {
                loop: true,
                slidesPerView: 1,
                spaceBetween: 0,
				autoplay: {
					disableOnInteraction: true,
					pauseOnMouseEnter: true,
				},
                simulateTouch: false,
                slideToClickedSlide: false,
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
					renderBullet: function (index, className) {
						return '<button class="' + className + '"><span class="title">' + (titles[index]) + '</span> <span class="image"><img src="' + (images[index]) + '" alt="' + (titles[index]) + '" /></span></button>';
					},
				},
                keyboard: {
                    enabled: true,
                    onlyInViewport: true,
                },
                a11y: {
                    enabled: true,
                    prevSlideMessage: "Previous Slide",
                    nextSlideMessage: "Next Slide",
                    firstSlideMessage: "This is the first slide",
                    lastSlideMessage: "This is the last slide"
                },
				breakpoints: {

                },
                on: {
                    init: function() {
                        $(".hero-banner .swiper-slide, .hero-banner .swiper-slide a").attr("tabindex", "-1");
                        $(".hero-banner .swiper-slide-active, .hero-banner .swiper-slide-active a").attr("tabindex", "0");
                    },
                    transitionStart: function() {
                        $(".hero-banner .swiper-slide, .hero-banner .swiper-slide a").attr("tabindex", "-1");
                        $(".hero-banner .swiper-slide-active, .hero-banner .swiper-slide-active a").attr("tabindex", "0");
                    },
                    slideChange: function() {

                        // setTimeout(function() {
                        //     $(".hero-banner .swiper-slide-active a").focus();
                        // }, 200);

                    },
                },
            });

			$(".hero-banner .swiper-container").mouseenter(function(){
				heroSwiper.autoplay.stop();
			});
			
			$(".hero-banner .swiper-container").mouseleave(function(){
				heroSwiper.autoplay.start();
			});

        }


		// video
		function fallback(video){
			let img = video.querySelector('img');
			if (img){
				video.parentNode.replaceChild(img, video);
			}
		}


		// hero
		if (window.innerWidth > 1024) {
			let video = $('section.video-banner .video-wrapper').height();
			let cta = $('section.video-banner .banner-cta').height();
			let offset = (video * 15) * .01;
			let banner_height = video + cta + 200 - offset;
			$('.video-banner').css('height', banner_height+'px');
		} else {
			let video = $('section.video-banner .video-wrapper').height();
			let cta = $('section.video-banner .banner-cta').height();
			let offset = (video * 15) * .01;
			let banner_height = video + cta + 100 - offset;
			$('.video-banner').css('height', banner_height+'px');
		}


		

		$(window).on('resize', function(){
			if (window.innerWidth > 1024) {
				let video = $('section.video-banner .video-wrapper').height();
				let cta = $('section.video-banner .banner-cta').height();
				let offset = (video * 33) * .01;
                let banner_height = video + cta + 200;
				$('.video-banner').css('height', banner_height+'px');
			} else {
				let video = $('section.video-banner .video-wrapper').height();
				let cta = $('section.video-banner .banner-cta').height();
				let offset = (video * 33) * .01;
                let banner_height = video + cta + 100;
				$('.video-banner').css('height', banner_height+'px');
			}
		});

	},
  	finalize() {
    // JavaScript to be fired on the home page, after the init JS

  	},
};
